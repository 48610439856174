import "./AppMenu.scss";
import React, { useEffect, useState } from "react";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Cascader, Menu } from "antd";
import { useAtom } from "jotai";
import { collabAPIAtom, isCollaboratingAtom } from "../imago-app/collab/Collab";
import { loadFromJSONPage } from "../data/json";
import { AppState } from "../types";
import { ActionManager } from "../actions/manager";
import { actionChangePage } from "../actions/actionChangePage";
import { LocalData } from "../imago-app/data/LocalData";
import { PageManager } from "../imago-app/data/PageManager";
import { getPageListFromStorage, isCollabtionHost } from "../imago-app/data/localStorage";
import { saveAsJSONPage } from "../data";
import { createUndoAction } from "../actions/actionHistory";
import { GRID_SIZE, GRID_STYLE } from "../constants";
import { actionZoomIn, actionZoomOut } from "../actions";
import { languages, t } from "../i18n";
import {
  actionResetZoom,
  actionZoomTo,
  actionZoomToFit,
} from "../actions/actionCanvas";
import { useDevice } from "./App";
import { langCodeAtom, languageDetector } from "../imago-app";
type MenuItem = Required<MenuProps>["items"][number];

export const AppMenu: React.FC<{
  appState: AppState;
  actionManager: ActionManager;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  setAppState: React.Component<any, AppState>["setState"];
  onViewBackgroundColorChange?: (color: string) => void;
  hasMeetSDK: boolean;
  onClick?: () => void;
}> = ({
  appState,
  actionManager,
  operaPage,
  setAppState,
  onViewBackgroundColorChange,
  hasMeetSDK,
  onClick,
}) => {
    const device = useDevice();
    const [collabAPI] = useAtom(collabAPIAtom);
    const [pages, setPages] = useState(getPageListFromStorage());
    const [openKeys, setOpenKeys] = useState<string[]>();
    const [isCollaborating,] = useAtom(isCollaboratingAtom);
    const [preferences, setPreferences] = useState<MenuItem>(null);
    const [menuSaveTo, setMenuSaveTo] = useState<MenuItem>(null);
    const [menuNewBoard, setMenuNewBoard] = useState<MenuItem>(null);
    const [menuDashBoard, setMenuDashBoard] = useState<MenuItem>(null);
    const [menuDashBoardDivid, setMenuDashBoardDivid] = useState<MenuItem>(null);
    const [menuHelp, setMenuHelp] = useState<MenuItem>(null);
    const [menuHelpDivid, setMenuHelpDivid] = useState<MenuItem>(null);
    const [mode, _] = useState<"vertical" | "inline">("vertical");
    const [langCode, setLangCode] = useAtom(langCodeAtom);
    const isHost = isCollabtionHost()
    const getLangList: () => any[] = () => {
      const arr: any[] = [];
      languages.map((lang) => {
        arr.push({ key: lang.code, label: lang.label });
      });
      return arr;
    };

    const getLangCodes: () => string[] = () => {
      const codes: string[] = [];
      languages.map((lang) => {
        codes.push(lang.code);
      });

      return codes;
    };

    const perferBoardColor = { key: "board-colour", label: t("leftMenu.board_colour") }
    const perferLanguage = {
      key: "language",
      label: t("leftMenu.language"),
      children: getLangList(),
    }
    const perferColorSystem = {
      key: "colour-system",
      label: t("leftMenu.colour_system"),
      children: [
        { key: "light-mode", label: t("leftMenu.light_mode") },
        { key: "dark-mode", label: t("leftMenu.dark_mode") },
      ],
    }

    const saveToJson: MenuItem = {
      key: "save-to",
      label: t("leftMenu.save_to"),
      children: [
        { key: "google-classroom", label: t("leftMenu.google_classroom") },
        { key: "google-drive", label: t("leftMenu.google_drive") },
      ],
    }

    const newBoardJson: MenuItem = {
      key: "new-board",
      label: t("leftMenu.new_board"),
    }

    const dashBoardJson: MenuItem = {
      key: "back-to-dashboard",
      label: t("leftMenu.back_to_dashboard"),
    }

    const dashBoardDividJson: MenuItem = {
      type: "divider",
    }

    const helpJson: MenuItem = {
      key: "help",
      label: t("leftMenu.help"),
    }

    const helpDividJson: MenuItem = {
      type: "divider",
    }

    const prefer1: MenuItem = {
      key: "preferences",
      label: t("leftMenu.preferences"),
      children: [
        perferBoardColor,
        perferColorSystem,
        perferLanguage,
      ],
    }

    const prefer2: MenuItem = {
      key: "preferences",
      label: t("leftMenu.preferences"),
      children: [
        perferLanguage,
      ],
    }

    useEffect(() => {


      if (isCollaborating) {
        if (isHost) {
          setPreferences(prefer1)
        } else {
          setPreferences(prefer2)
        }
      } else {
        setPreferences(prefer1)
      }

    }, [isCollaborating, appState]);

    useEffect(() => {
      if (appState.userInfo?.roleEn === "Pro" || appState.userInfo?.roleEn === "Standard") {
        if (!isCollaborating) {
          setMenuSaveTo(saveToJson)
        } else {
          if (isHost) {
            setMenuSaveTo(saveToJson)
          }
        }
      }
      if (!hasMeetSDK) {
        setMenuNewBoard(newBoardJson)
        setMenuDashBoard(dashBoardJson)
        setMenuDashBoardDivid(dashBoardDividJson)
        setMenuHelp(helpJson)
        setMenuHelpDivid(helpDividJson)
      }

    }, []);



    const preferences1: MenuItem = {
      key: "preferences",
      label: t("leftMenu.preferences"),
      children: [
        { key: "board-colour", label: t("leftMenu.board_colour") },
        {
          key: "colour-system",
          label: t("leftMenu.colour_system"),
          children: [
            { key: "light-mode", label: t("leftMenu.light_mode") },
            { key: "dark-mode", label: t("leftMenu.dark_mode") },
          ],
        },
        {
          key: "language",
          label: t("leftMenu.language"),
          children: getLangList(),
        },
      ],
    }

    const items: MenuItem[] = [
      menuDashBoard,
      menuDashBoardDivid,
      {
        key: "file",
        label: t("leftMenu.file"),
        children: [
          {
            key: "open-board",
            label: t("leftMenu.open_board"),
            children: [
              { key: "recently-edited", label: t("leftMenu.recently_edited") },
              { key: "open-file", label: t("leftMenu.open_file") },
            ],
          },
          menuNewBoard,
          // {
          //   key: "page",
          //   label: "Add New Page",
          //   children: [
          //     { key: "add-new-page", label: "Add New Page" },
          //     {
          //       type: "divider",
          //     },
          //     ...pages.map((s) => {
          //       return {
          //         key: s.id,
          //         label: s.name,
          //       };
          //     }),
          //   ],
          // },
          {
            type: "divider",
          },
          {
            key: "save-to-local",
            label: t("leftMenu.save_to_local"),
          },
          menuSaveTo,
          {
            key: "scan-to-download",
            label: t("leftMenu.scan_to_download"),
          },
        ],
      },
      // {
      //   key: "edit",
      //   label: "Edit",
      //   children: [
      //     { key: "undo", label: "Undo" },
      //     { key: "redo", label: "Redo" },
      //   ],
      // },
      {
        key: "view",
        label: t("leftMenu.view"),
        children: [
          {
            key: "show-grid",
            label: t("leftMenu.show_grid"),
            children: [
              { key: "none-grid", label: t("leftMenu.none_grid") },
              { key: "line-grid", label: t("leftMenu.line_grid") },
              { key: "line-dash-grid", label: t("leftMenu.line_dashed_grid") },
              { key: "row-grid", label: t("leftMenu.row_grid") },
              { key: "five-line-grid", label: t("leftMenu.five_line_grid") },
              { key: "music-score-grid", label: t("leftMenu.music_score_grid") },
              { key: "mizi-grid", label: t("leftMenu.mizi_grid") },
            ],
          },
          {
            type: "divider",
          },
          // { key: "zoom-in", label: "Zoom In" },
          // { key: "zoom-out", label: "Zoom Out" },
          { key: "zoom-100%", label: t("leftMenu.zoom_100") },
          { key: "zoom-to-fit", label: t("leftMenu.zoom_to_fit") },
          //   { key: "12", label: "Zoom Selection" },
        ],
      },
      preferences,
      menuHelpDivid,
      menuHelp,
    ];

    const openUrl = async (url: string) => {
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("target", "_blank");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    const onMenuClick: MenuProps["onClick"] = (e) => {
      // setOpenKeys(e.keyPath);
      // console.log(e.keyPath)
      // setCurrent(e.key);

      if (getLangCodes().includes(e.key)) {
        setLangCode(e.key);
      } else {
        switch (e.key) {
          case "back-to-dashboard":
            location.href = "/dashboard";
            break;
          case "recently-edited":
            handleRecentlyEdited();
            break;
          case "open-file":
            handleLoadFile();
            break;
          case "new-board":
            handleNewBoard();
            break;
          case "add-new-page":
            handleAddPage();
            break;
          case "save-to-local":
            handleSaveFile();
            break;
          case "google-classroom":
          case "google-drive":
          case "save-to":
            handleSaveTo();
            break;
          case "scan-to-download":
            setAppState({ openDialog: "scanDownload" });
            break;
          case "undo":
            handleUndo();
            break;
          case "redo":
            handleRedo();
            break;
          case "none-grid":
            handleGrid();
            break;
          case "dot-grid":
          case "line-grid":
          case "line-dash-grid":
          case "row-grid":
          case "five-line-grid":
          case "music-score-grid":
          case "mizi-grid":
            handleGrid(e.key);
            break;
          case "zoom-in":
          case "zoom-out":
          case "zoom-100%":
          case "zoom-to-fit":
            handleZoom(e.key);
            break;
          case "board-colour":
            setAppState({ openDialog: "setBackground" });
            break;
          case "light-mode":
            handleSystemColor(e.key);
            break;
          case "dark-mode":
            handleSystemColor(e.key);
            break;
          case "help":
            const url = "https://imago.us/contact-us/";
            openUrl(url);
            break;
        }
        if (e.key.startsWith("page_")) {
          handleChangePage(e.key);
        }
      }

      onClick?.();
      //e.domEvent.stopPropagation();
    };

    const handleRecentlyEdited = () => {
      collabAPI?.loadUserSceneData();
    };
    async function handleLoadFile(): Promise<void> {
      await loadFromJSONPage(appState, operaPage);
      actionManager.executeAction(actionChangePage);
    }
    const handleNewBoard = async () => {
      await LocalData.clearPages();
      await LocalData.dataStateStorage.clear();
      PageManager.addPage({});
      location.href = "/board";
    };
    const handleAddPage = () => {
      const page = PageManager.addPage({});
      setPages([...pages, page]);

      actionManager.executeAction(actionChangePage);
    };
    const handleChangePage = (id: string) => {
      PageManager.changePage({ page: id });
      actionManager.executeAction(actionChangePage);
    };

    async function handleSaveFile(): Promise<void> {
      await saveAsJSONPage(appState, setAppState);
    }
    const handleSaveTo = () => {
      setAppState({ openDialog: "fileSaveTo" });
    };
    const handleUndo = () => {
      actionManager.executeAction(actionManager.actions.undo);
    };

    const handleRedo = () => {
      actionManager.executeAction(actionManager.actions.redo);
    };

    const handleZoom = (
      value: "zoom-in" | "zoom-out" | "zoom-100%" | "zoom-to-fit",
    ) => {
      switch (value) {
        case "zoom-in":
          actionManager.executeAction(actionZoomIn);
          break;
        case "zoom-out":
          actionManager.executeAction(actionZoomOut);
          break;
        case "zoom-100%":
          actionManager.executeAction(actionResetZoom);
          break;
        case "zoom-to-fit":
          actionManager.executeAction(actionZoomToFit);
          break;
      }
    };
    const handleGrid = (
      value?: undefined | "dot-grid" | "line-grid" | "line-dash-grid" | "row-grid" | "five-line-grid" | "music-score-grid" | "mizi-grid",
    ) => {
      if (!value) {
        setAppState({
          gridSize: null,
          gridStyle: null,
        });
      } else {
        setAppState({
          gridSize: GRID_SIZE,
          gridStyle: GRID_STYLE[
            value as keyof typeof GRID_STYLE
          ] as typeof appState.gridStyle,
        });
      }
    };

    const handleSystemColor = (mode: string) => {
      let viewBackgroundColor = "#ffffff";
      let currentItemStrokeColor = "#000000";
      if (mode == "dark-mode") {
        if (appState.viewBackgroundColor === "#ffffff") {
          viewBackgroundColor = "#2E4A62";
          currentItemStrokeColor = "#ffffff";
        }
      }
      onViewBackgroundColorChange?.(viewBackgroundColor);
      setAppState({
        viewBackgroundColor,
        currentItemStrokeColor,
      });
    };

    return (
      <>
        <Menu
          onClick={onMenuClick}
          style={{ width: 200 }}
          mode={mode}
          items={items}

        // getPopupContainer={(node) =>{
        //   return  node.parentNode as HTMLElement
        // }}
        />
      </>
    );
  };
