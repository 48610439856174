import {
  ImagoElement,
  ImagoLinearElement,
  ImagoTextElement,
  Arrowhead,
  NonDeletedImagoElement,
  ImagoFreeDrawElement,
  ImagoImageElement,
} from "../element/types";
import {
  isTextElement,
  isLinearElement,
  isFreeDrawElement,
  isInitializedImageElement,
} from "../element/typeChecks";
import {
  getDiamondPoints,
  getElementAbsoluteCoords,
  getArrowheadPoints,
} from "../element/bounds";
import { RoughCanvas } from "roughjs/bin/canvas";
import { Drawable, Options } from "roughjs/bin/core";
import { RoughSVG } from "roughjs/bin/svg";
import { RoughGenerator } from "roughjs/bin/generator";

import { RenderConfig } from "../scene/types";
import { distance, getFontString, getFontFamilyString, isRTL } from "../utils";
import { isPathALoop } from "../math";
import rough from "roughjs/bin/rough";
import { AppState, BinaryFiles, Zoom } from "../types";
import { getDefaultAppState } from "../appState";
import {
  BOUND_TEXT_PADDING,
  MAX_DECIMALS_FOR_SVG_EXPORT,
  MIME_TYPES,
  RUBBER_SIZE,
  SVG_NS,
  VERTICAL_ALIGN,
} from "../constants";
import { getStroke, StrokeOptions } from "perfect-freehand";
import { getApproxLineHeight } from "../element/textElement";
import { ellipseFixture } from "../tests/fixtures/elementFixture";
import { Point } from "../types";

// using a stronger invert (100% vs our regular 93%) and saturate
// as a temp hack to make images in dark theme look closer to original
// color scheme (it's still not quite there and the colors look slightly
// desatured, alas...)
const IMAGE_INVERT_FILTER = "invert(100%) hue-rotate(180deg) saturate(1.25)";

const defaultAppState = getDefaultAppState();

const isPendingImageElement = (
  element: ImagoElement,
  renderConfig: RenderConfig,
) =>
  isInitializedImageElement(element) &&
  !renderConfig.imageCache.has(element.fileId);

const shouldResetImageFilter = (
  element: ImagoElement,
  renderConfig: RenderConfig,
) => {
  return (
    renderConfig.theme === "dark" &&
    isInitializedImageElement(element) &&
    !isPendingImageElement(element, renderConfig) &&
    renderConfig.imageCache.get(element.fileId)?.mimeType !== MIME_TYPES.svg
  );
};

const getDashArrayDashed = (strokeWidth: number) => [8, 8 + strokeWidth];

const getDashArrayDotted = (strokeWidth: number) => [1.5, 6 + strokeWidth];

const getCanvasPadding = (element: ImagoElement) => {
  if (element.type === "freedraw") {
    return element.strokeWidth * 12;
  } else if (element.type === "eraserbig") {
    return element.strokeWidth * 12;
  }
  return 20;
};

export interface ImagoElementWithCanvas {
  element: ImagoElement | ImagoTextElement;
  canvas: HTMLCanvasElement;
  theme: RenderConfig["theme"];
  canvasZoom: Zoom["value"];
  canvasOffsetX: number;
  canvasOffsetY: number;
}

const generateElementCanvas = (
  element: NonDeletedImagoElement,
  zoom: Zoom,
  renderConfig: RenderConfig,
  appState: AppState,
): ImagoElementWithCanvas => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d")!;
  const padding = getCanvasPadding(element);
  let canvasOffsetX = 0;
  let canvasOffsetY = 0;

  if (isLinearElement(element) || isFreeDrawElement(element)) {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element);

    canvas.width =
      distance(x1, x2) * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;
    canvas.height =
      distance(y1, y2) * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;

    canvasOffsetX =
      element.x > x1
        ? distance(element.x, x1) * window.devicePixelRatio * zoom.value
        : 0;

    canvasOffsetY =
      element.y > y1
        ? distance(element.y, y1) * window.devicePixelRatio * zoom.value
        : 0;

    context.translate(canvasOffsetX, canvasOffsetY);
  } else {
    canvas.width =
      element.width * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;
    canvas.height =
      element.height * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;
  }

  context.save();
  context.translate(padding * zoom.value, padding * zoom.value);
  context.scale(
    window.devicePixelRatio * zoom.value,
    window.devicePixelRatio * zoom.value,
  );

  const rc = rough.canvas(canvas);

  // in dark theme, revert the image color filter
  if (shouldResetImageFilter(element, renderConfig)) {
    context.filter = IMAGE_INVERT_FILTER;
  }

  drawElementOnCanvas(element, rc, context, renderConfig, appState);
  context.restore();

  return {
    element,
    canvas,
    theme: renderConfig.theme,
    canvasZoom: zoom.value,
    canvasOffsetX,
    canvasOffsetY,
  };
};

export const DEFAULT_LINK_SIZE = 14;

const IMAGE_PLACEHOLDER_IMG = document.createElement("img");
IMAGE_PLACEHOLDER_IMG.src = `data:${MIME_TYPES.svg},${encodeURIComponent(
  `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" class="svg-inline--fa fa-image fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#888" d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z"></path></svg>`,
)}`;

const IMAGE_ERROR_PLACEHOLDER_IMG = document.createElement("img");
IMAGE_ERROR_PLACEHOLDER_IMG.src = `data:${MIME_TYPES.svg},${encodeURIComponent(
  `<svg viewBox="0 0 668 668" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2"><path d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48ZM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56ZM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48Z" style="fill:#888;fill-rule:nonzero" transform="matrix(.81709 0 0 .81709 124.825 145.825)"/><path d="M256 8C119.034 8 8 119.033 8 256c0 136.967 111.034 248 248 248s248-111.034 248-248S392.967 8 256 8Zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676ZM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676Z" style="fill:#888;fill-rule:nonzero" transform="matrix(.30366 0 0 .30366 506.822 60.065)"/></svg>`,
)}`;

const drawImagePlaceholder = (
  element: ImagoImageElement,
  context: CanvasRenderingContext2D,
  zoomValue: AppState["zoom"]["value"],
) => {
  context.fillStyle = "#E7E7E7";
  context.fillRect(0, 0, element.width, element.height);

  const imageMinWidthOrHeight = Math.min(element.width, element.height);

  const size = Math.min(
    imageMinWidthOrHeight,
    Math.min(imageMinWidthOrHeight * 0.4, 100),
  );

  context.drawImage(
    element.status === "error"
      ? IMAGE_ERROR_PLACEHOLDER_IMG
      : IMAGE_PLACEHOLDER_IMG,
    element.width / 2 - size / 2,
    element.height / 2 - size / 2,
    size,
    size,
  );
};

const drawElementOnCanvas = (
  element: NonDeletedImagoElement,
  rc: RoughCanvas,
  context: CanvasRenderingContext2D,
  renderConfig: RenderConfig,
  appState: AppState,
) => {
  context.globalAlpha = element.opacity / 100;

  //console.log("element",element.x,element.y,"===========================================")
  switch (element.type) {
    case "rectangle":
    case "square":  
    case "triangle":
    case "pentagon":
    case "hexagon":
    case "octagon":
    case "circle":
    case "perspective":
    case "rosette":
    case "star":
    case "cylinder":
    case "heart":
    case "tick":
    case "cross":
    case "diamond":
    case "ellipse": {
      context.lineJoin = "round";
      context.lineCap = "round";

      rc.draw(getShapeForElement(element)!);


      // drawErasure(element,context,appState);

      break;
    }
    case "arrow":
    case "connectarrow":
    case "line": {
      context.lineJoin = "round";
      context.lineCap = "round";

      getShapeForElement(element)!.forEach((shape) => {
        rc.draw(shape);
      });

      //drawErasure(element,context,appState);

      break;
    }
    case "marker":
    case "magicpen":
    case "magictext":
    case "freedraw": {
      // Draw directly to canvas
      context.save();
      context.fillStyle = element.strokeColor;
      const path = getFreeDrawPath2D(element) as Path2D;
      const fillShape = getShapeForElement(element);

      if (fillShape) {
        rc.draw(fillShape);
      }

      context.fillStyle = element.strokeColor;
      context.beginPath();
      context.fill(path);
      context.closePath();

      //drawErasure(element,context,appState);

      context.restore();
      break;
    }
    case "eraserbig": {
      // Draw directly to canvas
      context.save();
      context.fillStyle = element.strokeColor;
      const path = getBrushPath2D(element);
      const fillShape = getShapeForElement(element);

      if (fillShape) {
        rc.draw(fillShape);
      }

      context.fillStyle = element.strokeColor;
      context.beginPath();
      context.fill(path);
      context.closePath();

      //drawErasure(element,context,appState);

      context.restore();
      break;
    }
    case "image": {
      const img = isInitializedImageElement(element)
        ? renderConfig.imageCache.get(element.fileId)?.image
        : undefined;
      if (img != null && !(img instanceof Promise)) {
        context.drawImage(
          img,
          0 /* hardcoded for the selection box*/,
          0,
          element.width,
          element.height,
        );
      } else {
        drawImagePlaceholder(element, context, renderConfig.zoom.value);
      }
      //drawErasure(element,context,appState);

      break;
    }
    default: {
      if (isTextElement(element)) {
        const rtl = isRTL(element.text);
        const shouldTemporarilyAttach = rtl && !context.canvas.isConnected;
        if (shouldTemporarilyAttach) {
          // to correctly render RTL text mixed with LTR, we have to append it
          // to the DOM
          document.body.appendChild(context.canvas);
        }
        context.canvas.setAttribute("dir", rtl ? "rtl" : "ltr");
        context.save();
        context.font = getFontString(element);
        context.fillStyle = element.strokeColor;
        context.textAlign = element.textAlign as CanvasTextAlign;

        // Canvas does not support multiline text by default
        const lines = element.text.replace(/\r\n?/g, "\n").split("\n");
        const lineHeight = element.containerId
          ? getApproxLineHeight(getFontString(element))
          : element.height / lines.length;
        let verticalOffset = element.height - element.baseline;
        if (element.verticalAlign === VERTICAL_ALIGN.BOTTOM) {
          verticalOffset = BOUND_TEXT_PADDING;
        }

        const horizontalOffset =
          element.textAlign === "center"
            ? element.width / 2
            : element.textAlign === "right"
              ? element.width
              : 0;
        for (let index = 0; index < lines.length; index++) {
          context.fillText(
            lines[index],
            horizontalOffset,
            (index + 1) * lineHeight - verticalOffset,
          );
        }

        //drawErasure(element,context,appState);

        context.restore();
        if (shouldTemporarilyAttach) {
          context.canvas.remove();
        }
      } else {
        throw new Error(`Unimplemented type ${element.type}`);
      }
    }
  }
  context.globalAlpha = 1;
  context.globalCompositeOperation = 'destination-out';
  element.erasurePoints?.forEach(ps => {
    rc.draw(generateElementErasureShape(ps, rc.generator, appState));
  });
};

// const drawErasure = (element:ImagoElement,context: CanvasRenderingContext2D, appState: AppState)=>{
//   const erasurePaths = getErasurePath2D(element);
//   erasurePaths && erasurePaths.forEach(path=>{
//     context.fillStyle = appState.viewBackgroundColor;
//       context.globalAlpha = 1;
//       context.beginPath();
//       context.fill(path);
//       context.closePath();
//   })
// }

const elementWithCanvasCache = new WeakMap<
  ImagoElement,
  ImagoElementWithCanvas
>();

const shapeCache = new WeakMap<ImagoElement, ElementShape>();

type ElementShape = Drawable | Drawable[] | null;

type ElementShapes = {
  freedraw: Drawable | null;
  eraserbig: Drawable | null;
  arrow: Drawable[];
  connectarrow: Drawable[];
  line: Drawable[];
  text: null;
  image: null;
  marker: Drawable | null;
  magicpen: Drawable | null;
  magictext: Drawable | null;
};

export const getShapeForElement = <T extends ImagoElement>(element: T) =>
  shapeCache.get(element) as T["type"] extends keyof ElementShapes
  ? ElementShapes[T["type"]] | undefined
  : Drawable | null | undefined;

export const setShapeForElement = <T extends ImagoElement>(
  element: T,
  shape: T["type"] extends keyof ElementShapes
    ? ElementShapes[T["type"]]
    : Drawable,
) => shapeCache.set(element, shape);

export const invalidateShapeForElement = (element: ImagoElement) =>
  shapeCache.delete(element);

export const generateRoughOptions = (
  element: ImagoElement,
  continuousPath = false,
): Options => {
  const options: Options = {
    seed: element.seed,
    strokeLineDash:
      element.strokeStyle === "dashed"
        ? getDashArrayDashed(element.strokeWidth)
        : element.strokeStyle === "dotted"
          ? getDashArrayDotted(element.strokeWidth)
          : undefined,
    // for non-solid strokes, disable multiStroke because it tends to make
    // dashes/dots overlay each other
    disableMultiStroke: element.strokeStyle !== "solid",
    // for non-solid strokes, increase the width a bit to make it visually
    // similar to solid strokes, because we're also disabling multiStroke
    strokeWidth:
      element.strokeStyle !== "solid"
        ? element.strokeWidth + 0.5
        : element.strokeWidth,
    // when increasing strokeWidth, we must explicitly set fillWeight and
    // hachureGap because if not specified, roughjs uses strokeWidth to
    // calculate them (and we don't want the fills to be modified)
    fillWeight: element.strokeWidth / 2,
    hachureGap: element.strokeWidth * 4,
    roughness: element.roughness,
    stroke: element.strokeColor,
    preserveVertices: continuousPath,
  };

  switch (element.type) {
    case "rectangle":
    case "square":
    case "triangle":
    case "pentagon":
    case "hexagon":
    case "octagon":
    case "circle":
    case "perspective":
    case "rosette":
    case "star":
    case "cylinder":   
    case "heart":
    case "tick":
    case "cross":
    case "diamond":
    case "ellipse": {
      options.fillStyle = element.fillStyle;
      options.fill =
        element.backgroundColor === "transparent"
          ? undefined
          : element.backgroundColor;
      if (element.type === "ellipse") {
        options.curveFitting = 1;
      }
      return options;
    }
    case "line":
    case "marker":
    case "magicpen":
    case "magictext":
    case "freedraw": {
      if (isPathALoop(element.points)) {
        options.fillStyle = element.fillStyle;
        options.fill =
          element.backgroundColor === "transparent"
            ? undefined
            : element.backgroundColor;
      }
      return options;
    }
    case "eraserbig": {
      if (isPathALoop(element.points)) {
        options.fillStyle = element.fillStyle;
        options.fill =
          element.backgroundColor === "transparent"
            ? undefined
            : element.backgroundColor;
      }
      return options;
    }
    case "arrow":
    case "connectarrow":
      return options;
    default: {
      throw new Error(`Unimplemented type ${element.type}`);
    }
  }
};

/**
 * Generates the element's shape and puts it into the cache.
 * @param element
 * @param generator
 */
const generateElementShape = (
  element: NonDeletedImagoElement,
  generator: RoughGenerator,
) => {
  let shape = shapeCache.get(element);

  // `null` indicates no rc shape applicable for this element type
  // (= do not generate anything)
  if (shape === undefined) {
    elementWithCanvasCache.delete(element);

    switch (element.type) {
      case "rectangle":
        if (element.strokeSharpness === "round") {
          const w = element.width;
          const h = element.height;
          const r = Math.min(w, h) * 0.25;
          shape = generator.path(
            `M ${r} 0 L ${w - r} 0 Q ${w} 0, ${w} ${r} L ${w} ${h - r
            } Q ${w} ${h}, ${w - r} ${h} L ${r} ${h} Q 0 ${h}, 0 ${h - r
            } L 0 ${r} Q 0 0, ${r} 0`,
            generateRoughOptions(element, true),
          );
        } else {
          shape = generator.rectangle(
            0,
            0,
            element.width,
            element.height,
            generateRoughOptions(element),
          );
        }

        setShapeForElement(element, shape);

        break;

      case "square":
        const size = Math.min(element.width, element.height);
      
        if (element.strokeSharpness === "round") {
          const r = size * 0.25;
          shape = generator.path(
            `M ${r} 0 L ${size - r} 0 Q ${size} 0, ${size} ${r} L ${size} ${size - r
            } Q ${size} ${size}, ${size - r} ${size} L ${r} ${size} Q 0 ${size}, 0 ${size - r
            } L 0 ${r} Q 0 0, ${r} 0`,
            generateRoughOptions(element, true),
          );
        } else {
          shape = generator.rectangle(
            0,
            0,
            size,
            size,
            generateRoughOptions(element),
          );
        }
      
        setShapeForElement(element, shape);
        break;

      case "triangle":
        const base = element.width;
        const height = element.height;
      
        if (element.strokeSharpness === "round") {
          shape = generator.path(
            `M 0 ${height} 
              L ${base / 2} 0 
              L ${base} ${height} 
              Q ${base / 2} ${height - 10}, 0 ${height}`,
            generateRoughOptions(element, true)
          );
        } else {
          shape = generator.polygon(
            [
              [0, height],
              [base / 2, 0],
              [base, height],
            ],
            generateRoughOptions(element)
          );
        }
      
        setShapeForElement(element, shape);
        break;
      
      case "pentagon":
      {
        const w = element.width;
        const h = element.height;
        const centerX = w / 2;
        const centerY = h / 2;
        const radius = Math.min(w, h) / 2;

        const pentagonPoints = Array.from({ length: 5 }, (_, i) => {
          const angle = ((Math.PI * 2) / 5) * i - Math.PI / 2;
          return `${centerX + radius * Math.cos(angle)} ${centerY + radius * Math.sin(angle)}`;
        }).join(" L ");

        if (element.strokeSharpness === "round") {
          shape = generator.path(`M ${pentagonPoints} Z`, generateRoughOptions(element, true));
        } else {
          shape = generator.polygon(
            Array.from({ length: 5 }, (_, i) => {
              const angle = ((Math.PI * 2) / 5) * i - Math.PI / 2;
              return [centerX + radius * Math.cos(angle), centerY + radius * Math.sin(angle)];
            }),
            generateRoughOptions(element)
          );
        }
      }
      setShapeForElement(element, shape);
      break;

      case "hexagon":
        {
          const w = element.width;
          const h = element.height;
          const centerX = w / 2;
          const centerY = h / 2;
          const radius = Math.min(w, h) / 2;

          const hexagonPoints = Array.from({ length: 6 }, (_, i) => {
            const angle = ((Math.PI * 2) / 6) * i - Math.PI / 2;
            return `${centerX + radius * Math.cos(angle)} ${centerY + radius * Math.sin(angle)}`;
          }).join(" L ");

          if (element.strokeSharpness === "round") {
            shape = generator.path(`M ${hexagonPoints} Z`, generateRoughOptions(element, true));
          } else {
            shape = generator.polygon(
              Array.from({ length: 6 }, (_, i) => {
                const angle = ((Math.PI * 2) / 6) * i - Math.PI / 2;
                return [centerX + radius * Math.cos(angle), centerY + radius * Math.sin(angle)];
              }),
              generateRoughOptions(element)
            );
          }
        }
        setShapeForElement(element, shape);
        break;

      case "octagon":
        {
          const w = element.width;
          const h = element.height;
          const centerX = w / 2;
          const centerY = h / 2;
          const radius = Math.min(w, h) / 2;

          const octagonPoints = Array.from({ length: 8 }, (_, i) => {
            const angle = ((Math.PI * 2) / 8) * i - Math.PI / 2;
            return `${centerX + radius * Math.cos(angle)} ${centerY + radius * Math.sin(angle)}`;
          }).join(" L ");

          if (element.strokeSharpness === "round") {
            shape = generator.path(`M ${octagonPoints} Z`, generateRoughOptions(element, true));
          } else {
            shape = generator.polygon(
              Array.from({ length: 8 }, (_, i) => {
                const angle = ((Math.PI * 2) / 8) * i - Math.PI / 2;
                return [centerX + radius * Math.cos(angle), centerY + radius * Math.sin(angle)];
              }),
              generateRoughOptions(element)
            );
          }
        }
        setShapeForElement(element, shape);
        break;

      case "diamond": 
      {
        const [topX, topY, rightX, rightY, bottomX, bottomY, leftX, leftY] =
          getDiamondPoints(element);
        if (element.strokeSharpness === "round") {
          shape = generator.path(
            `M ${topX + (rightX - topX) * 0.25} ${topY + (rightY - topY) * 0.25
            } L ${rightX - (rightX - topX) * 0.25} ${rightY - (rightY - topY) * 0.25
            }
            C ${rightX} ${rightY}, ${rightX} ${rightY}, ${rightX - (rightX - bottomX) * 0.25
            } ${rightY + (bottomY - rightY) * 0.25}
            L ${bottomX + (rightX - bottomX) * 0.25} ${bottomY - (bottomY - rightY) * 0.25
            }
            C ${bottomX} ${bottomY}, ${bottomX} ${bottomY}, ${bottomX - (bottomX - leftX) * 0.25
            } ${bottomY - (bottomY - leftY) * 0.25}
            L ${leftX + (bottomX - leftX) * 0.25} ${leftY + (bottomY - leftY) * 0.25
            }
            C ${leftX} ${leftY}, ${leftX} ${leftY}, ${leftX + (topX - leftX) * 0.25
            } ${leftY - (leftY - topY) * 0.25}
            L ${topX - (topX - leftX) * 0.25} ${topY + (leftY - topY) * 0.25}
            C ${topX} ${topY}, ${topX} ${topY}, ${topX + (rightX - topX) * 0.25
            } ${topY + (rightY - topY) * 0.25}`,
            generateRoughOptions(element, true),
          );
        } else {
          shape = generator.polygon(
            [
              [topX, topY],
              [rightX, rightY],
              [bottomX, bottomY],
              [leftX, leftY],
            ],
            generateRoughOptions(element),
          );
        }
        setShapeForElement(element, shape);

        break;
      }

      case "circle":
      {
        const radius = Math.min(element.width, element.height) / 2;
        const centerX = radius;
        const centerY = radius;
        
        if (element.strokeSharpness === "round") {
          shape = generator.path(
            `M ${centerX} 0 
            A ${radius} ${radius} 0 1 0 ${centerX} ${radius * 2} 
            A ${radius} ${radius} 0 1 0 ${centerX} 0 Z`,
            generateRoughOptions(element, true)
          );
        } else {
          shape = generator.circle(
            centerX,
            centerY,
            radius * 2,
            generateRoughOptions(element)
          );
        }
      }
      setShapeForElement(element, shape);
      break;

      case "perspective":
      {
        const w = element.width;
        const h = element.height;
        const depth = w * 0.3;

       
        const points = [
          [depth, 0],
          [w - depth, 0],
          [w, h],
          [0, h],
        ];

        shape = generator.path(
          `M ${points.map(([x, y]) => `${x},${y}`).join(" L ")} Z`,
          generateRoughOptions(element, true)
        );

        setShapeForElement(element, shape);
      }
      break;
   
      case "rosette":
      {
        const centerX = element.width / 2;
        const centerY = element.height / 2;
        const outerRadius = Math.min(element.width, element.height) / 2.4; // Smaller outer boundary for better petal separation
        const petalRadius = outerRadius * 1.5; // Increase petal length for distinct shape
        const petals = 8;
        let pathData = "";

        for (let i = 0; i < petals; i++) {
          const angle = (Math.PI * 2 * i) / petals;
          const nextAngle = (Math.PI * 2 * (i + 1)) / petals;

          const petalStartX = centerX + outerRadius * Math.cos(angle);
          const petalStartY = centerY + outerRadius * Math.sin(angle);

          // Adjust control points for more distinct, deeper curves
          const controlX1 = centerX + petalRadius * Math.cos(angle + Math.PI / petals);
          const controlY1 = centerY + petalRadius * Math.sin(angle + Math.PI / petals);

          const controlX2 = centerX + petalRadius * Math.cos(nextAngle - Math.PI / petals);
          const controlY2 = centerY + petalRadius * Math.sin(nextAngle - Math.PI / petals);

          const petalEndX = centerX + outerRadius * Math.cos(nextAngle);
          const petalEndY = centerY + outerRadius * Math.sin(nextAngle);

          if (i === 0) {
            pathData += `M ${petalStartX} ${petalStartY} `;
          }

          // Bézier curve for deeper, more distinct petals
          pathData += `C ${controlX1} ${controlY1}, ${controlX2} ${controlY2}, ${petalEndX} ${petalEndY} `;
        }
        pathData += "Z"; // Close the shape

        // Generate a more distinct flower shape
        shape = generator.path(pathData, generateRoughOptions(element, true));

        setShapeForElement(element, shape);
      }
      break;

      case "star":
      {
        const centerX = element.width / 2;
        const centerY = element.height / 2;
        const outerRadius = Math.min(element.width, element.height) / 2;
        const innerRadius = outerRadius / 2.5;
        const points = 5;

        let starPoints: [number, number][] = [];

        for (let i = 0; i < points * 2; i++) {
          const angle = (Math.PI / points) * i - Math.PI / 2;
          const radius = i % 2 === 0 ? outerRadius : innerRadius;
          starPoints.push([
            centerX + radius * Math.cos(angle),
            centerY + radius * Math.sin(angle),
          ]);
        }

        shape = generator.polygon(starPoints, generateRoughOptions(element));

        setShapeForElement(element, shape);
      }
      break;

      case "cylinder":
      {
        const w = element.width;
        const h = element.height;
        const centerX = w / 2;
        const topRadius = w / 2;
        const bottomRadius = w / 2;
        const ellipseHeight = h * 0.2;
    
        const cylinderPath = `
          M ${centerX - topRadius}, ${ellipseHeight}
          A ${topRadius} ${ellipseHeight} 0 1 1 ${centerX + topRadius}, ${ellipseHeight}
          L ${centerX + bottomRadius}, ${h - ellipseHeight}
          A ${bottomRadius} ${ellipseHeight} 0 1 1 ${centerX - bottomRadius}, ${h - ellipseHeight}
          L ${centerX - topRadius}, ${ellipseHeight}
          Z
        `;
    
        shape = generator.path(cylinderPath, generateRoughOptions(element));
    
        setShapeForElement(element, shape);
      }
      break;
  
      case "heart":
      {
        const w = element.width;
        const h = element.height;
        const centerX = w / 2;
        const centerY = h / 7;

        const leftCurveX = centerX - w / 4;
        const rightCurveX = centerX + w / 4;
        const bottomX = centerX;
        const bottomY = h;

        const heartPath = `
          M ${centerX} ${h * 0.9}
          C ${leftCurveX - w / 6} ${h * 0.6}, ${centerX - w / 2} ${h * 0.2}, ${centerX} ${h * 0.3}
          C ${centerX + w / 2} ${h * 0.2}, ${rightCurveX + w / 6} ${h * 0.6}, ${centerX} ${h * 0.9}
          Z
        `;

        shape = generator.path(heartPath, generateRoughOptions(element, true));

        setShapeForElement(element, shape);
      }
      break;

      case "tick":
        {        
          const w = element.width;
          const h = element.height;
      
          const path = `
            M ${w * 0.1} ${h * 0.6}
            L ${w * 0.3} ${h * 0.8}
            L ${w * 0.87} ${h * 0.29}
            L ${w * 1.0} ${h * 0.4}
            L ${w * 0.35} ${h * 1.0}
            L ${w * 0.0} ${h * 0.7}
            Z
          `;
      
          shape = generator.path(path, generateRoughOptions(element, true));
      
          setShapeForElement(element, shape);
        }
        break;
        
      case "cross": 
      {        
        const w = element.width;
        const h = element.height;

        const path = `
          M ${w * 0.2} ${h * 0.0}
          L ${w * 0.5} ${h * 0.4}
          L ${w * 0.8} ${h * 0.0}
          L ${w * 1.0} ${h * 0.2}
          L ${w * 0.6} ${h * 0.5}
          L ${w * 1.0} ${h * 0.8}
          L ${w * 0.8} ${h * 1.0}
          L ${w * 0.5} ${h * 0.6}
          L ${w * 0.2} ${h * 1.0}
          L ${w * 0.0} ${h * 0.8}
          L ${w * 0.4} ${h * 0.5}
          L ${w * 0.0} ${h * 0.2}
          Z
        `;

        shape = generator.path(path, generateRoughOptions(element, true));

        setShapeForElement(element, shape);
      }
      break;

      case "ellipse":
        shape = generator.ellipse(
          element.width / 2,
          element.height / 2,
          element.width,
          element.height,
          generateRoughOptions(element),
        );
        setShapeForElement(element, shape);

        break;
      case "line":
      case "arrow":
      case "connectarrow": {
        const options = generateRoughOptions(element);

        // points array can be empty in the beginning, so it is important to add
        // initial position to it
        const points = element.points.length ? element.points : [[0, 0]];

        // curve is always the first element
        // this simplifies finding the curve for an element
        if (element.strokeSharpness === "sharp") {
          if (options.fill) {
            shape = [generator.polygon(points as [number, number][], options)];
          } else {
            shape = [
              generator.linearPath(points as [number, number][], options),
            ];
          }
        } else {
          shape = [generator.curve(points as [number, number][], options)];
        }

        // add lines only in arrow
        if (element.type === "arrow" || element.type === "connectarrow") {
          const { startArrowhead = null, endArrowhead = "arrow" } = element;

          const getArrowheadShapes = (
            element: ImagoLinearElement,
            shape: Drawable[],
            position: "start" | "end",
            arrowhead: Arrowhead,
          ) => {
            const arrowheadPoints = getArrowheadPoints(
              element,
              shape,
              position,
              arrowhead,
            );

            if (arrowheadPoints === null) {
              return [];
            }

            // Other arrowheads here...
            if (arrowhead === "dot") {
              const [x, y, r] = arrowheadPoints;

              return [
                generator.circle(x, y, r, {
                  ...options,
                  fill: element.strokeColor,
                  fillStyle: "solid",
                  stroke: "none",
                }),
              ];
            }

            if (arrowhead === "triangle") {
              const [x, y, x2, y2, x3, y3] = arrowheadPoints;

              // always use solid stroke for triangle arrowhead
              delete options.strokeLineDash;

              return [
                generator.polygon(
                  [
                    [x, y],
                    [x2, y2],
                    [x3, y3],
                    [x, y],
                  ],
                  {
                    ...options,
                    fill: element.strokeColor,
                    fillStyle: "solid",
                  },
                ),
              ];
            }

            // Arrow arrowheads
            const [x2, y2, x3, y3, x4, y4] = arrowheadPoints;

            if (element.strokeStyle === "dotted") {
              // for dotted arrows caps, reduce gap to make it more legible
              const dash = getDashArrayDotted(element.strokeWidth - 1);
              options.strokeLineDash = [dash[0], dash[1] - 1];
            } else {
              // for solid/dashed, keep solid arrow cap
              delete options.strokeLineDash;
            }
            return [
              generator.line(x3, y3, x2, y2, options),
              generator.line(x4, y4, x2, y2, options),
            ];
          };

          if (startArrowhead !== null) {
            const shapes = getArrowheadShapes(
              element,
              shape,
              "start",
              startArrowhead,
            );
            shape.push(...shapes);
          }

          if (endArrowhead !== null) {
            if (endArrowhead === undefined) {
              // Hey, we have an old arrow here!
            }

            const shapes = getArrowheadShapes(
              element,
              shape,
              "end",
              endArrowhead,
            );
            shape.push(...shapes);
          }
        }

        setShapeForElement(element, shape);

        break;
      }
      case "marker":
      case "magicpen":
      case "magictext":
      case "freedraw": {
        generateFreeDrawShape(element);

        if (isPathALoop(element.points)) {
          // generate rough polygon to fill freedraw shape
          shape = generator.polygon(element.points as [number, number][], {
            ...generateRoughOptions(element),
            stroke: "none",
          });
        } else {
          shape = null;
        }
        setShapeForElement(element, shape);
        break;
      }
      case "eraserbig": {
        generateFreeDrawShape(element);

        if (isPathALoop(element.points)) {
          // generate rough polygon to fill freedraw shape
          shape = generator.polygon(element.points as [number, number][], {
            ...generateRoughOptions(element),
            stroke: "none",
          });
        } else {
          shape = null;
        }
        setShapeForElement(element, shape);
        break;
      }
      case "text":
      case "image": {
        // just to ensure we don't regenerate element.canvas on rerenders
        setShapeForElement(element, null);
        break;
      }
    }

    // generateErasurePath(element);
  }
};

const generateElementErasureShape = (
  point: Point,
  generator: RoughGenerator,
  appState: AppState
) => {


  const options: Options = {
    seed: 0,
    strokeWidth: 0.1,
    stroke: appState.viewBackgroundColor,
    fill: appState.viewBackgroundColor,
    fillStyle: "solid",
    roughness: 0
  };


  return generator.rectangle(
    point[0] - RUBBER_SIZE.width / 2,
    point[1] - RUBBER_SIZE.height / 2,
    RUBBER_SIZE.width,
    RUBBER_SIZE.height,
    options,
  );
};



const generateElementWithCanvas = (
  element: NonDeletedImagoElement,
  renderConfig: RenderConfig,
  appState: AppState,
) => {
  const zoom: Zoom = renderConfig ? renderConfig.zoom : defaultAppState.zoom;


  const prevElementWithCanvas = elementWithCanvasCache.get(element);


  const shouldRegenerateBecauseZoom =
    prevElementWithCanvas &&
    prevElementWithCanvas.canvasZoom !== zoom.value &&
    !renderConfig?.shouldCacheIgnoreZoom;

  const shouldRegenerateBecauseErasure = element.erasing;
  if (
    !prevElementWithCanvas ||
    shouldRegenerateBecauseZoom ||
    prevElementWithCanvas.theme !== renderConfig.theme ||
    shouldRegenerateBecauseErasure
  ) {
    const elementWithCanvas = generateElementCanvas(
      element,
      zoom,
      renderConfig,
      appState
    );
    elementWithCanvasCache.set(element, elementWithCanvas);

    return elementWithCanvas;
  }
  return prevElementWithCanvas;
};

const drawElementFromCanvas = (
  elementWithCanvas: ImagoElementWithCanvas,
  rc: RoughCanvas,
  context: CanvasRenderingContext2D,
  renderConfig: RenderConfig,
) => {
  const element = elementWithCanvas.element;
  const padding = getCanvasPadding(element);
  let [x1, y1, x2, y2] = getElementAbsoluteCoords(element);

  // Free draw elements will otherwise "shuffle" as the min x and y change
  if (isFreeDrawElement(element)) {
    x1 = Math.floor(x1);
    x2 = Math.ceil(x2);
    y1 = Math.floor(y1);
    y2 = Math.ceil(y2);
  }

  const cx = ((x1 + x2) / 2 + renderConfig.scrollX) * window.devicePixelRatio;
  const cy = ((y1 + y2) / 2 + renderConfig.scrollY) * window.devicePixelRatio;

  const _isPendingImageElement = isPendingImageElement(element, renderConfig);

  const scaleXFactor =
    "scale" in elementWithCanvas.element && !_isPendingImageElement
      ? elementWithCanvas.element.scale[0]
      : 1;
  const scaleYFactor =
    "scale" in elementWithCanvas.element && !_isPendingImageElement
      ? elementWithCanvas.element.scale[1]
      : 1;

  context.save();
  context.scale(
    (1 / window.devicePixelRatio) * scaleXFactor,
    (1 / window.devicePixelRatio) * scaleYFactor,
  );
  context.translate(cx * scaleXFactor, cy * scaleYFactor);
  context.rotate(element.angle * scaleXFactor * scaleYFactor);

  context.drawImage(
    elementWithCanvas.canvas!,
    (-(x2 - x1) / 2) * window.devicePixelRatio -
    (padding * elementWithCanvas.canvasZoom) / elementWithCanvas.canvasZoom,
    (-(y2 - y1) / 2) * window.devicePixelRatio -
    (padding * elementWithCanvas.canvasZoom) / elementWithCanvas.canvasZoom,
    elementWithCanvas.canvas!.width / elementWithCanvas.canvasZoom,
    elementWithCanvas.canvas!.height / elementWithCanvas.canvasZoom,
  );
  context.restore();

  // Clear the nested element we appended to the DOM
};

export const renderElement = (
  element: NonDeletedImagoElement,
  rc: RoughCanvas,
  context: CanvasRenderingContext2D,
  renderConfig: RenderConfig,
  appState: AppState,
) => {
  const generator = rc.generator;

  switch (element.type) {
    case "selection": {
      context.save();
      context.translate(
        element.x + renderConfig.scrollX,
        element.y + renderConfig.scrollY,
      );
      context.fillStyle = "rgba(0, 0, 200, 0.04)";

      // render from 0.5px offset  to get 1px wide line
      // https://stackoverflow.com/questions/7530593/html5-canvas-and-line-width/7531540#7531540
      // TODO can be be improved by offseting to the negative when user selects
      // from right to left
      const offset = 0.5 / renderConfig.zoom.value;

      context.fillRect(offset, offset, element.width, element.height);
      context.lineWidth = 1 / renderConfig.zoom.value;
      context.strokeStyle = "rgb(105, 101, 219)";
      context.strokeRect(offset, offset, element.width, element.height);

      context.restore();
      break;
    }
    case "marker":
    case "magicpen":
    case "magictext":
    case "freedraw": {
      generateElementShape(element, generator);

      if (renderConfig.isExporting) {
        const [x1, y1, x2, y2] = getElementAbsoluteCoords(element);
        const cx = (x1 + x2) / 2 + renderConfig.scrollX;
        const cy = (y1 + y2) / 2 + renderConfig.scrollY;
        const shiftX = (x2 - x1) / 2 - (element.x - x1);
        const shiftY = (y2 - y1) / 2 - (element.y - y1);
        context.save();
        context.translate(cx, cy);
        context.rotate(element.angle);
        context.translate(-shiftX, -shiftY);
        drawElementOnCanvas(element, rc, context, renderConfig, appState);
        context.restore();
      } else {
        const elementWithCanvas = generateElementWithCanvas(
          element,
          renderConfig,
          appState,
        );
        drawElementFromCanvas(elementWithCanvas, rc, context, renderConfig);
      }

      break;
    }
    case "eraserbig": {
      generateElementShape(element, generator);

      if (renderConfig.isExporting) {
        const [x1, y1, x2, y2] = getElementAbsoluteCoords(element);
        const cx = (x1 + x2) / 2 + renderConfig.scrollX;
        const cy = (y1 + y2) / 2 + renderConfig.scrollY;
        const shiftX = (x2 - x1) / 2 - (element.x - x1);
        const shiftY = (y2 - y1) / 2 - (element.y - y1);
        context.save();
        context.translate(cx, cy);
        context.rotate(element.angle);
        context.translate(-shiftX, -shiftY);
        drawElementOnCanvas(element, rc, context, renderConfig, appState);
        context.restore();
      } else {
        const elementWithCanvas = generateElementWithCanvas(
          element,
          renderConfig,
          appState,
        );
        drawElementFromCanvas(elementWithCanvas, rc, context, renderConfig);
      }

      break;
    }
    case "rectangle":
    case "square":
    case "triangle":
    case "pentagon":
    case "hexagon":
    case "octagon":
    case "circle":
    case "perspective":
    case "rosette":
    case "star":
    case "cylinder":  
    case "heart":
    case "tick":
    case "cross":
    case "diamond":
    case "ellipse":
    case "line":
    case "arrow":
    case "connectarrow":
    case "image":
    case "text": {
      generateElementShape(element, generator);

      if (renderConfig.isExporting) {
        const [x1, y1, x2, y2] = getElementAbsoluteCoords(element);
        const cx = (x1 + x2) / 2 + renderConfig.scrollX;
        const cy = (y1 + y2) / 2 + renderConfig.scrollY;
        const shiftX = (x2 - x1) / 2 - (element.x - x1);
        const shiftY = (y2 - y1) / 2 - (element.y - y1);
        context.save();
        context.translate(cx, cy);
        context.rotate(element.angle);
        if (element.type === "image") {
          context.scale(element.scale[0], element.scale[1]);
        }
        context.translate(-shiftX, -shiftY);

        if (shouldResetImageFilter(element, renderConfig)) {
          context.filter = "none";
        }

        drawElementOnCanvas(element, rc, context, renderConfig, appState);
        context.restore();
        // not exporting → optimized rendering (cache & render from element
        // canvases)
      } else {
        const elementWithCanvas = generateElementWithCanvas(
          element,
          renderConfig,
          appState,
        );

        drawElementFromCanvas(elementWithCanvas, rc, context, renderConfig);
      }
      break;
    }
    default: {
      // @ts-ignore
      throw new Error(`Unimplemented type ${element.type}`);
    }
  }
};

const roughSVGDrawWithPrecision = (
  rsvg: RoughSVG,
  drawable: Drawable,
  precision?: number,
) => {
  if (typeof precision === "undefined") {
    return rsvg.draw(drawable);
  }
  const pshape: Drawable = {
    sets: drawable.sets,
    shape: drawable.shape,
    options: { ...drawable.options, fixedDecimalPlaceDigits: precision },
  };
  return rsvg.draw(pshape);
};

export const renderElementToSvg = (
  element: NonDeletedImagoElement,
  rsvg: RoughSVG,
  svgRoot: SVGElement,
  files: BinaryFiles,
  offsetX?: number,
  offsetY?: number,
  exportWithDarkMode?: boolean,
) => {
  const [x1, y1, x2, y2] = getElementAbsoluteCoords(element);
  const cx = (x2 - x1) / 2 - (element.x - x1);
  const cy = (y2 - y1) / 2 - (element.y - y1);
  const degree = (180 * element.angle) / Math.PI;
  const generator = rsvg.generator;

  // element to append node to, most of the time svgRoot
  let root = svgRoot;

  // if the element has a link, create an anchor tag and make that the new root
  if (element.link) {
    const anchorTag = svgRoot.ownerDocument!.createElementNS(SVG_NS, "a");
    anchorTag.setAttribute("href", element.link);
    root.appendChild(anchorTag);
    root = anchorTag;
  }

  switch (element.type) {
    case "selection": {
      // Since this is used only during editing experience, which is canvas based,
      // this should not happen
      throw new Error("Selection rendering is not supported for SVG");
    }
    case "rectangle":
    case "square":
    case "triangle":
    case "pentagon":
    case "hexagon":
    case "octagon":
    case "circle":
    case "perspective":
    case "rosette":
    case "star":
    case "cylinder": 
    case "heart":
    case "tick":
    case "cross":
    case "diamond":
    case "ellipse": {
      generateElementShape(element, generator);
      const node = roughSVGDrawWithPrecision(
        rsvg,
        getShapeForElement(element)!,
        MAX_DECIMALS_FOR_SVG_EXPORT,
      );
      const opacity = element.opacity / 100;
      if (opacity !== 1) {
        node.setAttribute("stroke-opacity", `${opacity}`);
        node.setAttribute("fill-opacity", `${opacity}`);
      }
      node.setAttribute("stroke-linecap", "round");
      node.setAttribute(
        "transform",
        `translate(${offsetX || 0} ${offsetY || 0
        }) rotate(${degree} ${cx} ${cy})`,
      );
      root.appendChild(node);
      break;
    }
    case "line":
    case "arrow":
    case "connectarrow":
      {
        generateElementShape(element, generator);
        const group = svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
        const opacity = element.opacity / 100;
        group.setAttribute("stroke-linecap", "round");

        getShapeForElement(element)!.forEach((shape) => {
          const node = roughSVGDrawWithPrecision(
            rsvg,
            shape,
            MAX_DECIMALS_FOR_SVG_EXPORT,
          );
          if (opacity !== 1) {
            node.setAttribute("stroke-opacity", `${opacity}`);
            node.setAttribute("fill-opacity", `${opacity}`);
          }
          node.setAttribute(
            "transform",
            `translate(${offsetX || 0} ${offsetY || 0
            }) rotate(${degree} ${cx} ${cy})`,
          );
          if (
            element.type === "line" &&
            isPathALoop(element.points) &&
            element.backgroundColor !== "transparent"
          ) {
            node.setAttribute("fill-rule", "evenodd");
          }
          group.appendChild(node);
        });
        root.appendChild(group);
        break;
      }
    case "marker":
    case "magicpen":
    case "magictext":
    case "freedraw": {
      generateElementShape(element, generator);
      generateFreeDrawShape(element);
      const opacity = element.opacity / 100;
      const shape = getShapeForElement(element);
      const node = shape
        ? roughSVGDrawWithPrecision(rsvg, shape, MAX_DECIMALS_FOR_SVG_EXPORT)
        : svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
      if (opacity !== 1) {
        node.setAttribute("stroke-opacity", `${opacity}`);
        node.setAttribute("fill-opacity", `${opacity}`);
      }
      node.setAttribute(
        "transform",
        `translate(${offsetX || 0} ${offsetY || 0
        }) rotate(${degree} ${cx} ${cy})`,
      );
      node.setAttribute("stroke", "none");
      const path = svgRoot.ownerDocument!.createElementNS(SVG_NS, "path");
      path.setAttribute("fill", element.strokeColor);
      path.setAttribute("d", getFreeDrawSvgPath(element));
      node.appendChild(path);
      root.appendChild(node);
      break;
    }
    case "eraserbig": {
      generateElementShape(element, generator);
      generateFreeDrawShape(element);
      const opacity = element.opacity / 100;
      const shape = getShapeForElement(element);
      const node = shape
        ? roughSVGDrawWithPrecision(rsvg, shape, MAX_DECIMALS_FOR_SVG_EXPORT)
        : svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
      if (opacity !== 1) {
        node.setAttribute("stroke-opacity", `${opacity}`);
        node.setAttribute("fill-opacity", `${opacity}`);
      }
      node.setAttribute(
        "transform",
        `translate(${offsetX || 0} ${offsetY || 0
        }) rotate(${degree} ${cx} ${cy})`,
      );
      node.setAttribute("stroke", "none");
      const path = svgRoot.ownerDocument!.createElementNS(SVG_NS, "path");
      path.setAttribute("fill", element.strokeColor);
      path.setAttribute("d", getFreeDrawSvgPath(element));
      node.appendChild(path);
      root.appendChild(node);
      break;
    }
    case "image": {
      const width = Math.round(element.width);
      const height = Math.round(element.height);
      const fileData =
        isInitializedImageElement(element) && files[element.fileId];
      if (fileData) {
        const symbolId = `image-${fileData.id}`;
        let symbol = svgRoot.querySelector(`#${symbolId}`);
        if (!symbol) {
          symbol = svgRoot.ownerDocument!.createElementNS(SVG_NS, "symbol");
          symbol.id = symbolId;

          const image = svgRoot.ownerDocument!.createElementNS(SVG_NS, "image");

          image.setAttribute("width", "100%");
          image.setAttribute("height", "100%");
          image.setAttribute("href", fileData.dataURL);

          symbol.appendChild(image);

          root.prepend(symbol);
        }

        const use = svgRoot.ownerDocument!.createElementNS(SVG_NS, "use");
        use.setAttribute("href", `#${symbolId}`);

        // in dark theme, revert the image color filter
        if (exportWithDarkMode && fileData.mimeType !== MIME_TYPES.svg) {
          use.setAttribute("filter", IMAGE_INVERT_FILTER);
        }

        use.setAttribute("width", `${width}`);
        use.setAttribute("height", `${height}`);

        // We first apply `scale` transforms (horizontal/vertical mirroring)
        // on the <use> element, then apply translation and rotation
        // on the <g> element which wraps the <use>.
        // Doing this separately is a quick hack to to work around compositing
        // the transformations correctly (the transform-origin was not being
        // applied correctly).
        if (element.scale[0] !== 1 || element.scale[1] !== 1) {
          const translateX = element.scale[0] !== 1 ? -width : 0;
          const translateY = element.scale[1] !== 1 ? -height : 0;
          use.setAttribute(
            "transform",
            `scale(${element.scale[0]}, ${element.scale[1]}) translate(${translateX} ${translateY})`,
          );
        }

        const g = svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
        g.appendChild(use);
        g.setAttribute(
          "transform",
          `translate(${offsetX || 0} ${offsetY || 0
          }) rotate(${degree} ${cx} ${cy})`,
        );

        root.appendChild(g);
      }
      break;
    }
    default: {
      if (isTextElement(element)) {
        const opacity = element.opacity / 100;
        const node = svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
        if (opacity !== 1) {
          node.setAttribute("stroke-opacity", `${opacity}`);
          node.setAttribute("fill-opacity", `${opacity}`);
        }
        node.setAttribute(
          "transform",
          `translate(${offsetX || 0} ${offsetY || 0
          }) rotate(${degree} ${cx} ${cy})`,
        );
        const lines = element.text.replace(/\r\n?/g, "\n").split("\n");
        const lineHeight = element.height / lines.length;
        const verticalOffset = element.height - element.baseline;
        const horizontalOffset =
          element.textAlign === "center"
            ? element.width / 2
            : element.textAlign === "right"
              ? element.width
              : 0;
        const direction = isRTL(element.text) ? "rtl" : "ltr";
        const textAnchor =
          element.textAlign === "center"
            ? "middle"
            : element.textAlign === "right" || direction === "rtl"
              ? "end"
              : "start";
        for (let i = 0; i < lines.length; i++) {
          const text = svgRoot.ownerDocument!.createElementNS(SVG_NS, "text");
          text.textContent = lines[i];
          text.setAttribute("x", `${horizontalOffset}`);
          text.setAttribute("y", `${(i + 1) * lineHeight - verticalOffset}`);
          text.setAttribute("font-family", getFontFamilyString(element));
          text.setAttribute("font-size", `${element.fontSize}px`);
          text.setAttribute("fill", element.strokeColor);
          text.setAttribute("text-anchor", textAnchor);
          text.setAttribute("style", "white-space: pre;");
          text.setAttribute("direction", direction);
          node.appendChild(text);
        }
        root.appendChild(node);
      } else {
        // @ts-ignore
        throw new Error(`Unimplemented type ${element.type}`);
      }
    }
  }
};

export const pathsCache = new WeakMap<ImagoFreeDrawElement, Path2D>([]);

export function generateFreeDrawShape(element: ImagoFreeDrawElement) {
  const svgPathData = getFreeDrawSvgPath(element);
  const path = new Path2D(svgPathData);
  pathsCache.set(element, path);
  return path;
}

export function getFreeDrawPath2D(element: ImagoFreeDrawElement) {
  return pathsCache.get(element);
}

export const erasurePathCache = new WeakMap<ImagoElement, Path2D[]>([]);
export function generateErasurePath(element: ImagoElement) {
  if (element.erasurePoints && element.erasurePoints.length > 0) {
    // const paths = element.erasurePoints.map(points=>{
    //   const svgPathData = getSvgPath(points,200);
    //   const path = new Path2D(svgPathData);

    //   return path;
    // })
    const svgPathData = getSvgPath(element.erasurePoints, 200);
    const paths = new Path2D(svgPathData);
    return paths;
  }

}

export function getErasurePath2D(element: ImagoElement) {
  return generateErasurePath(element);
}


function getBrushPath2D(element: ImagoFreeDrawElement) {
  // const path = new Path2D();
  // if (element.points.length > 0) {
  //   for (let i = 0; i < element.points.length; i++) {
  //     path.rect(element.points[i][0] - 45, element.points[i][1] - 68, 90, 136);
  //   }
  // }
  // return path;
  const inputPoints = element.simulatePressure
    ? element.points
    : element.points.length
      ? element.points.map(([x, y], i) => [x, y, element.pressures[i]])
      : [[0, 0, 0.5]];

  // Consider changing the options for simulated pressure vs real pressure
  const options: StrokeOptions = {
    simulatePressure: element.simulatePressure,
    size: 30,
    thinning: 0,
    smoothing: 1,
    streamline: 1,
    easing: (t) => Math.sin((t * Math.PI) / 2), // https://easings.net/#easeOutSine
    last: !!element.lastCommittedPoint, // LastCommittedPoint is added on pointerup
    start: {
      taper: 0,
      easing: (t) => t,
      cap: false,
    },
    end: {
      taper: 0,
      easing: (t) => t,
      cap: false,
    },
  };

  return new Path2D(
    getSvgPathFromStroke(getStroke(inputPoints as number[][], options)),
  );
}

export function getSvgPath(
  points: (readonly [number, number])[],
  size: number = 200,
) {
  const inputPoints = points.map(([x, y], i) => [x, y, false]);

  // Consider changing the options for simulated pressure vs real pressure
  const options: StrokeOptions = {
    simulatePressure: false,
    size,
    thinning: 0.6,
    smoothing: 0.5,
    streamline: 0.5,
    easing: (t) => Math.sin((t * Math.PI) / 2), // https://easings.net/#easeOutSine
  };

  return getSvgPathFromStroke(getStroke(inputPoints as number[][], options));
}
export function getFreeDrawSvgPath(element: ImagoFreeDrawElement) {
  // If input points are empty (should they ever be?) return a dot
  const inputPoints = element.simulatePressure
    ? element.points
    : element.points.length
      ? element.points.map(([x, y], i) => [x, y, element.pressures[i]])
      : [[0, 0, 0.5]];

  // Consider changing the options for simulated pressure vs real pressure
  const options: StrokeOptions = {
    simulatePressure: element.simulatePressure,
    size: element.strokeWidth,
    thinning: 0.5,
    smoothing: 0.5,
    streamline: 0.5,
    easing: (t) => t,//Math.sin((t * Math.PI) / 2), // https://easings.net/#easeOutSine
    last: !!element.lastCommittedPoint, // LastCommittedPoint is added on pointerup
  };


  return getSvgPathFromStroke(getStroke(inputPoints as number[][], options));
}

function med(A: number[], B: number[]) {
  return [(A[0] + B[0]) / 2, (A[1] + B[1]) / 2];
}

// Trim SVG path data so number are each two decimal points. This
// improves SVG exports, and prevents rendering errors on points
// with long decimals.
const TO_FIXED_PRECISION = /(\s?[A-Z]?,?-?[0-9]*\.[0-9]{0,2})(([0-9]|e|-)*)/g;

function getSvgPathFromStroke(points: number[][]): string {
  if (!points.length) {
    return "";
  }

  const max = points.length - 1;

  return points
    .reduce(
      (acc, point, i, arr) => {
        if (i === max) {
          acc.push(point, med(point, arr[0]), "L", arr[0], "Z");
        } else {
          acc.push(point, med(point, arr[i + 1]));
        }
        return acc;
      },
      ["M", points[0], "Q"],
    )
    .join(" ")
    .replace(TO_FIXED_PRECISION, "$1");
}
