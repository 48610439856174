import React, { useState, useEffect } from 'react'
import "./NotesPanelPre.scss";
import RichEditor from './RichEditor'
import { Input } from 'antd';
import { LocalData } from '../imago-app/data/LocalData';
import { AppState } from '../types';
import { Editor, Toolbar } from '@wangeditor-next/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig, i18nChangeLanguage } from '@wangeditor-next/editor'
import { getformatDate } from './NotesPanel';

const { TextArea } = Input;

export const NotesPanelPre: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {
  const [pageNoteTitle, setPageNoteTitle] = useState<string>("");

  useEffect(() => {
    appState.pageNoteWinInfo && setPageNoteTitle(appState.pageNoteWinInfo.title)
  }, []);



  return (
    <div className='editor-container'>
      <div className="editor-container-title">
        <TextArea
          value={pageNoteTitle}
          placeholder="Title"
          onChange={(e) => {
            setPageNoteTitle(e.target.value)
            appState.pageNoteWinInfo && setAppState({
              pageNoteWinInfo: {
                ...appState.pageNoteWinInfo,
                time: getformatDate(),
                title: e.target.value,
              },
            });
          }}
          autoSize />

        <div className='time'>{appState.pageNoteWinInfo?.time}</div>

      </div>
      <div className='editor-container-content'>
        <RichEditor appState={appState} setAppState={setAppState} />
      </div>

    </div>
  )
}
