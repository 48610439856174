import usePortal from "react-useportal";
import "./DraggablePanelPageNote.scss";
import React, {
  Suspense,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import "./Resizable";
import Resizable, { Direction } from "./Resizable";
import { throttleRAF } from "../utils";
import { AppState } from "../types";
import {
  RefreshIcon,
  ResizeMaxIcon,
  ResizeFloatingIcon,
  ResizeNormalIcon,
  MinimizeWhiteIcon,
  IconCloseWhite,
  MinimizeIcon,
  DocMinIcon,
} from "./icons";
import clsx from "clsx";

function useForceUpdate() {
  const [value, setState] = useState(true);
  return () => setState(!value);
}
const DRAGABLE_SIZE_STATE = {
  minisize: 3,
  maxsize: 2,
  default: 1,
  floating: 0,
};

export const DraggablePanelPageNoteWin = ({
  id,
  title,
  children,
  height = 360,
  width = 640,
  appState,
  zIndex,
  currentState: defalutState = "default",
  setAppState,
  buttons,
  onMinisize,
  onClose,
  onMove,
  onUp,
  onFocus,
  position = [0, 0],
  resizeable = true,
  useActiveTools = true,
  radius,
  renderFlag
}: {
  id: "screenSharing" | "flashCollabration" | string;
  title: string;
  children: React.ReactNode;
  height?: number;
  width?: number;
  appState: AppState;
  zIndex?: number;
  setAppState: React.Component<any, AppState>["setState"];
  buttons: ("refresh" | "close" | "minisize" | "maxsize" | "floating")[];
  onMinisize?: (position: [number, number]) => void;
  onClose?: () => void;
  onMove?: (position: [number, number]) => void;
  onUp?: (position: [number, number]) => void;
  onFocus?: () => void;
  currentState?: keyof typeof DRAGABLE_SIZE_STATE;
  position?: [number, number];
  resizeable?: boolean;
  useActiveTools?: boolean;
  radius?: number;
  renderFlag?: string;
}) => {
  const { Portal } = usePortal({
    bindTo: document.getElementById("panel-portal")!,
  });
  let [x, y] = position;
  if (x == 0) {
    x = (window.innerWidth - width) / 2
  }
  if (y == 0) {
    y = (window.innerHeight - height) / 2
  }



  const panelRef = useRef<HTMLDivElement>(null);
  const [pointerDown, setPointerDown] = useState(false);
  const [offsetX, setOffsetX] = useState(x);
  const [offsetY, setOffsetY] = useState(y);
  const isHovered = appState.activeDragableIframe?.id == id && appState.activeDragableIframe?.state == "hover";
  const isMoving = appState.activeDragableIframe?.id == id && appState.activeDragableIframe?.state == "move";
  const isActive = appState.activeDragableIframe?.id == id && appState.activeDragableIframe?.state == "active";
  const actZindex = appState.activeDragableIframe?.id == id ? 2 : zIndex;
  const minWidth = 300;
  const minHeight = 200;

  const [panelState, setPanelState] = useState(
    DRAGABLE_SIZE_STATE[defalutState],
  );
  const [c, setC] = useState(children);


  const forceUpdate = useForceUpdate();
  const handleResize = (direction: string, moveX: number, moveY: number) => {
    const panel = panelRef.current;
    if (!panel) {
      return;
    }
    let movementY = moveY;
    let movementX = moveX;
    const { width, height, x, y } = panel.getBoundingClientRect();
    const newWidth = width - moveX;
    const newHeight = height - moveY;

    const resizeTop = () => {
      if (minHeight >= newHeight && moveY > 0) {
        movementY = 0;
      }
      panel.style.height = `${height - movementY}px`;
      panel.style.top = `${y + movementY}px`;
      panel.style.left = `${x}px`;
    };

    const resizeTopLeft = () => {
      if (minHeight >= newHeight && moveY > 0) {
        movementY = 0;
      }
      if (minWidth >= newWidth && moveX > 0) {
        movementX = 0;
      }
      panel.style.width = `${width - movementX}px`;
      panel.style.height = `${height - movementY}px`;
      panel.style.top = `${y + movementY}px`;
      panel.style.left = `${x + movementX}px`;
    };

    const resizeRight = () => {
      if (minWidth >= newWidth && moveX < 0) {
        movementX = 0;
      }
      panel.style.width = `${width + movementX}px`;
      panel.style.left = `${x}px`;
      panel.style.top = `${y}px`;
    };

    const resizeBottom = () => {
      if (minHeight >= newHeight && moveY < 0) {
        movementY = 0;
      }
      panel.style.height = `${height + movementY}px`;
      panel.style.left = `${x}px`;
      panel.style.top = `${y}px`;
    };

    const resizeLeft = () => {
      if (minWidth >= newWidth && moveX > 0) {
        movementX = 0;
      }
      panel.style.width = `${width - movementX}px`;
      panel.style.left = `${x + movementX}px`;
      panel.style.top = `${y}px`;
    };

    switch (direction) {
      case Direction.TopLeft:
        resizeTopLeft();
        break;

      case Direction.Top:
        resizeTop();
        break;

      case Direction.TopRight:
        resizeRight();
        resizeTop();
        break;

      case Direction.Right:
        resizeRight();
        break;

      case Direction.BottomRight:
        resizeBottom();
        resizeRight();
        break;

      case Direction.Bottom:
        resizeBottom();
        break;

      case Direction.BottomLeft:
        resizeBottom();
        resizeLeft();
        break;

      case Direction.Left:
        resizeLeft();
        break;

      default:
        break;
    }
    panel.className = "draggable-panel-move";
  };

  useEffect(() => {
    setPanelState(DRAGABLE_SIZE_STATE[defalutState]);
  }, [defalutState]);

  useEffect(() => {
    setC(children)
  }, [renderFlag]);



  const checkButton = (name: any) => {
    return buttons.indexOf(name) > -1;
  };

  useEffect(() => {
    const handlePointerUp = (e: any) => {
      const { movementX, movementY } = e;
      setOffsetX(offsetX + movementX);
      setOffsetY(offsetY + movementY);
      onUp && onUp([offsetX, offsetY])
      setPointerDown(false)
    };
    window.addEventListener("pointerup", handlePointerUp);
    return () => {
      window.removeEventListener("pointerup", handlePointerUp);
    };
  });



  useEffect(() => {
    const handlePointerMove = (e: any) => {
      const { movementX, movementY } = e;
      if (movementX == 0 && movementY == 0) {

      } else {
        setAppState({ activeDragableIframe: { id, state: "move" } });
        setOffsetX(offsetX + movementX);
        setOffsetY(offsetY + movementY);
        onMove && onMove([offsetX, offsetY])
        e.stopPropagation();
        e.preventDefault();
      }
    };



    if (pointerDown) {
      window.addEventListener("pointermove", handlePointerMove);
    }

    return () => {
      window.removeEventListener("pointermove", handlePointerMove);

    };
  }, [pointerDown, offsetX, offsetY]);

  const handlePointerDown = (event: any) => {
    //   setOffsetX(event.pageX);
    //   setOffsetY(event.pageY);
    setPointerDown(true);
    event.stopPropagation();
    event.preventDefault();
  };

  function handleClose() {
    onClose && onClose();
  }
  const handleMinimize = () => {
    setPanelState(DRAGABLE_SIZE_STATE.minisize);
    onMinisize && onMinisize([offsetX, offsetY]);
  };
  const handleFloating = () => {
    if (panelState == DRAGABLE_SIZE_STATE.floating) {
      setPanelState(DRAGABLE_SIZE_STATE.default);
    } else {
      setPanelState(DRAGABLE_SIZE_STATE.floating);
    }
  };
  const handleMaximize = () => {
    if (panelState == DRAGABLE_SIZE_STATE.maxsize) {
      setPanelState(DRAGABLE_SIZE_STATE.default);
    } else {
      setPanelState(DRAGABLE_SIZE_STATE.maxsize);
    }
  };

  const getDiffSeconds = (lastHoverTime: number): number => {
    const nowTime = new Date().getTime();
    const diffMilliseconds = Math.abs(nowTime - lastHoverTime);
    return diffMilliseconds;
  }



  useEffect(() => {
    const panel = panelRef.current;
    if (!panel) {
      return;
    }
    const resizeMax = () => {
      panel.style.width = `${window.innerWidth}px`;
      panel.style.height = `${window.innerHeight}px`;
      panel.style.top = "0px";
      panel.style.left = "0px";
      panel.className = "draggable-panel-resize";
    };

    switch (panelState) {
      case 0:
        panel.style.width = "320px";
        panel.style.height = "180px";
        panel.style.top = "10px";
        panel.style.left = "10px";
        panel.className = "draggable-panel-resize";
        break;
      case 1:
        setOffsetX(x);
        setOffsetY(y);
        panel.style.width = `${width}px`;
        panel.style.height = `auto`;
        panel.className = "draggable-panel-move";
        break;
      case 2:
        resizeMax();
        break;
      case 3:
        panel.className = "draggable-panel-hide";
        break;
    }
    if (panelState == 2) {
      window.addEventListener("resize", resizeMax);
    }

    return () => {
      window.removeEventListener("resize", resizeMax);
    };
  }, [panelState]);


  return (
    <Portal>
      <div className="draggable-panel-page-note-win">
        <div
          className={clsx("draggable-panel-move", {
            "draggable-panel-hide": defalutState == "minisize",
            "panel__container-hover": isHovered || isMoving,
          })}
          style={{ height: "auto", width, zIndex: actZindex, left: offsetX, top: offsetY }}
          ref={panelRef}
        >

          <div style={{ border: "1px solid #ccc" }}>
            <div style={{ display: "flex", justifyContent: "end", paddingRight: "8px", paddingTop: "3px" }}>
              <div onClick={() => {
                appState.pageNoteWinInfo && setAppState({
                  pageNoteWinInfo: {
                    ...appState.pageNoteWinInfo,
                    closed: true
                  },
                });
              }} style={{ cursor: "pointer" }}>
                {DocMinIcon}
              </div>
            </div>




            {isActive ? (
              panelState == 2 && useActiveTools && (
                <></>
              )
            ) : (useActiveTools &&
              <div

                className="panel__inner"
                onPointerDown={(e) => {
                  if (!isHovered) {
                    setAppState({ activeDragableIframe: { id, state: "hover", lastHoverTime: new Date().getTime() }, selectedElementIds: {} });
                    onFocus && onFocus();
                    handlePointerDown(e);
                  } else {
                    if (appState.activeDragableIframe?.lastHoverTime && appState.activeDragableIframe?.lastHoverTime != 0) {
                      const diffMilliseconds = getDiffSeconds(appState.activeDragableIframe?.lastHoverTime)
                      if (diffMilliseconds > 500) {
                        setAppState({ activeDragableIframe: { id, state: "hover", lastHoverTime: new Date().getTime() }, selectedElementIds: {} });
                        onFocus && onFocus();
                        handlePointerDown(e);
                      } else {
                        setAppState({ activeDragableIframe: { id, state: "active", lastHoverTime: 0 }, selectedElementIds: {} });
                        e.stopPropagation();
                      }

                    } else {
                      setAppState({ activeDragableIframe: { id, state: "active", lastHoverTime: 0 }, selectedElementIds: {} });
                      e.stopPropagation();
                    }

                  }
                }}
              >
                {/* {isHovered && (
              <div className="panel__inactive">
                <a
                  href={undefined}
                  className="interactive-button"
                  title="Please click to interact"
                  onPointerDown={(e) => {
                    setAppState({ activeDragableIframe: { id, state: "active" } });
                    e.stopPropagation();
                  }}
                >
                  Click here to interact
                </a>
                {renderSizeTool()}
              </div>
            )} */}
              </div>
            )}

            <div
              className={clsx("panel__container_page_note")}
              style={{ height: "auto", minHeight: "440px", maxHeight: "600px" }} onPointerDown={(e) => {
                if (!useActiveTools) {
                  setAppState({ activeDragableIframe: { id, state: "hover" } });
                  onFocus && onFocus();
                  handlePointerDown(e);
                }

                e.stopPropagation();
              }}>
              {resizeable && <Resizable onResize={handleResize} />}
              <div className="panel__content">
                {" "}
                <Suspense fallback="loading...">{c}</Suspense>
              </div>
            </div>

          </div>


        </div>
      </div>
    </Portal>
  );
};
