import { AppState } from "../../src/types";
import { ColorPicker, BottomColorPicker, ElementBackgroundPicker } from "../components/ColorPicker";
import { IconPicker, ArrowheadPicker } from "../components/IconPicker";
import React from "react";
import { Popover } from "../components/Popover";
import clsx from "clsx";
import { ToolButton } from "../components/ToolButton";
// TODO barnabasmolnar/editor-redesign
// TextAlignTopIcon, TextAlignBottomIcon,TextAlignMiddleIcon,
// ArrowHead icons
import {
  ArrowheadArrowIcon,
  ArrowheadBarIcon,
  ArrowheadDotIcon,
  ArrowheadTriangleIcon,
  ArrowheadNoneIcon,
  StrokeStyleDashedIcon,
  StrokeStyleDottedIcon,
  TextAlignTopIcon,
  TextAlignBottomIcon,
  TextAlignMiddleIcon,
  FillHachureIcon,
  FillCrossHatchIcon,
  FillSolidIcon,
  SloppinessArchitectIcon,
  SloppinessArtistIcon,
  SloppinessCartoonistIcon,
  StrokeWidthBaseIcon,
  StrokeWidthBoldIcon,
  StrokeWidthExtraBoldIcon,
  FontSizeSmallIcon,
  FontSizeMediumIcon,
  FontSizeLargeIcon,
  FontSizeExtraLargeIcon,
  EdgeSharpIcon,
  EdgeRoundIcon,
  FontFamilyNormalIcon,
  FontFamilyCodeIcon,
  TextAlignLeftIcon,
  TextAlignCenterIcon,
  TextAlignRightIcon,
  createStringIcon,
  FontSizeIcon,
  OpacityIcon,
  StrokeColorIcon,
  ArrowheadDotLeftIcon,
  ArrowheadDotRightIcon,
  ArrowheadArrowLeftIcon,
  ArrowheadArrowRightIcon,
  DrawStrokeColorIcon,
  StrokeIcon,
  PenIcon,
  TranslateIcon,
  RequestAIIcon,
  RequestAIColorIcon,
  IconInsertToDoc
} from "../components/icons";
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
  FONT_FAMILY,
  VERTICAL_ALIGN,
} from "../constants";
import {
  getNonDeletedElements,
  isTextElement,
  redrawTextBoundingBox,
} from "../element";
import { mutateElement, newElementWith } from "../element/mutateElement";
import {
  getBoundTextElement,
  getContainerElement,
} from "../element/textElement";
import {
  isBoundToContainer,
  isImageElement,
  isLinearElement,
  isLinearElementType,
} from "../element/typeChecks";
import {
  Arrowhead,
  ImagoElement,
  ImagoLinearElement,
  ImagoTextElement,
  FontFamilyValues,
  TextAlign,
  VerticalAlign,
} from "../element/types";
import { getLanguage, t } from "../i18n";
import { KEYS } from "../keys";
import { randomInteger } from "../random";
import {
  canChangeSharpness,
  canHaveArrowheads,
  getCommonAttributeOfSelectedElements,
  getSelectedElements,
  getTargetElements,
  isSomeElementSelected,
} from "../scene";
import { hasStrokeColor } from "../scene/comparisons";
import { arrayToMap } from "../utils";
import { register } from "./register";
import { ButtonIconSelect } from "../components/ButtonIconSelect";
import { Island } from "../components/Island";
import { max } from "lodash";
import { RangePicker } from "../components/RangePicker";
import { TranslateAI } from "../components/TranslateAI";
import { getformatDate } from "../components/NotesPanel";


const FONT_SIZE_RELATIVE_INCREASE_STEP = 0.1;

const changeProperty = (
  elements: readonly ImagoElement[],
  appState: AppState,
  callback: (element: ImagoElement) => ImagoElement,
  includeBoundText = false,
) => {
  const selectedElementIds = arrayToMap(
    getSelectedElements(elements, appState, includeBoundText),
  );
  return elements.map((element) => {
    if (
      selectedElementIds.get(element.id) ||
      element.id === appState.editingElement?.id
    ) {
      return callback(element);
    }
    return element;
  });
};

const getFormValue = function <T>(
  elements: readonly ImagoElement[],
  appState: AppState,
  getAttribute: (element: ImagoElement) => T,
  defaultValue?: T,
): T | null {
  const editingElement = appState.editingElement;
  const nonDeletedElements = getNonDeletedElements(elements);

  const value = (
    (editingElement && getAttribute(editingElement)) ??
    (isSomeElementSelected(nonDeletedElements, appState)
      ? getCommonAttributeOfSelectedElements(
        nonDeletedElements,
        appState,
        getAttribute,
      )
      : defaultValue) ??
    null
  );
  return value;
};

const offsetElementAfterFontResize = (
  prevElement: ImagoTextElement,
  nextElement: ImagoTextElement,
) => {
  if (isBoundToContainer(nextElement)) {
    return nextElement;
  }
  return mutateElement(
    nextElement,
    {
      x:
        prevElement.textAlign === "left"
          ? prevElement.x
          : prevElement.x +
          (prevElement.width - nextElement.width) /
          (prevElement.textAlign === "center" ? 2 : 1),
      // it makes sense
      y: prevElement.y + (prevElement.height - nextElement.height) / 2,
    },
    false,
  );
};

const changeFontSize = (
  elements: readonly ImagoElement[],
  appState: AppState,
  getNewFontSize: (element: ImagoTextElement) => number,
  fallbackValue?: ImagoTextElement["fontSize"],
) => {
  const newFontSizes = new Set<number>();

  return {
    elements: changeProperty(
      elements,
      appState,
      (oldElement) => {
        if (isTextElement(oldElement)) {
          const newFontSize = getNewFontSize(oldElement);
          newFontSizes.add(newFontSize);

          let newElement: ImagoTextElement = newElementWith(oldElement, {
            fontSize: newFontSize,
          });
          redrawTextBoundingBox(newElement, getContainerElement(oldElement));

          newElement = offsetElementAfterFontResize(oldElement, newElement);

          return newElement;
        }

        return oldElement;
      },
      true,
    ),
    appState: {
      ...appState
    },
    commitToHistory: true,
  };
};

// -----------------------------------------------------------------------------

export const actionChangeStrokeColor = register({
  name: "changeStrokeColor",
  trackEvent: false,
  perform: (elements, appState, value) => {
    const result = {
      ...(value.currentItemStrokeColor && {
        elements: changeProperty(
          elements,
          appState,
          (el) => {
            return hasStrokeColor(el.type)
              ? newElementWith(el, {
                strokeColor: value.currentItemStrokeColor,
              })
              : el;
          },
          true,
        ),
      }),
      appState: {
        ...appState,
        ...(value.openPopup && { openPopup: value.openPopup }),

      },
      commitToHistory: !!value.currentItemStrokeColor,
    };
    return result;
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <>
      <ColorPicker
        type="elementStroke"
        label={t("labels.stroke")}
        color={getFormValue(
          elements,
          appState,
          (element) => element.strokeColor,
          appState.currentItemStrokeColor,
        )}
        onChange={(color) => updateData({ currentItemStrokeColor: color })}
        isActive={appState.openPopup === "strokeColorPicker"}
        setActive={(active) =>
          updateData({ openPopup: active ? "strokeColorPicker" : null })
        }
        elements={elements}
        appState={appState}
      />
    </>
  ),
});

export const actionBottomChangeStrokeColor = register({
  name: "bottomChangeStrokeColor",
  trackEvent: false,
  perform: (elements, appState, value) => {

    const formValue = {
      ...(value.currentItemStrokeColor && {
        elements: changeProperty(
          elements,
          appState,
          (el) => {
            return hasStrokeColor(el.type)
              ? newElementWith(el, {
                strokeColor: value.currentItemStrokeColor,
              })
              : el;
          },
          true,
        ),
      }),
      appState: {
        ...appState,
        ...value,
      },
      commitToHistory: !!value.currentItemStrokeColor,
    };
    return formValue;
  },
  PanelComponent: ({ elements, appState, updateData, data }) => (
    <>
      <BottomColorPicker
        type="elementStroke"
        label={t("labels.stroke")}
        colors={data && data["colors"]}
        color={
          appState.currentItemStrokeColor
        }
        icon={StrokeColorIcon}//{StrokeColorIcon(appState.currentItemStrokeColor)}
        onChange={(color) => updateData({ currentItemStrokeColor: color })}
        isActive={appState.openPopup === "bottomStrokeColorPicker"}
        setActive={(active) =>
          updateData({ openPopup: active ? "bottomStrokeColorPicker" : null })
        }
        appState={appState}
        setAppState={data && data["setAppState"]}

      />
    </>
  ),
});



export const actionChangeBackgroundColor = register({
  name: "changeBackgroundColor",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      ...(value.currentItemBackgroundColor && {
        elements: changeProperty(elements, appState, (el) =>
          newElementWith(el, {
            backgroundColor: value.currentItemBackgroundColor,
          }),
        ),
      }),
      appState: {
        ...appState,
        ...(value.openPopup && { openPopup: value.openPopup }),
      },
      commitToHistory: !!value.currentItemBackgroundColor,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <>
      <ElementBackgroundPicker
        label={t("labels.background")}
        color={getFormValue(
          elements,
          appState,
          (element) => element.backgroundColor,
          appState.currentItemBackgroundColor,
        )}
        onChange={(color) => updateData({ currentItemBackgroundColor: color })}
        elements={elements}
        appState={appState}
      />
    </>
  ),
});

export const actionChangeFillStyle = register({
  name: "changeFillStyle",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          fillStyle: value,
        }),
      ),
      appState: { ...appState },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      <legend>{t("labels.fill")}</legend>
      <ButtonIconSelect
        options={[
          {
            value: "hachure",
            text: t("labels.hachure"),
            icon: FillHachureIcon,
          },
          {
            value: "cross-hatch",
            text: t("labels.crossHatch"),
            icon: FillCrossHatchIcon,
          },
          {
            value: "solid",
            text: t("labels.solid"),
            icon: FillSolidIcon,
          },
        ]}
        group="fill"
        value={getFormValue(
          elements,
          appState,
          (element) => element.fillStyle,
          appState.currentItemFillStyle,
        )}
        onChange={(value) => {
          updateData(value);
        }}
      />
    </fieldset>
  ),
});

export const actionChangeStrokeWidth = register({
  name: "changeStrokeWidth",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          strokeWidth: value,
        }),
      ),
      appState: { ...appState },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      <legend>{t("labels.strokeWidth")}</legend>
      <ButtonIconSelect
        group="stroke-width"
        options={[
          {
            value: 2,
            text: t("labels.thin"),
            icon: StrokeWidthBaseIcon,
          },
          {
            value: 4,
            text: t("labels.bold"),
            icon: StrokeWidthBoldIcon,
          },
          {
            value: 8,
            text: t("labels.extraBold"),
            icon: StrokeWidthExtraBoldIcon,
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => element.strokeWidth,
          appState.currentItemStrokeWidth,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});
export const actionBottomChangeStrokeWidth = register({
  name: "bottomChangeStrokeWidth",
  trackEvent: false,
  perform: (elements, appState, value) => {
    const newelements = value.currentItemStrokeStyle ? changeProperty(
      elements,
      appState,
      (el) => {
        return value.currentItemStrokeStyle ? newElementWith(el, {
          strokeStyle: value.currentItemStrokeStyle
        }) : el;
      },
      true,
    ) : value.currentItemStrokeWidth ? changeProperty(
      elements,
      appState,
      (el) => {
        return value.currentItemStrokeStyle ? newElementWith(el, {
          strokeStyle: value.currentItemStrokeStyle
        }) : hasStrokeColor(el.type)
          ? newElementWith(el, {
            strokeWidth: value.currentItemStrokeWidth,
          })
          : el;
      },
      true,
    ) : elements;
    return {
      elements: newelements,
      appState: {
        ...appState,
        ...value,
      },
      commitToHistory: !!value.currentItemStrokeWidth,
    };
  },
  PanelComponent: ({ elements, appState, updateData, data }) => {
    const pickerButton = React.useRef<HTMLButtonElement>(null);
    const coords = pickerButton.current?.getBoundingClientRect();
    const isActive = appState.openPopup === "strokeWidthPicker";
    const setActive = (active: boolean) => {
      updateData({ openPopup: active ? "strokeWidthPicker" : null })

    }
    return (
      <div className="toolbar-button-contanier">
        <ToolButton
          type="icon"
          icon={StrokeIcon}
          aria-label={t("labels.strokeWidth")}
          onClick={() => setActive(!isActive)}
          size={"medium"}
          ref={pickerButton}
          title={t("labels.strokeWidth")}
          id={"strokeWidthPicker"}
        />
        <React.Suspense fallback="">
          {isActive ? (

            <Popover
              onCloseRequest={(event) => {
                return event.target !== pickerButton.current && setActive(false)
              }

              }
              bottom={"2.7rem"}
            >
              <Island className="stroke-width-pop">

                <input
                  type="range"
                  min="2"
                  max="16"
                  step="1"
                  onChange={(event) => updateData({ currentItemStrokeWidth: +event.target.value })}
                  style={{}}
                  value={appState.currentItemStrokeWidth}
                />

                <div className="panelColumn">
                  <ButtonIconSelect
                    group="strokeStyle"
                    options={[
                      {
                        value: "dotted",
                        text: t("labels.strokeStyle_dotted"),
                        icon: StrokeStyleDottedIcon,
                      },
                      {
                        value: "dashed",
                        text: t("labels.strokeStyle_dashed"),
                        icon: StrokeStyleDashedIcon,
                      },
                      {
                        value: "solid",
                        text: t("labels.strokeStyle_solid"),
                        icon: StrokeWidthBaseIcon,
                      },
                    ]}
                    value={getFormValue(
                      elements,
                      appState,
                      (element) => element.strokeStyle,
                      appState.currentItemStrokeStyle,
                    )}
                    onChange={(value) => updateData({ currentItemStrokeStyle: value })}
                  />
                </div>
              </Island>
            </Popover>

          ) : null}
        </React.Suspense>


      </div>
    )
  }
});
export const actionChangeSloppiness = register({
  name: "changeSloppiness",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          seed: randomInteger(),
          roughness: value,
        }),
      ),
      appState: { ...appState },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      <legend>{t("labels.sloppiness")}</legend>
      <ButtonIconSelect
        group="sloppiness"
        options={[
          {
            value: 0,
            text: t("labels.architect"),
            icon: SloppinessArchitectIcon,
          },
          {
            value: 1,
            text: t("labels.artist"),
            icon: SloppinessArtistIcon,
          },
          {
            value: 2,
            text: t("labels.cartoonist"),
            icon: SloppinessCartoonistIcon,
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => element.roughness,
          appState.currentItemRoughness,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeStrokeStyle = register({
  name: "changeStrokeStyle",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          strokeStyle: value,
        }),
      ),
      appState: { ...appState },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      <legend>{t("labels.strokeStyle")}</legend>
      <ButtonIconSelect
        group="strokeStyle"
        options={[
          {
            value: "solid",
            text: t("labels.strokeStyle_solid"),
            icon: StrokeWidthBaseIcon,
          },
          {
            value: "dashed",
            text: t("labels.strokeStyle_dashed"),
            icon: StrokeStyleDashedIcon,
          },
          {
            value: "dotted",
            text: t("labels.strokeStyle_dotted"),
            icon: StrokeStyleDottedIcon,
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => element.strokeStyle,
          appState.currentItemStrokeStyle,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeOpacity = register({
  name: "changeOpacity",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (el) =>
          newElementWith(el, {
            opacity: value,
          }),
        true,
      ),
      appState: { ...appState },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (

    <div className="iconSelectList buttonList">
      <RangePicker
        name="change-opacity"
        label={t("labels.opacity")}
        onChange={(value) => { updateData(value) }}
        min={0}
        max={100}
        step={10}
        icon={OpacityIcon}
        value={getFormValue(
          elements,
          appState,
          (element) => element.opacity,
          appState.currentItemOpacity,
        ) ?? appState.currentItemOpacity}

      />
    </div>

  ),
});

export const actionChangeFontSize = register({
  name: "changeFontSize",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return changeFontSize(elements, appState, () => value, value);
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    return <div className="iconSelectList buttonList">
      <RangePicker
        name="change-font-size"
        label={t("labels.fontSize")}
        onChange={(value) => { updateData(value) }}
        min={16}
        max={50}
        step={1}
        icon={FontSizeIcon}
        value={getFormValue(
          elements,
          appState,
          (element) => {
            if (isTextElement(element)) {
              return element.fontSize;
            }
            const boundTextElement = getBoundTextElement(element);
            if (boundTextElement) {
              return boundTextElement.fontSize;
            }
            return null;
          },
          appState.currentItemFontSize || DEFAULT_FONT_SIZE)}

      />
    </div>
  }
});

export const actionDecreaseFontSize = register({
  name: "decreaseFontSize",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return changeFontSize(elements, appState, (element) =>
      Math.round(
        // get previous value before relative increase (doesn't work fully
        // due to rounding and float precision issues)
        (1 / (1 + FONT_SIZE_RELATIVE_INCREASE_STEP)) * element.fontSize,
      ),
    );
  },
  keyTest: (event) => {
    return (
      event[KEYS.CTRL_OR_CMD] &&
      event.shiftKey &&
      // KEYS.COMMA needed for MacOS
      (event.key === KEYS.CHEVRON_LEFT || event.key === KEYS.COMMA)
    );
  },
});

export const actionIncreaseFontSize = register({
  name: "increaseFontSize",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return changeFontSize(elements, appState, (element) =>
      Math.round(element.fontSize * (1 + FONT_SIZE_RELATIVE_INCREASE_STEP)),
    );
  },
  keyTest: (event) => {
    return (
      event[KEYS.CTRL_OR_CMD] &&
      event.shiftKey &&
      // KEYS.PERIOD needed for MacOS
      (event.key === KEYS.CHEVRON_RIGHT || event.key === KEYS.PERIOD)
    );
  },
});

export const actionChangeFontFamily = register({
  name: "changeFontFamily",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (oldElement) => {
          if (isTextElement(oldElement)) {
            const newElement: ImagoTextElement = newElementWith(
              oldElement,
              {
                fontFamily: value,
              },
            );
            redrawTextBoundingBox(newElement, getContainerElement(oldElement));
            return newElement;
          }

          return oldElement;
        },
        true,
      ),
      appState: {
        ...appState
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    const options: {
      value: FontFamilyValues;
      text: string;
      icon: JSX.Element;
    }[] = [
        {
          value: FONT_FAMILY.Virgil,
          text: t("labels.handDrawn"),
          icon: PenIcon,
        },
        {
          value: FONT_FAMILY.Helvetica,
          text: t("labels.normal"),
          icon: FontFamilyNormalIcon,
        },
        {
          value: FONT_FAMILY.Cascadia,
          text: t("labels.code"),
          icon: FontFamilyCodeIcon,
        },
      ];

    return (
      <fieldset>
        <legend>{t("labels.fontFamily")}</legend>
        <ButtonIconSelect<FontFamilyValues | false>
          group="font-family"
          options={options}
          value={getFormValue(
            elements,
            appState,
            (element) => {
              if (isTextElement(element)) {
                return element.fontFamily;
              }
              const boundTextElement = getBoundTextElement(element);
              if (boundTextElement) {
                return boundTextElement.fontFamily;
              }
              return null;
            },
            appState.currentItemFontFamily || DEFAULT_FONT_FAMILY,
          )}
          onChange={(value) => updateData(value)}
        />
      </fieldset>
    );
  },
});

export const actionChangeTextAlign = register({
  name: "changeTextAlign",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (oldElement) => {
          if (isTextElement(oldElement)) {
            const newElement: ImagoTextElement = newElementWith(
              oldElement,
              { textAlign: value },
            );
            redrawTextBoundingBox(newElement, getContainerElement(oldElement));
            return newElement;
          }

          return oldElement;
        },
        true,
      ),
      appState: {
        ...appState
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    return (
      <fieldset>
        <legend>{t("labels.textAlign")}</legend>
        <ButtonIconSelect<TextAlign | false>
          group="text-align"
          options={[
            {
              value: "left",
              text: t("labels.left"),
              icon: TextAlignLeftIcon,
            },
            {
              value: "center",
              text: t("labels.center"),
              icon: TextAlignCenterIcon,
            },
            {
              value: "right",
              text: t("labels.right"),
              icon: TextAlignRightIcon,
            },
          ]}
          value={getFormValue(
            elements,
            appState,
            (element) => {
              if (isTextElement(element)) {
                return element.textAlign;
              }
              const boundTextElement = getBoundTextElement(element);
              if (boundTextElement) {
                return boundTextElement.textAlign;
              }
              return null;
            },
            appState.currentItemTextAlign,
          )}
          onChange={(value) => updateData(value)}
        />
      </fieldset>
    );
  },
});
export const actionChangeVerticalAlign = register({
  name: "changeVerticalAlign",
  trackEvent: { category: "element" },
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (oldElement) => {
          if (isTextElement(oldElement)) {
            const newElement: ImagoTextElement = newElementWith(
              oldElement,
              { verticalAlign: value },
            );

            redrawTextBoundingBox(newElement, getContainerElement(oldElement));
            return newElement;
          }

          return oldElement;
        },
        true,
      ),
      appState: {
        ...appState,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    return (
      <fieldset>
        <ButtonIconSelect<VerticalAlign | false>
          group="text-align"
          options={[
            {
              value: VERTICAL_ALIGN.TOP,
              text: t("labels.alignTop"),
              icon: <TextAlignTopIcon theme={appState.theme} />,
            },
            {
              value: VERTICAL_ALIGN.MIDDLE,
              text: t("labels.centerVertically"),
              icon: <TextAlignMiddleIcon theme={appState.theme} />,
            },
            {
              value: VERTICAL_ALIGN.BOTTOM,
              text: t("labels.alignBottom"),
              icon: <TextAlignBottomIcon theme={appState.theme} />,
            },
          ]}
          value={getFormValue(elements, appState, (element) => {
            if (isTextElement(element) && element.containerId) {
              return element.verticalAlign;
            }
            const boundTextElement = getBoundTextElement(element);
            if (boundTextElement) {
              return boundTextElement.verticalAlign;
            }
            return null;
          })}
          onChange={(value) => updateData(value)}
        />
      </fieldset>
    );
  },
});

export const actionChangeSharpness = register({
  name: "changeSharpness",
  trackEvent: false,
  perform: (elements, appState, value) => {
    const targetElements = getTargetElements(
      getNonDeletedElements(elements),
      appState,
    );
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          strokeSharpness: value,
        }),
      ),
      appState: {
        ...appState
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      <legend>{t("labels.edges")}</legend>
      <ButtonIconSelect
        group="edges"
        options={[
          {
            value: "sharp",
            text: t("labels.sharp"),
            icon: EdgeSharpIcon,
          },
          {
            value: "round",
            text: t("labels.round"),
            icon: EdgeRoundIcon,
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => element.strokeSharpness,
          (canChangeSharpness(appState.activeTool.type) &&
            (isLinearElementType(appState.activeTool.type)
              ? appState.currentItemLinearStrokeSharpness
              : appState.currentItemStrokeSharpness)) ||
          null,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeArrowhead = register({
  name: "changeArrowhead",
  trackEvent: false,
  perform: (
    elements,
    appState,
    value: { position: "start" | "end"; type: Arrowhead },
  ) => {
    return {
      elements: changeProperty(elements, appState, (el) => {
        if (isLinearElement(el)) {
          const { position, type } = value;

          if (position === "start") {
            const element: ImagoLinearElement = newElementWith(el, {
              startArrowhead: type,
            });
            return element;
          } else if (position === "end") {
            const element: ImagoLinearElement = newElementWith(el, {
              endArrowhead: type,
            });
            return element;
          }
        }

        return el;
      }),
      appState: {
        ...appState
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    const isRTL = getLanguage().rtl;

    return (
      <div className="iconSelectList buttonList">
        <ArrowheadPicker
          label="arrowhead_start"
          options={[
            {
              value: "start_",
              text: t("labels.arrowhead_none"),
              icon: ArrowheadNoneIcon,
              keyBinding: "q",
            },
            {
              value: "end_",
              text: t("labels.arrowhead_none"),
              keyBinding: "q",
              icon: ArrowheadNoneIcon,
            },
            {
              value: "start_dot",
              text: t("labels.arrowhead_dot"),
              icon: ArrowheadDotLeftIcon,
              keyBinding: "r",
            },
            {
              value: "end_dot",
              text: t("labels.arrowhead_dot"),
              keyBinding: "r",
              icon: ArrowheadDotRightIcon,
            },
            {
              value: "start_arrow",
              text: t("labels.arrowhead_arrow"),
              icon: ArrowheadArrowLeftIcon,
              keyBinding: "w",
            },
            {
              value: "end_arrow",
              text: t("labels.arrowhead_arrow"),
              keyBinding: "w",
              icon: ArrowheadArrowRightIcon,
            },
            // {
            //   value: "start_bar",
            //   text: t("labels.arrowhead_bar"),
            //   icon: <ArrowheadBarIcon flip={!isRTL} />,
            //   keyBinding: "e",
            // },
            // {
            //   value: "end_bar",
            //   text: t("labels.arrowhead_bar"),
            //   keyBinding: "e",
            //   icon: <ArrowheadBarIcon flip={isRTL} />,
            // },

            // {
            //   value: "start_triangle",
            //   text: t("labels.arrowhead_triangle"),
            //   icon: <ArrowheadTriangleIcon flip={!isRTL} />,
            //   keyBinding: "t",
            // },
            // {
            //   value: "end_triangle",
            //   text: t("labels.arrowhead_triangle"),
            //   icon: <ArrowheadTriangleIcon flip={isRTL} />,
            //   keyBinding: "t",
            // },
          ]}
          value={getFormValue<Record<"start" | "end", Arrowhead | null>>(
            elements,
            appState,
            (element) =>
              isLinearElement(element) && canHaveArrowheads(element.type)
                ? { "start": element.startArrowhead, "end": element.endArrowhead }
                :
                { "start": appState.currentItemStartArrowhead, "end": appState.currentItemEndArrowhead }

          )}
          onChange={(value) => {
            const valueChunk = value.split("_")
            updateData({ position: valueChunk[0], type: valueChunk[1] });
          }}
        />

      </div>

    );
  },
});


export const actionTranslate = register({
  name: "translate",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (el) =>
          newElementWith(el, {
            opacity: value,
          }),
        true,
      ),
      appState: { ...appState },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {

    return <div className="iconSelectList buttonList">
      <TranslateAI
        textValue={getFormValue(
          elements,
          appState,
          (element) => {
            if (isTextElement(element)) {
              return element.text;
            }
            return null;
          },
        )}

        label={t("labels.translate")}
        ownerElement={elements}
        name={"TranslateButton"}
        icon={TranslateIcon}
        appState={appState}
        setAppState={updateData}
      />
    </div>
  },
});


export const actionTextRequestAI = register({
  name: "textRequestAI",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements,
      appState: { ...appState, requestAIText: value },
      commitToHistory: true,
    };


  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <ToolButton
      type="button"
      icon={RequestAIColorIcon}
      title={`textRequestAI`}
      aria-label="textRequestAI"
      onClick={() => {
        updateData(getFormValue(
          elements,
          appState,
          (element) => {
            if (isTextElement(element)) {
              return element.text;
            }
            return null;
          },
        ))
      }}
      visible={isSomeElementSelected(getNonDeletedElements(elements), appState)}
    />
  ),
});


export const actionTextInsertToDoc = register({
  name: "textInsertToDoc",
  trackEvent: false,
  perform: (elements, appState, value) => {
    let noteContent = ''
    if (appState.pageNoteWinInfo && appState.pageNoteWinInfo.content) {
      noteContent = appState.pageNoteWinInfo.content
    }
    noteContent += "<p>" + value + "</p>";
    const pageNoteWinInfo = appState.pageNoteWinInfo ? { ...appState.pageNoteWinInfo, content: noteContent, closed: false } : { id: "page-note-1", closed: false, title: "", content: noteContent, time: getformatDate() }

    return {
      elements,
      appState: {
        ...appState,
        selectedElementIds: {},
        pageNoteWinInfo: pageNoteWinInfo
      },
      commitToHistory: true,
    };


  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <ToolButton
      type="button"
      icon={IconInsertToDoc}
      title={`textInsertToDoc`}
      aria-label="textInsertToDoc"
      onClick={() => {
        updateData(getFormValue(
          elements,
          appState,
          (element) => {
            if (isTextElement(element)) {
              return element.text;
            }
            return null;
          },
        ))
      }}
      visible={isSomeElementSelected(getNonDeletedElements(elements), appState)}
    />
  ),
});


export const actionImageInsertToDoc = register({
  name: "imageInsertToDoc",
  trackEvent: false,
  perform: (elements, appState, value) => {

    return {
      elements,
      appState: { ...appState, insertDocImageId: value },
      commitToHistory: true,
    };


  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <ToolButton
      type="button"
      icon={IconInsertToDoc}
      title={`imageInsertToDoc`}
      aria-label="imageInsertToDoc"
      onClick={() => {
        updateData(getFormValue(
          elements,
          appState,
          (element) => {
            return new Date().getTime().toString()
          },
        ))
      }}
      visible={isSomeElementSelected(getNonDeletedElements(elements), appState)}
    />
  ),
});

export const actionImageRequestAI = register({
  name: "imageRequestAI",
  trackEvent: false,
  perform: (elements, appState, value) => {

    return {
      elements,
      appState: { ...appState, requestAIImageId: value },
      commitToHistory: true,
    };


  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <ToolButton
      type="button"
      icon={RequestAIColorIcon}
      title={`imageRequestAI`}
      aria-label="imageRequestAI"
      onClick={() => {
        updateData(getFormValue(
          elements,
          appState,
          (element) => {
            return new Date().getTime().toString()
          },
        ))
      }}
      visible={isSomeElementSelected(getNonDeletedElements(elements), appState)}
    />
  ),
});
