import '@wangeditor-next/editor/dist/css/style.css'
import React, { useState, useEffect } from 'react'
import "./NotesPanel.scss";
import RichEditor from './RichEditor'
import { Input } from 'antd';
import { LocalData } from '../imago-app/data/LocalData';
import { AppState } from '../types';
import { Editor, Toolbar } from '@wangeditor-next/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig, i18nChangeLanguage } from '@wangeditor-next/editor'

const { TextArea } = Input;

export const getformatDate = () => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const date = new Date();
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${day} ${month},${year} ${hours}:${formattedMinutes} ${ampm}`;
}

export const NotesPanel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  isShowToolBar?: boolean
}> = ({ appState, setAppState, isShowToolBar }) => {

  const initPageNote = () => {
    if (!appState.pageNoteWinInfo) {
      setAppState({ pageNoteWinInfo: { id: "page-note-1", closed: false, title: "", content: "", time: getformatDate() } })
    } else {
      appState.pageNoteWinInfo && setAppState({
        pageNoteWinInfo: {
          ...appState.pageNoteWinInfo,
          closed: false
        },
      });
    }
  }

  useEffect(() => {
    initPageNote()
  }, []);

  const [editor, setEditor] = useState<IDomEditor | null>(null)
  useEffect(() => {
    i18nChangeLanguage('en')

  }, [])


  const toolbarConfig: Partial<IToolbarConfig> = {
    // toolbarKeys: [
    //   'headerSelect',
    //   '|',
    //   "redo", "undo", 'bold', 'underline', 'italic', 'through', 'color', 'bgColor', 'indent', 'delIndent', 'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyJustify', 'fontSize', "emotion",
    //   "uploadVideo", "todo",
    //   "insertImage"
    // ]
  }


  const editorConfig: Partial<IEditorConfig> = {
    placeholder: 'content...',
    MENU_CONF: {
      "uploadImage": {
        server: "",
        metaWithUrl: false,
        onSuccess: () => {

        },
        onFailed: () => {

        },
        onError: () => {

        },
        base64LimitSize: 5 * 1024 * 1024
      }
    }
  }

  useEffect(() => {
    return () => {
      if (editor == null) return
      editor.destroy()
      setEditor(null)
    }
  }, [editor])

  return (
    <div onCopy={(event) => {
      event.stopPropagation();
    }} className='page-notes-container'>
      {isShowToolBar && (<div className='rich-note-tool-bar'>
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ with: "600px" }}
        />
      </div>)}
      <div className='note-content'>


        <div className='note-content-main'>
          <div className="note-content-main-title">
            <TextArea value={appState.pageNoteWinInfo?.title}
              placeholder="Title"
              onChange={(e) => {
                appState.pageNoteWinInfo && setAppState({
                  pageNoteWinInfo: {
                    ...appState.pageNoteWinInfo,
                    title: e.target.value,
                    time: getformatDate(),
                    closed: false
                  },
                });
              }}
              autoSize />

            <div className='time'>{appState.pageNoteWinInfo?.time}</div>

          </div>
          <div className='note-content-main-rich-edit'>
            <Editor
              defaultConfig={editorConfig}
              value={appState.pageNoteWinInfo?.content}
              onCreated={setEditor}
              onChange={editor => {
                appState.pageNoteWinInfo && setAppState({
                  pageNoteWinInfo: {
                    ...appState.pageNoteWinInfo,
                    content: editor.getHtml(),
                    time: getformatDate(),
                    closed: false
                  },
                });

              }}
              mode="default"
              style={{ flex: 1, overflowY: 'hidden' }}
            />

          </div>

        </div>
      </div>
    </div>

  )
}
