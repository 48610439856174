import clsx from "clsx";
import { ActionManager } from "../actions/manager";
import { t } from "../i18n";
import { AppState, ImagoProps } from "../types";
import {
  ExitZenModeAction,
  FinalizeAction,
  UndoRedoActions,
  ZoomActions,
} from "./Actions";
import { useDevice } from "./App";
import { CollabLogo, WelcomeScreenHelpArrow, AIBotIcon, AIBotCloseIcon, GlowingStarIcon } from "./icons";
import { Section } from "./Section";
import Stack from "./Stack";
import WelcomeScreenDecor from "./WelcomeScreenDecor";
import { Island } from "./Island";
import { UserList } from "./UserList";
import CollabButton from "./CollabButton";
import { LibraryButton } from "./LibraryButton";
import { Profile } from "./Profile";
import { Pagination } from "./Pagination";
import { actionChangePage } from "../actions/actionChangePage";
import { Zoom } from "./Zoom";
import { WebEmbeds } from "./WebEmbed";
import { PageManager } from "../imago-app/data/PageManager";
import { IconLogoCollab } from "./newIcons";
import { GetLoginedUser } from "../utils";
import { Tag } from "antd";
import { useState } from "react";
import { AppMenu } from "./AppMenu";
import { Popover } from "./Popover";
import { AppFooter } from "./AppFooter";
import { nanoid } from "nanoid";

const Footer = ({
  appState,
  actionManager,
  renderCustomFooter,
  showExitZenModeBtn,
  renderWelcomeScreen,
  onCollabButtonClick,
  isCollaborating,
  setAppState,
  onLogoutDelete,
  onLogoutSave,
  operaPage,
  onViewBackgroundColorChange
}: {
  appState: AppState;
  actionManager: ActionManager;
  renderCustomFooter?: ImagoProps["renderFooter"];
  showExitZenModeBtn: boolean;
  renderWelcomeScreen: boolean;
  onCollabButtonClick?: () => void;
  isCollaborating: boolean;
  setAppState: React.Component<any, AppState>["setState"];
  onLogoutDelete: () => void;
  onLogoutSave: () => void;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  onViewBackgroundColorChange?: (color: string) => void;
}) => {
  const [showAppMenu, setShowAppMenu] = useState<boolean>(false);
  const device = useDevice();
  const showFinalize =
    !appState.viewModeEnabled && appState.multiElement && device.isTouchScreen;
  const user = GetLoginedUser();
  const [showModal, setShowModal] = useState(false);
  const [showGlowingStarModal, setShowGlowingStarModal] = useState(false);
  return (
    <footer
      role="contentinfo"
      className="layer-ui__wrapper__footer App-menu App-menu_bottom"
    >

      {appState.documentMode === "board" && (<>

        <div></div>
        <div
          className={"zen-mode-transition"}
        >
          {renderCustomFooter?.(false, appState)}
        </div>
        <div
          className={clsx("layer-ui__wrapper__footer-right zen-mode-transition", {
            "layer-ui__wrapper__footer-left--transition-bottom":
              appState.zenModeEnabled,
          })}
        >
          <Stack.Row gap={2}>
            <Stack.Col gap={1}>

              <Island
                padding={1}
                className={clsx("App-toolbar", {
                  "zen-mode": appState.zenModeEnabled,
                })}
              >
                <Section heading="canvasActions">
                  <WebEmbeds appState={appState} setAppState={setAppState} showLeftDivider={false} />
                </Section>
              </Island>
            </Stack.Col>
            <Stack.Col gap={1}>
              <Island
                padding={1}
                className={clsx("App-toolbar", {
                  "zen-mode": appState.zenModeEnabled,
                })}
              >
                <Section heading="canvasActions">
                  {/* <UserList
                  collaborators={appState.collaborators}
                  actionManager={actionManager}
                />
                {onCollabButtonClick && (
                  <CollabButton
                    isInHamburgerMenu={false}
                    isCollaborating={isCollaborating}
                    collaboratorCount={appState.collaborators.size}
                    onClick={onCollabButtonClick}
                    appState={appState}
                    setAppState={setAppState}
                  />
                )}
                {!appState.viewModeEnabled && (
                  <LibraryButton appState={appState} setAppState={setAppState} />
                )} */}
                  <Stack.Col gap={1}>
                    <Stack.Row align="center">
                      <Pagination appState={appState} actionManager={actionManager} setAppState={setAppState} />
                    </Stack.Row>
                  </Stack.Col>
                  <div className="App-toolbar__divider"></div>
                  <Stack.Col gap={1}>
                    <Stack.Row align="center">
                      <ZoomActions
                        renderAction={actionManager.renderAction}
                        zoom={appState.zoom}
                      />
                    </Stack.Row>
                  </Stack.Col>

                  {/*  <div className="App-toolbar__divider"></div>

              {!appState.viewModeEnabled && (
                <UndoRedoActions
                  renderAction={actionManager.renderAction}
                  className={clsx("zen-mode-transition", {
                    "layer-ui__wrapper__footer-left--transition-bottom":
                      appState.zenModeEnabled,
                  })}
                />
              )} */}
                  {/* {showFinalize && (
                <FinalizeAction
                  renderAction={actionManager.renderAction}
                  className={clsx("zen-mode-transition", {
                    "layer-ui__wrapper__footer-left--transition-left":
                      appState.zenModeEnabled,
                  })}
                />
              )} */}
                </Section>
              </Island>
            </Stack.Col>
          </Stack.Row>
        </div>

      </>)}

      {/* <div className={"layer-ui__wrapper__footer-right-star zen-mode-transition"}>
          <div className="glowing-star"
              onClick={() => setShowGlowingStarModal((prev) => !prev)}
            >
              {GlowingStarIcon}
          </div>
                         
            
          {showGlowingStarModal && (
            <div className="modal-overlay">
              <div className="modal-content">
                <button
                  className="modal-close"
                  onClick={() => setShowGlowingStarModal(false)}
                >
                  {AIBotCloseIcon}
                </button>
                <h2>{t("home_welcome.teacher_content_generator")}</h2>

                <iframe
                  src="https://dify.imago.work/completion/8khXV0fgtpNhvSxx"
                  style={{
                    width: "100%",
                    height: "90%",
                    minHeight: "450px",
                    border: "none",
                  }}
                ></iframe>
              </div>
            </div>
          )}
      </div> */}

      <div className={"layer-ui__wrapper__footer-right-bot zen-mode-transition"}>
        <div className="ai-bot"
          onClick={() => {
            setAppState({ openRightSidebar: "ai_assist" })
          }}
        >
          {AIBotIcon}
        </div>

        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button
                className="modal-close"
                onClick={() => setShowModal(false)}
              >
                {AIBotCloseIcon}
              </button>
              <h2>{t("home_welcome.collab_bot")}</h2>

              <iframe
                src="https://dify.imago.work/chat/gNXdHGchtm9p6naJ"
                style={{
                  width: "100%",
                  height: "86%",
                  minHeight: "450px",
                  border: "none",
                }}
              ></iframe>
            </div>
          </div>
        )}
      </div>

      <AppFooter
        appState={appState}
        setAppState={setAppState}
        isCollaborating={isCollaborating}
        actionManager={actionManager}
        onLogoutDelete={onLogoutDelete}
        onLogoutSave={onLogoutSave}
        operaPage={operaPage}
        onViewBackgroundColorChange={onViewBackgroundColorChange}
      ></AppFooter>
    </footer>
  );
};

export default Footer;
