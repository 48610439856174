import React, { useEffect, useRef, useState } from "react";
import { AppState, PageNoteWinInfo, StickyNote } from "../types";
import { DraggablePanel } from "./DraggablePanel";
import "./PageNotePanel.scss";
import { IconColors, IconTranslate, IconTrash, IconZoomIn, IconZoomOut } from "./newIcons";
import { nanoid } from "nanoid";
import { collabAPIAtom, isCollaboratingAtom } from "../imago-app/collab/Collab";
import { useAtom } from "jotai";
import { API_URL } from "../constants";
import { TranslateAIPopupNote } from "./TranslateAIPopup";
import { MinimizeIcon, RequestAIIcon } from "./icons";
import { requestAIChatbots } from "./AiChat";
import { LocalData } from "../imago-app/data/LocalData";
import { DraggablePanelPageNoteWin } from "./DraggablePanelPageNoteWin";
import { NotesPanel } from "./NotesPanel";
import { NotesPanelPre } from "./NotesPanelPre";



export const PageNotePanel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  pageNote: PageNoteWinInfo
}> = ({
  appState,
  setAppState,
  pageNote
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const colorSelectRef = useRef<HTMLDivElement | null>(null);
    const [collabAPI] = useAtom(collabAPIAtom);
    const [renderFlag, setRenderFlag] = useState<string>()
    const [showTranslateWin, setShowTranslateWin] = useState(false);
    const [winDefaultWidth, setWinDefaultWidth] = useState<number>(600)
    const [pageNoteTitle, setPageNoteTitle] = useState<string>("");
    const [pageNoteContent, setPageNoteContent] = useState<string>("");



    useEffect(() => {
      setRenderFlag(new Date().getTime().toString())
    }, [appState.pageNoteWinInfo]);

    return (
      <DraggablePanelPageNoteWin
        key={pageNote.id}
        id={pageNote.id}
        title=""
        width={winDefaultWidth}
        renderFlag={renderFlag}
        height={400}
        appState={appState}
        setAppState={setAppState}
        buttons={[]}
        resizeable={false}
        useActiveTools={true}
        radius={5}
        position={appState.pageNoteWinInfo?.position}

        onMove={(position: [number, number]) => {
          appState.pageNoteWinInfo && setAppState({
            pageNoteWinInfo: {
              ...appState.pageNoteWinInfo,
              position: position,
            },
          });
        }}
      >
        <div className="page-sticky-note-content">
          <div className="content">
            <NotesPanelPre appState={appState} setAppState={setAppState} />
          </div>
        </div>
      </DraggablePanelPageNoteWin>
    );
  };
