import React from "react";
import { ActionManager } from "../actions/manager";
import { SHAPES } from "../shapes";
import { AppState, StickyNote } from "../types";
import {
  BottomColorPickerAction,
  BottomExtraActions,
  BottomMoreActions,
  GroupShapesSwitcher,
  FileSaveGroupActions,
} from "./Actions";
import { ToolButton, ToolFlashCollab } from "./ToolButton";
import { TemplateIcon, createStringIcon, PersonalBoardIcon, NoteIcon } from "./icons";
import { isGoogleMeetAddonAtom } from "./App";
import { useAtom } from "jotai";
import { TemplateSelector } from "./TemplateSelector";
import CollabButton from "./CollabButton";
import { IconCollabration } from "./newIcons";
import { getCurrBoardMode, isCollabtionHost } from "../imago-app/data/localStorage";
import { nanoid } from "nanoid";
import { StickyNoteBackground } from "./StickyNotePanel";
import { capitalizeString } from "../utils";
import { t } from "../i18n";
import { BOARD_MODE } from "../constants";
import { collabAPIAtom } from "../imago-app/collab/Collab";

type ToolbarProps = {
  appState: AppState;
  actionManager: ActionManager;
  setAppState: React.Component<any, AppState>["setState"];
  onCollabButtonClick?: () => void;
  canvas: HTMLCanvasElement | null;
  isCollaborating: boolean;
  onImageAction: (data: {
    insertOnCanvasDirectly: boolean;
    callback: () => void;
  }) => void;
  activeRoomId?: string;
  onFlashCollabClick?: () => void;
  isFlashCollabing?: boolean;
  toolbarSetting: any[];
  loadLocalLibToCanvas?: (type: any) => void;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  isMobile?: boolean;
  addFileToCanvas: (file: File) => Promise<void>;
};
export const Toolbar: React.FC<ToolbarProps> = ({
  appState,
  canvas,
  setAppState,
  onImageAction,
  isCollaborating,
  onCollabButtonClick,
  activeRoomId,
  isFlashCollabing,
  onFlashCollabClick,
  toolbarSetting,
  actionManager,
  loadLocalLibToCanvas,
  operaPage,
  isMobile,
  addFileToCanvas,
}) => {
  const [collabAPI] = useAtom(collabAPIAtom);
  const [isGoogleMeetAddon, _] = useAtom(isGoogleMeetAddonAtom);
  const isHost = isCollabtionHost()
  const currBoardMode = getCurrBoardMode()
  return (
    <>
      {toolbarSetting.map((group, index) => {
        return (
          <React.Fragment key={`tool-group-${index}`}>
            {group.map((items: any, groupi: number) => {
              if (items.every((item: any) => item.name === "template")) {
                // return (
                //   <TemplateSelector
                //     key={"template-selector"}
                //     appState={appState}
                //     addFileToCanvas={addFileToCanvas}
                //   />
                // );
              } else if (
                items.every((item: any) => item.name.indexOf("color-") === 0)
              ) {
                return (
                  <BottomColorPickerAction
                    key={"BottomColorPickerAction"}
                    renderAction={actionManager.renderAction}
                    items={items}
                    setAppState={setAppState}
                  />
                );
              }
              // else if (
              //   items.some(
              //     (item: any) =>
              //       [
              //         "background",
              //         "dark-mode",
              //         "dot-grid",
              //         "line-grid",
              //         "line-dash-grid",
              //       ].indexOf(item.name) > -1,
              //   )
              // ) {
              //   return (
              //     <React.Fragment key={"changeViewBackgroudStyle"}>
              //       {actionManager.renderAction("changeViewBackgroudStyle", {
              //         options: items.map((item: any) => {
              //           return {
              //             value: item.name,
              //             text: item.name,
              //             icon: item.icon,
              //             default: item.default,
              //             loadLocalLibToCanvas,
              //           };
              //         }),
              //       })}
              //     </React.Fragment>
              //   );
              // } 
              else if (
                items.every(
                  (item: any) =>
                    [
                      "pointer",
                      "pencil",
                      "brush",
                      "marker",
                      "magicpen",
                      "rectangle",
                      "square",
                      "triangle",
                      "pentagon",
                      "hexagon",
                      "octagon",
                      "circle",
                      "perspective",
                      "rosette",
                      "star",
                      "cylinder",
                      "heart",
                      "tick",
                      "cross",
                      "ellipse",
                      "diamond",
                      "line",
                      "arrow",
                      "connectarrow",
                      "text",
                      "magictext",

                      "library",
                      "eraser-one",
                      "eraser-brush",
                      "eraser-all",
                    ].indexOf(item.name) > -1,
                )
              ) {
                const groupname = items[0].name;
                const tools = items
                  .filter(
                    (i: any) => !isCollaborating || i.name != "eraser-all",
                  )
                  .map((item: any) => {
                    const shape = SHAPES.find((s) => s.name === item.name) || {
                      name: item.name,
                      icon: item.icon,
                      value: item.name,
                      key: "",
                      numericKey: "",
                      fillable: true,
                      custom: true,
                    };
                    const icon = item.icon
                      ? createStringIcon(item.icon)
                      : shape.icon;
                    return {
                      ...shape,
                      visible: item.default,
                      group: groupname,
                      icon,
                    };
                  });
                return (
                  <GroupShapesSwitcher
                    key={`GroupShapesSwitcher_${groupi}`}
                    appState={appState}
                    canvas={canvas}
                    activeTool={appState.activeTool}
                    setAppState={setAppState}
                    renderAction={actionManager.renderAction}
                    onImageAction={({ pointerType, callback }) => {
                      onImageAction({
                        insertOnCanvasDirectly: pointerType !== "mouse",
                        callback,
                      });
                    }}
                    tools={tools}
                  />
                );
              } else if (
                items.every(
                  (item: any) =>
                    [
                      "note"].indexOf(
                        item.name,
                      ) > -1,
                )
              ) {

                if ((!isCollaborating) || (isCollaborating && isHost) || (isCollaborating && currBoardMode !== BOARD_MODE.classRoom)) {
                  const label = t(`toolBar.note`);
                  return (
                    <ToolButton
                      type="button"
                      aria-label="note"
                      title={capitalizeString(label)}
                      onClick={() => {
                        const stickyNote: StickyNote = {
                          id: nanoid(),
                          key: nanoid(),
                          content: "",
                          status: "expand",
                          background: StickyNoteBackground[Math.floor((Math.random() * 10) / 2)],
                          fontStyle: "normal",
                          textDecoration: "none",
                          fontWeight: "normal",
                          creatorUserId: appState.userInfo?.id,
                          position: null
                        }
                        collabAPI?.addStickyNote(stickyNote)
                      }
                      }
                      key="note"
                      icon={NoteIcon}
                    />)
                }

              } else if (
                items.every(
                  (item: any) =>
                    [
                      "personalBoard"].indexOf(
                        item.name,
                      ) > -1,
                )
              ) {
                if (isCollaborating && !isHost && currBoardMode == BOARD_MODE.classRoom) {
                  const label = t(`toolBar.personalBoard`);
                  return (
                    <ToolButton
                      type="button"
                      aria-label="personalBoard"
                      title={capitalizeString(label)}
                      onClick={() => {
                        setAppState({
                          openPersonalBoard: !appState.openPersonalBoard
                        })
                      }
                      }
                      key="personalBoard"
                      icon={PersonalBoardIcon}
                    />)
                }

              } else if (
                items.every(
                  (item: any) =>
                    ["gusture", "zoomin", "zoomout", "undo", "redo"].indexOf(
                      item.name,
                    ) > -1,
                )
              ) {
                return (
                  <BottomExtraActions
                    key={"BottomExtraActions"}
                    renderAction={actionManager.renderAction}
                    appState={appState}
                    setAppState={setAppState}
                    actions={items.map((item: any) => {
                      return {
                        ...item,
                        visible: item.default,
                        icon: item.icon && createStringIcon(item.icon),
                      };
                    })}
                    canvas={canvas}
                  />
                );
              } else if (isCollaborating && items.every(
                (item: any) =>
                  [
                    "audio-call"
                  ].indexOf(item.name) > -1,
              )) {
                const item = items[0];
                return <div style={{ position: "relative" }}>
                  {appState.waitCheckMemberCount > 0 && isHost && currBoardMode != BOARD_MODE.warRoom && (<div style={{ position: "absolute", height: "20px", width: "20px", top: "-5px", right: "-5px", background: "red", borderRadius: "10px", zIndex: "100", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", textAlign: "center" }}>{appState.waitCheckMemberCount}</div>)}
                  <ToolButton
                    type="button"
                    aria-label="collaborators"
                    onClick={() => {
                      setAppState({ openDialog: "collaborators" });
                    }
                    }


                    key={item.name}
                    icon={IconCollabration}
                  />
                </div>
              } else if (
                items.every(
                  (item: any) =>
                    [
                      "page-list",
                      "library",
                      "image",
                      "googleDrive",
                      "googleSearch",
                    ].indexOf(item.name) > -1,
                )
              ) {
                return (
                  <BottomMoreActions
                    key={"BottomMoreActions"}
                    renderAction={actionManager.renderAction}
                    appState={appState}
                    setAppState={setAppState}
                    data={{
                      isCollaborating,
                      onClick: onCollabButtonClick,
                    }}
                    actions={items.map((item: any) => {
                      return {
                        ...item,
                        visible: item.default,
                        icon: item.icon && createStringIcon(item.icon),
                      };
                    })}
                  />
                );
              } else if (
                items.every(
                  (item: any) =>
                    ["file", "file-save-to", "file-save", "load-file"].indexOf(
                      item.name,
                    ) > -1,
                )
              ) {
                return (
                  <FileSaveGroupActions
                    key={"FileSaveGroupActions"}
                    renderAction={actionManager.renderAction}
                    appState={appState}
                    setAppState={setAppState}
                    operaPage={operaPage}
                    data={{
                      isCollaborating,
                    }}
                    actions={items.map((item: any) => {
                      return {
                        ...item,
                        visible: item.default,
                        icon: item.icon && createStringIcon(item.icon),
                      };
                    })}
                  />
                );
              }
              // else if (
              //   items.every((item: any) => ["audio"].indexOf(item.name) > -1)
              // ) {
              //   return (
              //     <div key={"ToolFlashCollab"}>
              //       {activeRoomId !== null &&
              //         activeRoomId !== "" &&
              //         !isGoogleMeetAddon && (
              //           <ToolFlashCollab
              //             appState={appState}
              //             isFlashCollabing={isFlashCollabing}
              //             onClick={onFlashCollabClick}
              //           />
              //         )}
              //     </div>
              //   );
              // }
              return items.map((item: any, i: number) => {
                if (item.name === "stroke") {
                  return (
                    <React.Fragment key={`other-tool-${i}`}>
                      {actionManager.renderAction(
                        "bottomChangeStrokeWidth",
                        item,
                      )}
                    </React.Fragment>
                  );
                }
              });
            })}{" "}
            {!isMobile && toolbarSetting.length - 1 > index ? (
              <div className="App-toolbar__divider"></div>
            ) : null}{" "}
          </React.Fragment>
        );
      })}
    </>
  );
};
