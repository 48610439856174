import {
  useRef,
  useState,
  useEffect,
  useCallback,
  RefObject,
  forwardRef,
  SetStateAction,
} from "react";
import Library, {
  distributeLibraryItemsOnSquareGrid,
  libraryItemsAtom,
  useHandleLibrary,
} from "../../data/library";
import {
  AiIcon,
  BlueSendIcon,
  FlowChartIcon,
  IconClose,
  IconCloseRightSideWin,
  IconCloseWin,
  IconCopy,
  IconImageAdd,
  IconLeftGoHistory,
  IconRemovePreImg,
  IconSendMsgDefault,
  IconUnion,
  ImageAiIcon,
  LibMenuIcon,
  MermaidIcon,
  ShareScreenIcon,
} from "../icons";
import { t } from "../../i18n";
import {
  LibraryItems,
  LibraryItem,
  AppState,
  ImagoProps,
  ImagoAPIRefValue,
  BinaryFiles,
  BinaryFileData,
  StickyNote,
  DataURL,
} from "../../types";
import "./RightSidePanel.scss";
import { NonDeletedImagoElement, PointerType } from "../../element/types";
import { Input, message, Select } from 'antd';
import { nanoid } from "nanoid";
import { API_URL } from "../../constants";
import { marked } from "marked";
import Spinner from "../Spinner";
import { removeFromSelectedGroups } from "../../groups";
import { AiChatTranslateWin } from "../AiChatTranslateWin";
import { IconSend } from "../newIcons";
import { StickyNoteBackground } from "../StickyNotePanel";
import { useAtom } from "jotai";
import { aiAnalyzeMsgsAtom, aiChatMsgsAtom, aiGenerateAudioResultAtom, aiGenerateImageResultAtom, aiLearningCompanionInfoAtom, aiLessonPlanInfoAtom, aiQuizInfoPromptAtom, aiSummarizeAfterInfoAtom, aiSummarizeOriginInfoAtom, aiVocabularyListInfo, collabAPIAtom } from "../../imago-app/collab/Collab";
import clsx from "clsx";
import { fileOpen } from "../../data/filesystem";
import { getDataURL, resizeImageFile } from "../../data/blob";
import { AiTranslateAssist } from "../AiTranslateAssist";
import { result } from "lodash";
import { jotaiStore } from "../../jotai";
const { TextArea } = Input;


export interface Message {
  id: string;
  userMsg: string;
  isDelUserMsg: boolean;
  isDelAiMsg: boolean;
  aiMsg: string;
  aiTranslateMsg?: string;
  userTranslateMsg?: string;
  imageInfo?: AiImageInfo;
  imageDataUrlPre?: DataURL | null
}

interface preImageItem {
  imgUrl: string;
  modeName: string;
}

export interface AiImageInfo {
  filename: string;
  subfolder: string;
  type: string;
  prompt: string
}


export interface AiQuiz {
  subject: string;
  grade: string;
  topic: string;
  language: string;
  result: string;
}

export interface AiLearningLession {
  country: string;
  subject: string;
  grade: string;
  language: string;
  question: string;
  result: string;
}


export interface AiVocabulary {
  subject: string;
  grade: string;
  text: string;
  language: string;
  result: string;
}


export const RightSidePanel: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  onInsertElements: (elements: readonly NonDeletedImagoElement[]) => void;
  focusContainer: () => void;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  pageList?: string[];
  currPage?: string;
  libraryReturnUrl: ImagoProps["libraryReturnUrl"];
  library: Library;
  scanUploadImage: (
    data: { insertOnCanvasDirectly: boolean },
    imageFile: File,
  ) => void;
  onImageAction: (data: {
    pointerType: PointerType | null;
    callback: () => void;
  }) => void;
  files: BinaryFiles;
  deleteFiles: (fileIds: string[]) => void;
  addFiles: (files: BinaryFileData[]) => void;
  isCollaborating: boolean;
  id: string;

  onLibraryItemTouchEnd?: (id: string, event: React.TouchEvent) => void;
  onLibraryPageChange: (page: number, pageSize: number) => void;
  onRoomCreated: () => void;
}> = ({
  appState,
  setAppState,
  id,

}) => {
    const [value, setValue] = useState(100);
    const [chatMsgContent, setChatMsgContent] = useState<string>("");
    const [analyzeImagePre, setAnalyzeImagePre] = useState<DataURL | null>(null);
    const [analyzeContent, setAnalyzeContent] = useState<string>("Analysis the image given");
    const [currentAIResponse, setCurrentAIResponse] = useState('');
    const [chatMsgs, setChatMsgs] = useState<Message[]>([]);
    const [analyzeMsgs, setAnalyzeMsgs] = useState<Message[]>([]);
    const [summarizeOriginInfo, setSummarizeOriginInfo] = useState<string>('');
    const [isRequestSummarize, setIsRequestSummarize] = useState<boolean>(false);
    const [isRequestQuiz, setIsRequestQuiz] = useState<boolean>(false);
    const [isRequestVocabulary, setIsRequestVocabulary] = useState<boolean>(false);
    const [isRequestLearning, setIsRequestLearning] = useState<boolean>(false);
    const [isRequestLessonPlan, setIsRequestLessonPlan] = useState<boolean>(false);
    const [isUploadImgToServer, setIsUploadImgToServer] = useState<boolean>(false);
    const [isRequestGenerateImg, setIsRequestGenerateImg] = useState<boolean>(false);
    const [isRequestGenerateAudio, setIsRequestGenerateAudio] = useState<boolean>(false);
    const [summarizeAfterInfo, setSummarizeAfterInfo] = useState<string>('');
    const [generateImageType, setGenerateImageType] = useState<string>('Default');
    const [generateImageResult, setGenerateImageResult] = useState<AiImageInfo & { loaded: boolean }>({ loaded: false, prompt: '', filename: '', subfolder: '', type: '' });
    const [generateAudioResult, setGenerateAudioResult] = useState<AiImageInfo & { loaded: boolean }>({ loaded: false, prompt: '', filename: '', subfolder: '', type: '' });
    const [analyzeUploadImageInfo, setAnalyzeUploadImageInfo] = useState<AiImageInfo>({ prompt: '', filename: '', subfolder: '', type: '' });
    const [isActive, setActive] = useState(false);
    const [quizInfoPrompt, setQuizInfoPrompt] = useState<AiQuiz>({ topic: '', grade: '', subject: '', language: '', result: '' });
    const [learningCompanionInfo, setLearningCompanionInfo] = useState<AiLearningLession>({ country: '', question: '', grade: '', subject: '', language: '', result: '' });


    const [lessonPlanInfo, setLessonPlanInfo] = useState<AiLearningLession>({ country: '', question: '', grade: '', subject: '', language: '', result: '' });

    const [vocabularyListInfo, setVocabularyListInfo] = useState<AiVocabulary>({ text: '', grade: '', subject: '', language: '', result: '' });

    const [preImgs, setPreImgs] = useState<preImageItem[]>([{
      imgUrl: "Default.jpg",
      modeName: "Default"
    }, {
      imgUrl: "3D-Model.png",
      modeName: "3D Model"
    }, {
      imgUrl: "Analog-Film.jpg",
      modeName: "Analog Film"
    }, {
      imgUrl: "Anime.jpg",
      modeName: "Anime"
    }, {
      imgUrl: "Cinematic.jpg",
      modeName: "Cinematic"
    }, {
      imgUrl: "Comic-Book.jpg",
      modeName: "Comic Book"
    }, {
      imgUrl: "Digital-Art.jpg",
      modeName: "Digital Art"
    }, {
      imgUrl: "Enhance.jpg",
      modeName: "Enhance"
    }, {
      imgUrl: "Fantasy-Art.jpg",
      modeName: "Fantasy Art"
    }, {
      imgUrl: "Isometric.jpg",
      modeName: "Isometric"
    }, {
      imgUrl: "Line-Art.jpg",
      modeName: "Line Art"
    }, {
      imgUrl: "Low-Poly.jpg",
      modeName: "Low Poly"
    }, {
      imgUrl: "Modelling-Compound.jpg",
      modeName: "Modelling Compound"
    }, {
      imgUrl: "Neon-Punk.jpg",
      modeName: "Neon Punk"
    }, {
      imgUrl: "Origami.jpg",
      modeName: "Origami"
    }, {
      imgUrl: "Photographic.jpg",
      modeName: "Photographic"
    }, {
      imgUrl: "Pixel-Art.jpg",
      modeName: "Pixel Art"
    }, {
      imgUrl: "Tile-Texture.jpg",
      modeName: "Tile Texture"
    }]);

    const [iframeQuizHasLoaded, setIframeQuizHasLoaded] = useState(false);
    const [languages, setLanguages] = useState([
      { label: 'English', value: 'English' },
      { label: 'French', value: 'French' },
      { label: 'Spanish', value: 'Spanish' },
      { label: 'German', value: 'German' },
      { label: 'Italian', value: 'Italian' },
      { label: 'Dutch', value: 'Dutch' },
      { label: 'Swedish', value: 'Swedish' },
      { label: 'Czech', value: 'Czech' },
      { label: 'Turkish', value: 'Turkish' },
      { label: 'Russian', value: 'Russian' },
      { label: 'Greek', value: 'Greek' },
      { label: 'Hungarian', value: 'Hungarian' },
      { label: 'Irish', value: 'Irish' },
      { label: 'Japanese', value: 'Japanese' },
      { label: 'Korean', value: 'Korean' },
      { label: 'Chinese, Simplified', value: 'Chinese, Simplified' },
      { label: 'Chinese, Traditional', value: 'Chinese, Traditional' },
      { label: 'Malay', value: 'Malay' },
      { label: 'Indonesian', value: 'Indonesian' },
      { label: 'Thai', value: 'Thai' },
      { label: 'Vietnamese', value: 'Vietnamese' },
      { label: 'Filipino', value: 'Filipino' },
      { label: 'Khmer', value: 'Khmer' },
      { label: 'Hindi', value: 'Hindi' },
      { label: 'Tamil', value: 'Tamil' },
      { label: 'Kazakh', value: 'Kazakh' },
      { label: 'Polish', value: 'Polish' },
      { label: 'Portuguese', value: 'Portuguese' },
      { label: 'Arabic', value: 'Arabic' },
      { label: 'Afrikaans', value: 'Afrikaans' },
      { label: 'Swahili', value: 'Swahili' },
    ]);
    const [grades, setGrades] = useState(
      [
        { label: 'Pre-K', value: 'Pre-K' },
        { label: 'Kindergarten', value: 'Kindergarten' },
        { label: '1st Grade', value: '1st Grade' },
        { label: '2nd Grade', value: '2nd Grade' },
        { label: '3rd Grade', value: '3rd Grade' },
        { label: '4th Grade', value: '4th Grade' },
        { label: '5th Grade', value: '5th Grade' },
        { label: '6th Grade', value: '6th Grade' },
        { label: '7th Grade', value: '7th Grade' },
        { label: '8th Grade', value: '8th Grade' },
        { label: '9th Grade', value: '9th Grade' },
        { label: '10th Grade', value: '10th Grade' },
        { label: '11th Grade', value: '11th Grade' },
        { label: '12th Grade', value: '12th Grade' },
        { label: 'Year 1', value: 'Year 1' },
        { label: 'Year 2', value: 'Year 2' },
        { label: 'Year 3', value: 'Year 3' },
        { label: 'Year 4', value: 'Year 4' },
        { label: 'Year 5', value: 'Year 5' },
        { label: 'Year 6', value: 'Year 6' },
        { label: 'Year 7', value: 'Year 7' },
        { label: 'Year 8', value: 'Year 8' },
        { label: 'Year 9', value: 'Year 9' },
        { label: 'Year 10', value: 'Year 10' },
        { label: 'Year 11', value: 'Year 11' },
        { label: 'Year 12', value: 'Year 12' },
        { label: 'Year 13', value: 'Year 13' },
        { label: 'University', value: 'University' },
      ]
    );


    const [countrys, setCountrys] = useState(
      [
        { label: 'United State of America', value: 'United State of America' },
        { label: 'United Kingdom', value: 'United Kingdom' },
        { label: 'Canada', value: 'Canada' },
        { label: 'Australia', value: 'Australia' },
        { label: 'New Zealand', value: 'New Zealand' },
        { label: 'Mexico', value: 'Mexico' },
        { label: 'Columbia', value: 'Columbia' },
        { label: 'Brazil', value: 'Brazil' },
        { label: 'Argentina', value: 'Argentina' },
        { label: 'Puerto Rico', value: 'Puerto Rico' },
        { label: 'France', value: 'France' },
        { label: 'Italy', value: 'Italy' },
        { label: 'Spain', value: 'Spain' },
        { label: 'Germany', value: 'Germany' },
        { label: 'Poland', value: 'Poland' },
        { label: 'Austria', value: 'Austria' },
        { label: 'Netherlands', value: 'Netherlands' },
        { label: 'Belgium', value: 'Belgium' },
        { label: 'Greece', value: 'Greece' },
        { label: 'Sweden', value: 'Sweden' },
        { label: 'Switzerland', value: 'Switzerland' },
        { label: 'Hungary', value: 'Hungary' },
        { label: 'Turkiye', value: 'Turkiye' },
        { label: 'Russia', value: 'Russia' },
        { label: 'Saudi Arabia', value: 'Saudi Arabia' },
        { label: 'Qatar', value: 'Qatar' },
        { label: 'UAE', value: 'UAE' },
        { label: 'Singapore', value: 'Singapore' },
        { label: 'Malaysia', value: 'Malaysia' },
        { label: 'Indonesia', value: 'Indonesia' },
        { label: 'Thailand', value: 'Thailand' },
        { label: 'Vietnam', value: 'Vietnam' },
        { label: 'Philippines', value: 'Philippines' },
        { label: 'Cambodia', value: 'Cambodia' },
        { label: 'Japan', value: 'Japan' },
        { label: 'Korea', value: 'Korea' },
        { label: 'Hong Kong', value: 'Hong Kong' },
        { label: 'Taiwan', value: 'Taiwan' },
        { label: 'China', value: 'China' }
      ]
    );


    useEffect(() => {
      if (chatMsgs.length == 0) {
        const cacheAiChatMsgs = jotaiStore.get(aiChatMsgsAtom);
        cacheAiChatMsgs && setChatMsgs(cacheAiChatMsgs)
      }
      if (analyzeMsgs.length == 0) {
        const cacheAiAnalyzeMsgs = jotaiStore.get(aiAnalyzeMsgsAtom);
        cacheAiAnalyzeMsgs && setAnalyzeMsgs(cacheAiAnalyzeMsgs)
      }
      if (analyzeMsgs.length == 0) {
        const cacheAiAnalyzeMsgs = jotaiStore.get(aiAnalyzeMsgsAtom);
        cacheAiAnalyzeMsgs && setAnalyzeMsgs(cacheAiAnalyzeMsgs)
      }
      if (quizInfoPrompt.result == '') {
        const cacheAiQuizInfoPrompt = jotaiStore.get(aiQuizInfoPromptAtom);
        cacheAiQuizInfoPrompt && setQuizInfoPrompt(cacheAiQuizInfoPrompt)
      }
      if (summarizeOriginInfo == '') {
        const cacheAiSummarizeOriginInfo = jotaiStore.get(aiSummarizeOriginInfoAtom);
        cacheAiSummarizeOriginInfo && setSummarizeOriginInfo(cacheAiSummarizeOriginInfo)
      }
      if (summarizeAfterInfo == '') {
        const cacheAiSummarizeAfter = jotaiStore.get(aiSummarizeAfterInfoAtom);
        cacheAiSummarizeAfter && setSummarizeAfterInfo(cacheAiSummarizeAfter)
      }
      if (generateAudioResult.filename == '') {
        const cacheAiGenerateAudioResult = jotaiStore.get(aiGenerateAudioResultAtom);
        cacheAiGenerateAudioResult && setGenerateAudioResult({ ...cacheAiGenerateAudioResult, loaded: false })
      }

      if (generateImageResult.filename == '') {
        const cacheAiGenerateImage = jotaiStore.get(aiGenerateImageResultAtom);
        cacheAiGenerateImage && setGenerateImageResult({ ...cacheAiGenerateImage, loaded: false })
      }

      if (learningCompanionInfo.result == '') {
        const cacheAiLearningCompanionInfo = jotaiStore.get(aiLearningCompanionInfoAtom);
        cacheAiLearningCompanionInfo && setLearningCompanionInfo(cacheAiLearningCompanionInfo)
      }

      if (lessonPlanInfo.result == '') {
        const cacheAiLessonPlanInfo = jotaiStore.get(aiLessonPlanInfoAtom);
        cacheAiLessonPlanInfo && setLessonPlanInfo(cacheAiLessonPlanInfo)
      }

      if (vocabularyListInfo.result == '') {
        const cacheAiVocabularyListInfo = jotaiStore.get(aiVocabularyListInfo);
        cacheAiVocabularyListInfo && setVocabularyListInfo(cacheAiVocabularyListInfo)
      }

    }, []);


    useEffect(() => {
      if (chatMsgs.length > 0) {
        jotaiStore.set(aiChatMsgsAtom, chatMsgs);
      }
      if (analyzeMsgs.length > 0) {
        jotaiStore.set(aiAnalyzeMsgsAtom, analyzeMsgs);
      }
      if (quizInfoPrompt.result != '') {
        jotaiStore.set(aiQuizInfoPromptAtom, quizInfoPrompt);
      }

      if (summarizeOriginInfo != '') {
        jotaiStore.set(aiSummarizeOriginInfoAtom, summarizeOriginInfo);
      }

      if (summarizeAfterInfo != '') {
        jotaiStore.set(aiSummarizeAfterInfoAtom, summarizeAfterInfo);
      }

      if (generateAudioResult.filename != '') {
        jotaiStore.set(aiGenerateAudioResultAtom, generateAudioResult);
      }

      if (generateImageResult.filename != '') {
        jotaiStore.set(aiGenerateImageResultAtom, generateImageResult);
      }

      if (learningCompanionInfo.result != '') {
        jotaiStore.set(aiLearningCompanionInfoAtom, learningCompanionInfo);
      }

      if (lessonPlanInfo.result != '') {
        jotaiStore.set(aiLessonPlanInfoAtom, lessonPlanInfo);
      }

      if (vocabularyListInfo.result != '') {
        jotaiStore.set(aiVocabularyListInfo, vocabularyListInfo);
      }

    }, [chatMsgs, analyzeMsgs, quizInfoPrompt, summarizeOriginInfo, summarizeAfterInfo, generateAudioResult, generateImageResult, learningCompanionInfo, lessonPlanInfo, vocabularyListInfo]);

    const handleLoadeIframeQuiz = () => {
      setIframeQuizHasLoaded(true);
    };



    const handleLoadeAudiodData = () => {
      setGenerateAudioResult({ ...generateAudioResult, loaded: true })
    };




    const renderer = new marked.Renderer();
    const [collabAPI] = useAtom(collabAPIAtom);
    const sendAiSummarize = () => {
      if (summarizeOriginInfo == '') {
        return
      }
      setSummarizeAfterInfo('')
      setIsRequestSummarize(true)
      fetch(`${API_URL.host}${API_URL.aiSummarize}`, {
        method: "POST",
        body: JSON.stringify({ prompt: summarizeOriginInfo }),
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.code == 200 && data.data && data.data[0] != '') {
            setSummarizeAfterInfo(data.data[0])
          } else {
            message.warning("The network is busy, please try again later")
          }

        })
        .catch((err) => {
          message.error("Please try again later")
          console.log(err);
        });
    };


    const sendAiQuiz = () => {
      if (quizInfoPrompt.subject == '') {
        message.warning("subject value can not be empty")
        return
      }
      if (quizInfoPrompt.grade == '') {
        message.warning("grade value can not be empty")
        return
      }
      if (quizInfoPrompt.topic == '') {
        message.warning("topic value can not be empty")
        return
      }
      if (quizInfoPrompt.language == '') {
        message.warning("language value can not be empty")
        return
      }
      setIsRequestQuiz(true)
      fetch(`${API_URL.host}${API_URL.aiCompletionMessages}`, {
        method: "POST",
        body: JSON.stringify({ subject: quizInfoPrompt.subject, grade: quizInfoPrompt.grade, topic: quizInfoPrompt.topic, language: quizInfoPrompt.language }),
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.code == 200 && data.data && data.data != '') {
            setQuizInfoPrompt({
              ...quizInfoPrompt,
              result: data.data
            })
          } else {
            setIsRequestQuiz(false)
            message.warning("The network is busy, please try again later")
          }

        })
        .catch((err) => {
          message.error("Please try again later")
          setIsRequestQuiz(false)
          console.log(err);
        });
    };


    const sendAiVocabulary = () => {
      if (vocabularyListInfo.subject == '') {
        message.warning("subject value can not be empty")
        return
      }
      if (vocabularyListInfo.grade == '') {
        message.warning("grade value can not be empty")
        return
      }
      if (vocabularyListInfo.text == '') {
        message.warning("text value can not be empty")
        return
      }
      if (vocabularyListInfo.language == '') {
        message.warning("language value can not be empty")
        return
      }
      setIsRequestVocabulary(true)
      fetch(`${API_URL.host}${API_URL.aiVocabularyGenerator}`, {
        method: "POST",
        body: JSON.stringify({ subject: vocabularyListInfo.subject, grade: vocabularyListInfo.grade, text: vocabularyListInfo.text, language: vocabularyListInfo.language }),
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.code == 200 && data.data && data.data != '') {
            setVocabularyListInfo({
              ...vocabularyListInfo,
              result: data.data
            })
          } else {
            setIsRequestVocabulary(false)
            message.warning("The network is busy, please try again later")
          }

        })
        .catch((err) => {
          message.error("Please try again later")
          setIsRequestVocabulary(false)
          console.log(err);
        });
    };

    const sendAiLearningCompanion = () => {
      if (learningCompanionInfo.country == '') {
        message.warning("country value can not be empty")
        return
      }
      if (learningCompanionInfo.grade == '') {
        message.warning("grade value can not be empty")
        return
      }

      if (learningCompanionInfo.language == '') {
        message.warning("language value can not be empty")
        return
      }
      if (learningCompanionInfo.subject == '') {
        message.warning("subject value can not be empty")
        return
      }
      if (learningCompanionInfo.question == '') {
        message.warning("question value can not be empty")
        return
      }
      setIsRequestLearning(true)
      fetch(`${API_URL.host}${API_URL.aiLearningCompanion}`, {
        method: "POST",
        body: JSON.stringify({ country: learningCompanionInfo.country, subject: learningCompanionInfo.subject, grade: learningCompanionInfo.grade, question: learningCompanionInfo.question, language: learningCompanionInfo.language }),
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.code == 200 && data.data && data.data != '') {
            setLearningCompanionInfo({
              ...learningCompanionInfo,
              result: data.data
            })
          } else {
            setIsRequestLearning(false)
            message.warning("The network is busy, please try again later")
          }

        })
        .catch((err) => {
          message.error("Please try again later")
          setIsRequestLearning(false)
          console.log(err);
        });
    };




    const sendAiLessionPlan = () => {
      if (lessonPlanInfo.country == '') {
        message.warning("country value can not be empty")
        return
      }
      if (lessonPlanInfo.grade == '') {
        message.warning("grade value can not be empty")
        return
      }

      if (lessonPlanInfo.language == '') {
        message.warning("language value can not be empty")
        return
      }
      if (lessonPlanInfo.subject == '') {
        message.warning("subject value can not be empty")
        return
      }
      if (lessonPlanInfo.question == '') {
        message.warning("question value can not be empty")
        return
      }
      setIsRequestLessonPlan(true)
      fetch(`${API_URL.host}${API_URL.aiLessonPlan}`, {
        method: "POST",
        body: JSON.stringify({ country: lessonPlanInfo.country, subject: lessonPlanInfo.subject, grade: lessonPlanInfo.grade, question: lessonPlanInfo.question, language: lessonPlanInfo.language }),
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.code == 200 && data.data && data.data != '') {
            setLessonPlanInfo({
              ...lessonPlanInfo,
              result: data.data
            })
          } else {
            setIsRequestLessonPlan(false)
            message.warning("The network is busy, please try again later")
          }

        })
        .catch((err) => {
          message.error("Please try again later")
          setIsRequestLessonPlan(false)
          console.log(err);
        });
    };


    const sendAiGenerateAudio = () => {
      if (generateAudioResult.prompt == '') {
        return
      }
      setGenerateAudioResult({ ...generateAudioResult, loaded: false })
      setIsRequestGenerateAudio(true)
      setGenerateAudioResult({ ...generateAudioResult, filename: '', subfolder: '', type: '' })
      fetch(`${API_URL.host}${API_URL.aiTextToSpeech}`, {
        method: "POST",
        body: JSON.stringify({ prompt: generateAudioResult.prompt }),
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          setGenerateAudioResult({
            ...generateAudioResult,
            filename: data.data[0].filename,
            type: data.data[0].type,
            subfolder: data.data[0].subfolder,
          })
          setIsRequestGenerateAudio(false)
        })
        .catch((err) => {
          setIsRequestGenerateAudio(false)
          console.log(err);
        });
    };


    const sendAiGenerateImage = () => {
      if (generateImageResult.prompt == '') {
        return
      }
      setIsRequestGenerateImg(true)
      setGenerateImageResult({ ...generateImageResult, filename: '', subfolder: '', type: '', loaded: false })
      let prompt = generateImageResult.prompt;
      if (generateImageType != "Default") {
        prompt = generateImageType + " style:" + prompt
      }
      fetch(`${API_URL.host}${API_URL.aiGenerateImg}`, {
        method: "POST",
        body: JSON.stringify({ prompt: prompt }),
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          setGenerateImageResult({
            ...generateImageResult,
            filename: data.data[0].filename,
            type: data.data[0].type,
            subfolder: data.data[0].subfolder,
          })
          setIsRequestGenerateImg(false)
        })
        .catch((err) => {
          setIsRequestGenerateImg(false)
          console.log(err);
        });
    };


    const sendAiAnalyseImg = () => {
      if (analyzeContent == "" || analyzeUploadImageInfo.filename == "") {
        // message.warning("Please enter your question");
        return
      }

      const msg = {
        id: nanoid(),
        userMsg: analyzeContent,
        aiMsg: '',
        imageInfo: analyzeUploadImageInfo,
        imageDataUrlPre: analyzeImagePre,
        isDelUserMsg: false,
        isDelAiMsg: false,
      };

      setAnalyzeImagePre(null)
      setAnalyzeUploadImageInfo({ ...analyzeUploadImageInfo, filename: '', subfolder: '', type: '' })
      setAnalyzeMsgs([...analyzeMsgs, msg]);

      fetch(`${API_URL.host}${API_URL.aiAnalyzeImage}`, {
        method: "POST",
        body: JSON.stringify({ prompt: msg.userMsg, fileName: msg.imageInfo.filename }),
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.code == 200 && data.data && data.data[0] != '') {
            const aiMessage = data.data[0];
            msg.aiMsg = aiMessage
            setAnalyzeMsgs([...analyzeMsgs, msg]);
          } else {
            message.warning("The network is busy, please try again later")
          }

        })
        .catch((err) => {
          message.warning("Tplease try again later")
          console.log(err);
        });
    };



    const sendAiMessage = (sendMsg: string) => {
      if (sendMsg == "") {
        // message.warning("Please enter your question");
        return
      }
      setCurrentAIResponse("")

      const msg = {
        id: nanoid(),
        userMsg: sendMsg,
        aiMsg: '',
        aiMsgPre: '',
        isDelUserMsg: false,
        isDelAiMsg: false,
      };

      setChatMsgContent("")
      setChatMsgs([...chatMsgs, msg]);

      fetch(`${API_URL.host}${API_URL.aiChat}`, {
        method: "POST",
        body: JSON.stringify({ prompt: msg.userMsg }),
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.code == 200 && data.data && data.data[0] != '') {
            const aiMessage = data.data[0];
            msg.aiMsgPre = aiMessage;
            setChatMsgs([...chatMsgs, msg]);
            displayAIMessage(aiMessage, msg.id);
          } else {
            message.warning("The network is busy, please try again later")
          }

        })
        .catch((err) => {
          message.warning("please try again later")
          console.log(err);
        });
    };


    const uploadImage = async () => {

      try {
        const imageFile = await fileOpen({
          description: "Image",
          extensions: ["jpg", "png", "svg", "gif"],
        });

        const cursorImageSizePx = 320;
        const imagePreview = await resizeImageFile(imageFile, {
          maxWidthOrHeight: cursorImageSizePx,
        });

        let previewDataURL = await getDataURL(imagePreview);
        setAnalyzeImagePre(previewDataURL)

        setIsUploadImgToServer(true)

        const formData = new FormData();
        formData.append('image', imageFile);

        fetch(`${API_URL.host}${API_URL.aiUploadImage}`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${appState.userInfo?.authorization}`
          },
        }).then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
          .then((data) => {
            setAnalyzeUploadImageInfo({
              ...analyzeUploadImageInfo,
              filename: data.data.filename,
              type: data.data.type,
              subfolder: data.data.subfolder,
            })
            setIsUploadImgToServer(false)

          })
          .catch((err) => {
            setIsUploadImgToServer(false)
            console.log(err);
          });


      } catch (error: any) {
        if (error.name !== "AbortError") {
          console.error(error);
        } else {
          console.warn(error);
        }


      }
    };

    const displayAIMessage = (message: string, msgId: string) => {
      let index = -1;
      const interval = setInterval(() => {
        if (index < message.length - 1) {
          setCurrentAIResponse((prev) => prev + message[index]);
          index++;
        } else {
          clearInterval(interval);
          setChatMsgs((prevMsgs) =>
            prevMsgs.map((msg) =>
              msg.id === msgId ? { ...msg, aiMsg: message } : msg
            )
          );
        }
      }, 10);

    };


    const handleChange = (event: any) => {
      setValue(event.target.value);
    };


    const renderAiAssist = () => {
      return <div className="ai-assist">

        {/* <div className="txt-label">
          Hi username, how can I help you today?
        </div>

        <div className="txt-input">
          <Input
            placeholder="Ask a question or generate the following content..."
            value={""}
            onChange={(e) => {

            }}
            onKeyDown={(e) => {


            }}
          />

          <div className="right-send-msg" onClick={() => { }}>
            {IconSendMsgDefault}
          </div>

        </div> */}

        <div className="actions-group">
          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_prompt_text" })
          }}>AI Chat</div>
          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_generate_image" })
          }}>Text to Image</div>
          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_analyze_image" })
          }}>Analyze Image</div>
          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_summarize" })
          }}>Summarize</div>
          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_read_article" })
          }}>Read Article</div>
          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_lesson_plan" })
          }}>Lesson Plan</div>
          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_quiz_and_assessment" })
          }}>Quiz and Assessment</div>
          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_learning_companion" })
          }}>Learning Companion</div>
          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_vocabulary_list" })
          }}>Vocabulary List</div>

          <div onClick={() => {
            setAppState({ openRightSidebar: "ai_translate" })
          }}>Translate</div>


        </div>

      </div>;
    }

    const removeMsg = (item: Message, type: string) => {
      if (type === "user") {
        item.isDelUserMsg = true
      } else {
        item.aiMsg = ''
        item.isDelAiMsg = true
        setCurrentAIResponse("")
      }

      if (item.isDelUserMsg && item.isDelAiMsg) {
        setChatMsgs([
          ...chatMsgs.filter((msg) => msg.id != item.id)
        ]);
      } else {
        setChatMsgs([
          ...chatMsgs.filter((msg) => msg.id != item.id),
          item,
        ]);
      }
    }

    const handleOnClick = (message: Message) => {
      setActive(!isActive);
      //translateText
    };

    const renderAiChat = () => {
      return (<div className="ai-chat">
        <div className="content-main">

          <div className="message-box">

            {chatMsgs.map((item) => {
              return (<>

                {(!item.isDelUserMsg || !item.isDelAiMsg) && <div key={`ai_item_${item.id}`} className="item">
                  {item.userMsg !== '' && (<div className="item-user">
                    <div className="buttons">
                      <div><a
                        href={undefined}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(item.userMsg)
                            .then(() => {
                              console.log("Text copied to clipboard");
                              message.info("Text copied to clipboard");
                            })
                            .catch((err) => {
                              console.error("Failed to copy text:", err);
                              message.error("Failed to copy text");
                            });
                        }}
                      >
                        {IconCopy}
                      </a></div>

                      <div>
                        <a
                          href={undefined}
                          onClick={() => removeMsg(item, "user")}
                        >
                          {IconClose}
                        </a>
                      </div>
                      <div>
                        <AiChatTranslateWin translateResult={(translatedText) => {
                          item.userTranslateMsg = translatedText
                          handleOnClick(item)
                        }} appState={appState} setAppState={setAppState} textValue={item.userMsg} />
                      </div>
                      <div>
                        {(item.userTranslateMsg && item.userTranslateMsg != undefined && item.userTranslateMsg != '') && (<a
                          style={{ paddingTop: "3px" }}
                          href={undefined}
                          onClick={() => {
                            item.userTranslateMsg && sendAiMessage(item.userTranslateMsg)
                          }}
                        >
                          {IconSend}
                        </a>)}
                      </div>
                    </div>
                    <div className="content">
                      {item.userMsg}
                      {(item.userTranslateMsg && item.userTranslateMsg != undefined && item.userTranslateMsg != '') && (<div
                        dangerouslySetInnerHTML={{
                          __html: marked(item.userTranslateMsg) as string,
                        }}
                      ></div>)}
                    </div>
                    <div className="avatar">
                      <div className="icon">I</div>
                    </div>
                  </div>)}

                  {(!item.isDelAiMsg) && (<div className="item-ai">
                    <div className="avatar">
                      <div className="icon">AI</div>
                    </div>

                    <div className="content">
                      {(!item.aiMsg && currentAIResponse == '') ? (<Spinner />) : (<div
                        dangerouslySetInnerHTML={{
                          __html: marked((item.id === chatMsgs[chatMsgs.length - 1].id && currentAIResponse != '') ? currentAIResponse : item.aiMsg) as string,
                        }}
                      ></div>)}
                      {(item.aiTranslateMsg && item.aiTranslateMsg != undefined) && (<div
                        dangerouslySetInnerHTML={{
                          __html: marked(item.aiTranslateMsg) as string,
                        }}
                      ></div>)}
                    </div>
                    <div className="buttons">
                      <div><a
                        href={undefined}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(item.aiMsg)
                            .then(() => {
                              console.log("Text copied to clipboard");
                              message.info("Text copied to clipboard");
                            })
                            .catch((err) => {
                              console.error("Failed to copy text:", err);
                              message.error("Failed to copy text");
                            });
                        }}
                      >
                        {IconCopy}
                      </a></div>
                      <div>

                        <a
                          href={undefined}
                          onClick={() => {

                            let userContent = item.userMsg
                            let AIContent = item.aiMsg;

                            if (window.AndroidInterface) {

                              if (item.userTranslateMsg && item.userTranslateMsg != undefined && item.userTranslateMsg != '') {
                                userContent += "\n\n" + item.userTranslateMsg
                              }

                              if (item.aiTranslateMsg && item.aiTranslateMsg != undefined && item.aiTranslateMsg != '') {
                                AIContent += "\n\n" + item.aiTranslateMsg
                              }

                              const message = JSON.stringify({
                                event: "stickyNotes",
                                user: { content: userContent },
                                assistant: { content: AIContent },
                              });
                              window.AndroidInterface.sendMessage(message);
                            } else {
                              let userStickyContent = item.userMsg
                              if (item.userTranslateMsg && item.userTranslateMsg != undefined && item.userTranslateMsg != '') {
                                userStickyContent += "<br />" + item.userTranslateMsg
                              }
                              const randomBackground = Math.floor(
                                (Math.random() * 10) / 2,
                              );


                              let aiStickyContent = item.aiMsg

                              if (item.aiTranslateMsg && item.aiTranslateMsg != '') {
                                aiStickyContent += "<br />" + item.aiTranslateMsg
                              }

                              if (aiStickyContent && aiStickyContent != '') {
                                marked
                                  .lexer(aiStickyContent)
                                  .map((t) => {
                                    return marked.parse(t.raw, {
                                      ...marked.getDefaults(),
                                      gfm: true,
                                      breaks: true,
                                      renderer,
                                    }) as string;
                                  })
                                  .join("");
                              }

                              const stickyNote: StickyNote = {
                                id: nanoid(),
                                key: nanoid(),
                                status: "expand",
                                content: `<h4>YOU:</h4><p>${userStickyContent}</p><h4>AI:</h4><p>${aiStickyContent}</p>`,
                                background:
                                  StickyNoteBackground[randomBackground],
                                fontStyle: "normal",
                                textDecoration: "none",
                                fontWeight: "normal",
                                position: null,
                              }
                              collabAPI?.addStickyNote(stickyNote)
                            }
                          }}
                        >
                          {IconUnion}
                        </a>


                      </div>
                      <div>
                        <a
                          href={undefined}
                          onClick={() => removeMsg(item, "ai")}
                        >
                          {IconClose}
                        </a>
                      </div>
                      <div>
                        <AiChatTranslateWin translateResult={(translatedText) => {
                          item.aiTranslateMsg = translatedText
                          handleOnClick(item)
                        }} appState={appState} setAppState={setAppState} textValue={item.aiMsg} />
                      </div>

                    </div>
                  </div>)}


                </div>}

              </>)
            })}
          </div>

          <div className="send-box">
            <div key="ai-chat-input" className="txt-input">
              <Input
                placeholder="Ask a question or generate the following content..."
                value={chatMsgContent}
                onChange={(e) => {
                  setChatMsgContent(e.target.value)
                }}
                onKeyDown={(e) => {


                }}
              />

              <div className="right-send-msg" onClick={() => sendAiMessage(chatMsgContent)}>
                {IconSendMsgDefault}
              </div>

            </div>
          </div>

        </div>
        <div className="buttons">
          {/* <div className="btn">Add to board</div> */}
        </div>
      </div>)
    }


    const renderAiAnalyzeImage = () => {

      return (<div className="ai-analyze-image">
        <div className="content-main">

          <div className="message-box">

            {analyzeMsgs.map((item) => {
              return (<>
                {(item.aiMsg != '' || item.userMsg != '') && <div key={`ai_item_${item.id}`} className="item">
                  {item.imageDataUrlPre && (<div className="item-uploaded-img">
                    <img src={item.imageDataUrlPre} />
                  </div>)}
                  {!item.isDelUserMsg && (<div className="item-user">
                    <div className="buttons">
                      <div><a
                        href={undefined}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(item.userMsg)
                            .then(() => {
                              console.log("Text copied to clipboard");
                              message.info("Text copied to clipboard");
                            })
                            .catch((err) => {
                              console.error("Failed to copy text:", err);
                              message.error("Failed to copy text");
                            });
                        }}
                      >
                        {IconCopy}
                      </a></div>
                      <div>
                        <a
                          href={undefined}
                          onClick={() => removeMsg(item, "user")}
                        >
                          {IconClose}
                        </a>
                      </div>
                    </div>
                    <div className="content">{item.userMsg}</div>
                    <div className="avatar">
                      <div className="icon">I</div>
                    </div>
                  </div>)}

                  {(!item.isDelAiMsg) && (<div className="item-ai">
                    <div className="avatar">
                      <div className="icon">AI</div>
                    </div>

                    <div className="content">
                      {(item.aiMsg == '' || !item.aiMsg) ? (<Spinner />) : (<div
                        dangerouslySetInnerHTML={{
                          __html: marked(item.aiMsg) as string,
                        }}
                      ></div>)}
                    </div>
                    <div className="buttons">
                      <div><a
                        href={undefined}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(item.aiMsg)
                            .then(() => {
                              console.log("Text copied to clipboard");
                              message.info("Text copied to clipboard");
                            })
                            .catch((err) => {
                              console.error("Failed to copy text:", err);
                              message.error("Failed to copy text");
                            });
                        }}
                      >
                        {IconCopy}
                      </a></div>
                      <div>
                        <a
                          href={undefined}
                          onClick={() => {

                            let userContent = item.userMsg
                            let AIContent = item.aiMsg;

                            if (window.AndroidInterface) {
                              const message = JSON.stringify({
                                event: "stickyNotes",
                                user: { content: userContent },
                                assistant: { content: AIContent },
                              });
                              window.AndroidInterface.sendMessage(message);
                            } else {
                              const randomBackground = Math.floor(
                                (Math.random() * 10) / 2,
                              );
                              let stickyContent = marked
                                .lexer(item.aiMsg)
                                .map((t) => {
                                  return marked.parse(t.raw, {
                                    ...marked.getDefaults(),
                                    gfm: true,
                                    breaks: true,
                                    renderer,
                                  }) as string;
                                })
                                .join("");

                              const stickyNote: StickyNote = {
                                id: nanoid(),
                                key: nanoid(),
                                status: "expand",
                                content: `<h4>YOU:</h4><p>${userContent}</p><h4>AI:</h4><p>${stickyContent}</p>`,
                                background:
                                  StickyNoteBackground[randomBackground],
                                fontStyle: "normal",
                                textDecoration: "none",
                                fontWeight: "normal",
                                position: null,
                              }
                              collabAPI?.addStickyNote(stickyNote)
                            }
                          }}
                        >
                          {IconUnion}
                        </a>
                      </div>
                      <div>
                        <a
                          href={undefined}
                          onClick={() => removeMsg(item, "ai")}
                        >
                          {IconClose}
                        </a>
                      </div>

                    </div>
                  </div>)}


                </div>}

              </>)
            })}
          </div>

          <div className="send-box">
            <div className="send-box-content">
              <div className="upload-img-pre-list">
                {analyzeImagePre && (<>

                  {isUploadImgToServer ? <Spinner /> : <div className="pre-img-item">
                    <div className="remove-pre-img" onClick={() => {
                      setAnalyzeImagePre(null)
                      setAnalyzeUploadImageInfo({ ...analyzeUploadImageInfo, filename: '', subfolder: '', type: '' })
                    }}>{IconRemovePreImg}</div>
                    <img src={analyzeImagePre} />
                  </div>}

                </>)}

              </div>
              <TextArea
                placeholder="Analysis the image given"
                value={analyzeContent}
                onChange={(e) => {
                  setAnalyzeContent(e.target.value)
                }}
                onKeyDown={(e) => {


                }}
                autoSize={{ maxRows: 20 }}
              />

              <div className="send-tools">
                <div className="tools-left">
                  <div style={{ cursor: "pointer" }} onClick={() => uploadImage()}>
                    {IconImageAdd}
                  </div>
                </div>
                <div className="tools-right">
                  <div style={{ cursor: "pointer" }} onClick={() => sendAiAnalyseImg()}>
                    {IconSendMsgDefault}
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
        <div className="buttons">
          {/* <div className="btn">Add to board</div> */}
        </div>
      </div>)
    }

    const renderAiSummarize = () => {
      return (<div className="ai-summarize">
        <div className="content-main">
          <div className="send-box">
            <div className="send-box-content">
              <TextArea
                placeholder="Ask a question or generate the following content..."
                value={summarizeOriginInfo}
                onChange={(e) => {
                  setSummarizeOriginInfo(e.target.value)
                }}
                onKeyDown={(e) => {


                }}
                autoSize={{ maxRows: 20 }}
              />

              <div className="send-tools">
                <div className="tools-left">

                </div>
                <div className="tools-right">
                  <div style={{ cursor: "pointer" }} onClick={() => sendAiSummarize()}>
                    {IconSendMsgDefault}
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div className="message-box">

            {(isRequestSummarize || summarizeAfterInfo != '') && (<div className="result">
              <div className="title">Result</div>
              <div className="content">
                {summarizeAfterInfo != '' ? (<>
                  {summarizeAfterInfo}
                </>) : (<><Spinner /></>)}

              </div>
            </div>)}


          </div>

        </div>
        <div className="buttons">
          {/* {summarizeAfterInfo != '' && (<div onClick={() => {

            const randomBackground = Math.floor(
              (Math.random() * 10) / 2,
            );

            let originStickyContent = marked
              .lexer(summarizeAfterInfo)
              .map((t) => {
                return marked.parse(t.raw, {
                  ...marked.getDefaults(),
                  gfm: true,
                  breaks: true,
                  renderer,
                }) as string;
              })
              .join("");



            const stickyNote: StickyNote = {
              id: nanoid(),
              key: nanoid(),
              status: "expand",
              content: originStickyContent,
              background:
                StickyNoteBackground[randomBackground],
              fontStyle: "normal",
              textDecoration: "none",
              fontWeight: "normal",
              position: null,
            }
            collabAPI?.addStickyNote(stickyNote)

          }} className="btn">{t("labels.btn_insert")}</div>)} */}
        </div>
      </div>)
    }

    const renderAiText2Image = () => {

      return (<div className="ai-text-to-image">
        <div className="content-main">
          <div className="send-box">
            <div className="send-box-content">
              <TextArea
                placeholder="Ask a question or generate the following content..."
                value={generateImageResult.prompt}
                onChange={(e) => {
                  setGenerateImageResult({
                    ...generateImageResult,
                    prompt: e.target.value
                  })
                }}
                onKeyDown={(e) => {


                }}
                autoSize={{ maxRows: 20 }}
              />

              <div className="send-tools">
                <div className="tools-left">

                </div>
                <div className="tools-right">
                  <div style={{ cursor: "pointer" }} onClick={() => sendAiGenerateImage()}>
                    {IconSendMsgDefault}
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div className="message-box">
            <div className="img-type-box">
              <div className="title">Style</div>
              <div className="img-type-list">
                {preImgs.map((item, i) => {
                  return (<div className="img-type-item" key={`img-type-item${i}`}>
                    <li className={clsx({
                      "select-type": item.modeName == generateImageType
                    })} onClick={() => {
                      setGenerateImageType(item.modeName)
                    }}>
                      <img src={`/home/ai/${item.imgUrl}`} />
                      <span>{item.modeName}</span>
                    </li>
                  </div>)
                })}


              </div>
            </div>
            <div className="result">
              <div className="title">Result</div>
              <div className="content">

                {(isRequestGenerateImg || generateImageResult.filename) && (<>
                  {isRequestGenerateImg && (<><Spinner /></>)}
                  {(generateImageResult.filename != '') && (
                    <>
                      {!generateImageResult.loaded && (<Spinner />)}
                      {
                        <img style={{ display: generateImageResult.loaded ? 'block' : 'none' }} onLoad={() => setGenerateImageResult({ ...generateImageResult, loaded: true })} src={`${API_URL.host}${API_URL.aiShowFile}?filename=${generateImageResult.filename}&subfolder=${generateImageResult.subfolder}&type=${generateImageResult.type}`} />
                      }
                    </>
                  )}
                </>)}

                {/* {isRequestGenerateImg && (<>
                  {generateImageResult.filename != '' ? (<img src={`${API_URL.host}${API_URL.aiShowFile}?filename=${generateImageResult.filename}&subfolder=${generateImageResult.subfolder}&type=${generateImageResult.type}`} />) : (<><Spinner /></>)}
                </>)} */}

              </div>
            </div>



          </div>

        </div>
        <div className="buttons">
          {/* <div className="btn">Add to board</div> */}
        </div>
      </div>)
    }

    const renderAiReadArticle = () => {
      return (<div className="ai-read-article">
        <div className="content-main">
          <div className="send-box">
            <div className="send-box-content">
              <TextArea
                placeholder="Ask a question or generate the following content..."
                value={generateAudioResult.prompt}
                onChange={(e) => {
                  setGenerateAudioResult({
                    ...generateAudioResult,
                    prompt: e.target.value
                  })
                }}
                onKeyDown={(e) => {


                }}
                autoSize={{ maxRows: 100 }}
              />

              <div className="send-tools">
                <div className="tools-left">

                </div>
                <div className="tools-right">
                  <div style={{ cursor: "pointer" }} onClick={() => sendAiGenerateAudio()}>
                    {IconSendMsgDefault}
                  </div>
                </div>

              </div>

            </div>
            <div className="result">
              {(isRequestGenerateAudio || generateAudioResult.filename) && (<>
                {isRequestGenerateAudio && (<><Spinner /></>)}
                {(generateAudioResult.filename != '') && (
                  <>
                    {!generateAudioResult.loaded && (<Spinner />)}
                    {<div style={{ display: generateAudioResult.loaded ? 'block' : 'none' }}>
                      <audio controls onLoadedData={handleLoadeAudiodData}>
                        <source src={`${API_URL.host}${API_URL.aiShowFile}?filename=${generateAudioResult.filename}&subfolder=${generateAudioResult.subfolder}&type=${generateAudioResult.type}`} type="audio/flac" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>}
                  </>
                )}
              </>)}
            </div>

          </div>


        </div>
        <div className="buttons">
          {/* <div className="btn">Add to board</div> */}
        </div>
      </div>)
    }


    const renderAiTranslate = () => {
      return (<div className="ai-translate">
        <AiTranslateAssist
          appState={appState}
          setAppState={setAppState}
        />
      </div>)
    }

    //   const renderAiQuizAndAssessment = () => {
    //     return (<div className="ai-quiz-and-assessment">
    //       <iframe onLoad={handleLoadeIframeQuiz}
    //         style={iframeQuizHasLoaded ? { display: 'block' } : { display: 'none' }} src="https://dify.ai.imago.us/chatbot/e735WBBeVoNQbjJ1" allow="microphone"> </iframe>

    //       {!iframeQuizHasLoaded && (<Spinner />)}
    //     </div>)
    // }

    const renderAiQuizAndAssessment = () => {
      return (<div className="ai-quiz-and-assessment">
        <div className="content-main">
          <div className="send-box">
            <div className="send-box-content">
              <div className="form-item">
                <label htmlFor="quiz_subject">Subject</label>
                <Input required id="quiz_subject" placeholder='subject' value={quizInfoPrompt.subject} onChange={(e) => setQuizInfoPrompt({
                  ...quizInfoPrompt,
                  subject: e.target.value
                })} />
              </div>

              <div className="form-item">
                <label htmlFor="quiz_grade">Grade</label>
                <Select
                  id="quiz_grade"
                  value={quizInfoPrompt.grade}
                  placeholder="Select Grade"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setQuizInfoPrompt({
                      ...quizInfoPrompt,
                      grade: v
                    })
                  }}
                  options={grades.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>

              <div className="form-item">
                <label htmlFor="quiz_topic">Topic</label>
                <Input required id="quiz_topic" value={quizInfoPrompt.topic} placeholder='topic' onChange={(e) => setQuizInfoPrompt({
                  ...quizInfoPrompt,
                  topic: e.target.value
                })} />
              </div>

              <div className="form-item">
                <label htmlFor="quiz_language">Language</label>
                <Select
                  id="quiz_language"
                  value={quizInfoPrompt.language}
                  placeholder="Select Language"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setQuizInfoPrompt({
                      ...quizInfoPrompt,
                      language: v
                    })
                  }}
                  options={languages.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>

              <div className="btn-group">
                <div className="left">
                  <div className="btn-clear" onClick={() => {
                    setQuizInfoPrompt({ topic: '', grade: '', subject: '', language: '', result: '' })
                    setIsRequestQuiz(false)
                  }}>Clear</div>
                </div>
                <div className="right">
                  <div className="btn-submit" onClick={() => sendAiQuiz()}>Generate</div>
                </div>
              </div>

            </div>
          </div>

          <div className="message-box">
            <div className="result">

              {(isRequestQuiz || quizInfoPrompt.result != '') && (<><div className="title">Result</div><div className="content">
                {quizInfoPrompt.result != '' ? (<>
                  <div
                    className="content-list"
                    dangerouslySetInnerHTML={{
                      __html: marked(quizInfoPrompt.result) as string,
                    }}
                  ></div>
                </>) : (<><Spinner /></>)}
              </div></>)}

            </div>

          </div>

        </div>
        <div className="tools-bar-icon">

          {quizInfoPrompt.result != '' && (<>

            <div><a
              href={undefined}
              onClick={() => {
                navigator.clipboard
                  .writeText(quizInfoPrompt.result)
                  .then(() => {
                    console.log("Text copied to clipboard");
                    message.info("Text copied to clipboard");
                  })
                  .catch((err) => {
                    console.error("Failed to copy text:", err);
                    message.error("Failed to copy text");
                  });
              }}
            >
              {IconCopy}
            </a></div>

            <div>
              <a
                href={undefined}
                onClick={() => {
                  const randomBackground = Math.floor(
                    (Math.random() * 10) / 2,
                  );

                  let stickyContent = marked
                    .lexer(quizInfoPrompt.result)
                    .map((t) => {
                      return marked.parse(t.raw, {
                        ...marked.getDefaults(),
                        gfm: true,
                        breaks: true,
                        renderer,
                      }) as string;
                    })
                    .join("");

                  const stickyNote: StickyNote = {
                    id: nanoid(),
                    key: nanoid(),
                    status: "expand",
                    content: stickyContent,
                    background:
                      StickyNoteBackground[randomBackground],
                    fontStyle: "normal",
                    textDecoration: "none",
                    fontWeight: "normal",
                    position: null,
                  }
                  collabAPI?.addStickyNote(stickyNote)
                }}
              >
                {IconUnion}
              </a>
            </div>

          </>)}


        </div>
      </div>)
    }


    const renderLearningCompanion = () => {
      let gradesLearning = [...grades, { label: "Professional Staff", value: "Professional Staff" }];

      return (<div className="ai-learning-companion">
        <div className="content-main">
          <div className="send-box">
            <div className="send-box-content">
              <div className="form-item">
                <label htmlFor="learning_country">Country</label>
                <Select
                  id="learning_country"
                  value={learningCompanionInfo.country}
                  placeholder="Select Country"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setLearningCompanionInfo({
                      ...learningCompanionInfo,
                      country: v
                    })
                  }}
                  options={countrys.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>
              <div className="form-item">
                <label htmlFor="learning_grade">Grade</label>
                <Select
                  id="learning_grade"
                  value={learningCompanionInfo.grade}
                  placeholder="Select Grade"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setLearningCompanionInfo({
                      ...learningCompanionInfo,
                      grade: v
                    })
                  }}
                  options={gradesLearning.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>
              <div className="form-item">
                <label htmlFor="learning_language">Language</label>
                <Select
                  id="learning_language"
                  value={learningCompanionInfo.language}
                  placeholder="Select Language"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setLearningCompanionInfo({
                      ...learningCompanionInfo,
                      language: v
                    })
                  }}
                  options={languages.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>
              <div className="form-item">
                <label htmlFor="learning_subject">Subject</label>
                <Input required id="learning_subject" placeholder='subject' value={learningCompanionInfo.subject} onChange={(e) => setLearningCompanionInfo({
                  ...learningCompanionInfo,
                  subject: e.target.value
                })} />
              </div>



              <div className="form-item">
                <label htmlFor="learning_question">Question</label>
                <Input required id="learning_question" value={learningCompanionInfo.question} placeholder='question' onChange={(e) => setLearningCompanionInfo({
                  ...learningCompanionInfo,
                  question: e.target.value
                })} />
              </div>



              <div className="btn-group">
                <div className="left">
                  <div className="btn-clear" onClick={() => {
                    setLearningCompanionInfo({ country: '', question: '', grade: '', subject: '', language: '', result: '' })
                    setIsRequestLearning(false)
                  }}>Clear</div>
                </div>
                <div className="right">
                  <div className="btn-submit" onClick={() => sendAiLearningCompanion()}>Generate</div>
                </div>
              </div>

            </div>
          </div>

          <div className="message-box">
            <div className="result">

              {(isRequestLearning || learningCompanionInfo.result != '') && (<><div className="title">Result</div>
                <div className="content">
                  {learningCompanionInfo.result != '' ? (<>
                    <div
                      className="content-list"
                      dangerouslySetInnerHTML={{
                        __html: marked(learningCompanionInfo.result) as string,
                      }}
                    ></div>
                  </>) : (<><Spinner /></>)}
                </div></>)}


            </div>


          </div>

        </div>
        <div className="tools-bar-icon">

          {learningCompanionInfo.result != '' && (<>

            <div><a
              href={undefined}
              onClick={() => {
                navigator.clipboard
                  .writeText(learningCompanionInfo.result)
                  .then(() => {
                    console.log("Text copied to clipboard");
                    message.info("Text copied to clipboard");
                  })
                  .catch((err) => {
                    console.error("Failed to copy text:", err);
                    message.error("Failed to copy text");
                  });
              }}
            >
              {IconCopy}
            </a></div>

            <div>
              <a
                href={undefined}
                onClick={() => {
                  const randomBackground = Math.floor(
                    (Math.random() * 10) / 2,
                  );

                  let stickyContent = marked
                    .lexer(learningCompanionInfo.result)
                    .map((t) => {
                      return marked.parse(t.raw, {
                        ...marked.getDefaults(),
                        gfm: true,
                        breaks: true,
                        renderer,
                      }) as string;
                    })
                    .join("");

                  const stickyNote: StickyNote = {
                    id: nanoid(),
                    key: nanoid(),
                    status: "expand",
                    content: stickyContent,
                    background:
                      StickyNoteBackground[randomBackground],
                    fontStyle: "normal",
                    textDecoration: "none",
                    fontWeight: "normal",
                    position: null,
                  }
                  collabAPI?.addStickyNote(stickyNote)
                }}
              >
                {IconUnion}
              </a>
            </div>

          </>)}


        </div>
      </div>)
    }

    const renderVocabularyList = () => {
      return (<div className="ai-vocabulary-list">
        <div className="content-main">
          <div className="send-box">
            <div className="send-box-content">
              <div className="form-item">
                <label htmlFor="quiz_subject">Subject</label>
                <Input required id="quiz_subject" placeholder='subject' value={vocabularyListInfo.subject} onChange={(e) => setVocabularyListInfo({
                  ...vocabularyListInfo,
                  subject: e.target.value
                })} />
              </div>

              <div className="form-item">
                <label htmlFor="quiz_grade">Grade</label>
                <Select
                  id="quiz_grade"
                  value={vocabularyListInfo.grade}
                  placeholder="Select Grade"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setVocabularyListInfo({
                      ...vocabularyListInfo,
                      grade: v
                    })
                  }}
                  options={grades.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>

              <div className="form-item">
                <label htmlFor="quiz_topic">Text</label>
                <Input required id="quiz_topic" value={vocabularyListInfo.text} placeholder='text' onChange={(e) => setVocabularyListInfo({
                  ...vocabularyListInfo,
                  text: e.target.value
                })} />
              </div>

              <div className="form-item">
                <label htmlFor="quiz_language">Language</label>
                <Select
                  id="quiz_language"
                  value={vocabularyListInfo.language}
                  placeholder="Select Language"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setVocabularyListInfo({
                      ...vocabularyListInfo,
                      language: v
                    })
                  }}
                  options={languages.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>

              <div className="btn-group">
                <div className="left">
                  <div className="btn-clear" onClick={() => {
                    setVocabularyListInfo({ text: '', grade: '', subject: '', language: '', result: '' })
                    setIsRequestVocabulary(false)
                  }}>Clear</div>
                </div>
                <div className="right">
                  <div className="btn-submit" onClick={() => sendAiVocabulary()}>Generate</div>
                </div>
              </div>

            </div>
          </div>

          <div className="message-box">
            <div className="result">

              {(isRequestVocabulary || vocabularyListInfo.result != '') && (<><div className="title">Result</div>
                <div className="content">
                  {vocabularyListInfo.result != '' ? (<>
                    <div
                      className="content-list"
                      dangerouslySetInnerHTML={{
                        __html: marked(vocabularyListInfo.result) as string,
                      }}
                    ></div>
                  </>) : (<><Spinner /></>)}
                </div></>)}


            </div>


          </div>

        </div>
        <div className="tools-bar-icon">

          {vocabularyListInfo.result != '' && (<>

            <div><a
              href={undefined}
              onClick={() => {
                navigator.clipboard
                  .writeText(vocabularyListInfo.result)
                  .then(() => {
                    console.log("Text copied to clipboard");
                    message.info("Text copied to clipboard");
                  })
                  .catch((err) => {
                    console.error("Failed to copy text:", err);
                    message.error("Failed to copy text");
                  });
              }}
            >
              {IconCopy}
            </a></div>

            <div>
              <a
                href={undefined}
                onClick={() => {
                  const randomBackground = Math.floor(
                    (Math.random() * 10) / 2,
                  );

                  let stickyContent = marked
                    .lexer(vocabularyListInfo.result)
                    .map((t) => {
                      return marked.parse(t.raw, {
                        ...marked.getDefaults(),
                        gfm: true,
                        breaks: true,
                        renderer,
                      }) as string;
                    })
                    .join("");

                  const stickyNote: StickyNote = {
                    id: nanoid(),
                    key: nanoid(),
                    status: "expand",
                    content: stickyContent,
                    background:
                      StickyNoteBackground[randomBackground],
                    fontStyle: "normal",
                    textDecoration: "none",
                    fontWeight: "normal",
                    position: null,
                  }
                  collabAPI?.addStickyNote(stickyNote)
                }}
              >
                {IconUnion}
              </a>
            </div>

          </>)}


        </div>
      </div>)
    }


    const renderLessonPlan = () => {
      let gradesLearning = [...grades, { label: "Professional Staff", value: "Professional Staff" }];

      return (<div className="ai-lesson-plan">
        <div className="content-main">
          <div className="send-box">
            <div className="send-box-content">
              <div className="form-item">
                <label htmlFor="learning_country">Country</label>
                <Select
                  id="learning_country"
                  value={lessonPlanInfo.country}
                  placeholder="Select Country"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setLessonPlanInfo({
                      ...lessonPlanInfo,
                      country: v
                    })
                  }}
                  options={countrys.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>
              <div className="form-item">
                <label htmlFor="learning_grade">Grade</label>
                <Select
                  id="learning_grade"
                  value={lessonPlanInfo.grade}
                  placeholder="Select Grade"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setLessonPlanInfo({
                      ...lessonPlanInfo,
                      grade: v
                    })
                  }}
                  options={gradesLearning.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>
              <div className="form-item">
                <label htmlFor="learning_language">Language</label>
                <Select
                  id="learning_language"
                  value={lessonPlanInfo.language}
                  placeholder="Select Language"
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  dropdownStyle={{
                    textAlign: 'left'
                  }}
                  onChange={(v) => {
                    setLessonPlanInfo({
                      ...lessonPlanInfo,
                      language: v
                    })
                  }}
                  options={languages.map((c: any) => { return { label: c.label, value: c.value } })}
                />
              </div>
              <div className="form-item">
                <label htmlFor="learning_subject">Subject</label>
                <Input required id="learning_subject" placeholder='subject' value={lessonPlanInfo.subject} onChange={(e) => setLessonPlanInfo({
                  ...lessonPlanInfo,
                  subject: e.target.value
                })} />
              </div>



              <div className="form-item">
                <label htmlFor="learning_question">Question</label>
                <Input required id="learning_question" value={lessonPlanInfo.question} placeholder='question' onChange={(e) => setLessonPlanInfo({
                  ...lessonPlanInfo,
                  question: e.target.value
                })} />
              </div>



              <div className="btn-group">
                <div className="left">
                  <div className="btn-clear" onClick={() => {
                    setLessonPlanInfo({ country: '', question: '', grade: '', subject: '', language: '', result: '' })
                    setIsRequestLessonPlan(false)
                  }}>Clear</div>
                </div>
                <div className="right">
                  <div className="btn-submit" onClick={() => sendAiLessionPlan()}>Generate</div>
                </div>
              </div>

            </div>
          </div>

          <div className="message-box">
            <div className="result">

              {(isRequestLessonPlan || lessonPlanInfo.result != '') && (<><div className="title">Result</div>
                <div className="content">
                  {lessonPlanInfo.result != '' ? (<>
                    <div
                      className="content-list"
                      dangerouslySetInnerHTML={{
                        __html: marked(lessonPlanInfo.result) as string,
                      }}
                    ></div>
                  </>) : (<><Spinner /></>)}
                </div></>)}


            </div>


          </div>

        </div>
        <div className="tools-bar-icon">

          {lessonPlanInfo.result != '' && (<>

            <div><a
              href={undefined}
              onClick={() => {
                navigator.clipboard
                  .writeText(lessonPlanInfo.result)
                  .then(() => {
                    console.log("Text copied to clipboard");
                    message.info("Text copied to clipboard");
                  })
                  .catch((err) => {
                    console.error("Failed to copy text:", err);
                    message.error("Failed to copy text");
                  });
              }}
            >
              {IconCopy}
            </a></div>

            <div>
              <a
                href={undefined}
                onClick={() => {
                  const randomBackground = Math.floor(
                    (Math.random() * 10) / 2,
                  );

                  let stickyContent = marked
                    .lexer(lessonPlanInfo.result)
                    .map((t) => {
                      return marked.parse(t.raw, {
                        ...marked.getDefaults(),
                        gfm: true,
                        breaks: true,
                        renderer,
                      }) as string;
                    })
                    .join("");

                  const stickyNote: StickyNote = {
                    id: nanoid(),
                    key: nanoid(),
                    status: "expand",
                    content: stickyContent,
                    background:
                      StickyNoteBackground[randomBackground],
                    fontStyle: "normal",
                    textDecoration: "none",
                    fontWeight: "normal",
                    position: null,
                  }
                  collabAPI?.addStickyNote(stickyNote)
                }}
              >
                {IconUnion}
              </a>
            </div>

          </>)}


        </div>
      </div>)
    }
    return (
      <div
        className="right-side-container"
        onPointerDown={() => setAppState({ activeDragableIframe: null })}
      >
        <div className="side-box-header">
          <div className="header-left">
            <div className="go-history" onClick={() => {
              setIframeQuizHasLoaded(false)
              setAppState({ openRightSidebar: "ai_assist" })
            }}>
              {(appState.openRightSidebar && appState.openRightSidebar !== "ai_assist") && (<div className="icon">
                {IconLeftGoHistory}
              </div>)}
            </div>

            <div className="title">
              {appState.openRightSidebar === "ai_assist" ? (
                <>Collab AI Assist</>
              ) : appState.openRightSidebar === "ai_generate_image" ? (
                <>Text to Image</>
              ) : appState.openRightSidebar === "ai_summarize" ? (
                <>Summarize</>
              ) : appState.openRightSidebar === "ai_analyze_image" ? (
                <>Analyze Image</>
              ) : appState.openRightSidebar === "ai_prompt_text" ? (
                <>AI Chat</>
              ) : appState.openRightSidebar === "ai_read_article" ? (
                <>Read Article</>
              ) : appState.openRightSidebar === "ai_quiz_and_assessment" ? (
                <>Quiz and Assessment</>
              ) : appState.openRightSidebar === "ai_vocabulary_list" ? (
                <>Vocabulary List</>
              ) : appState.openRightSidebar === "ai_lesson_plan" ? (
                <>Lesson Plan</>
              ) : appState.openRightSidebar === "ai_learning_companion" ? (
                <>Learning Companion</>
              ) : appState.openRightSidebar === "ai_translate" ? (
                <>Translate</>
              ) : (
                <></>
              )}
            </div>

          </div>
          <div onClick={() => {
            setAppState({ openRightSidebar: null })
          }} className="close">
            {IconCloseRightSideWin}
          </div>
        </div>
        <div className="side-box-content" >
          {appState.openRightSidebar === "ai_assist" ? (
            renderAiAssist()
          ) : appState.openRightSidebar === "ai_generate_image" ? (
            renderAiText2Image()
          ) : appState.openRightSidebar === "ai_summarize" ? (
            renderAiSummarize()
          ) : appState.openRightSidebar === "ai_analyze_image" ? (
            renderAiAnalyzeImage()
          ) : appState.openRightSidebar === "ai_prompt_text" ? (
            renderAiChat()
          ) : appState.openRightSidebar === "ai_read_article" ? (
            renderAiReadArticle()
          ) : appState.openRightSidebar === "ai_quiz_and_assessment" ? (
            renderAiQuizAndAssessment()
          ) : appState.openRightSidebar === "ai_vocabulary_list" ? (
            renderVocabularyList()
          ) : appState.openRightSidebar === "ai_lesson_plan" ? (
            renderLessonPlan()
          ) : appState.openRightSidebar === "ai_learning_companion" ? (
            renderLearningCompanion()
          ) : appState.openRightSidebar === "ai_translate" ? (
            renderAiTranslate()
          ) : (
            <></>
          )}

        </div>
      </div>
    );
  };
