import { config } from "../config/config";
import { NeedUploadFile } from "../data";
import {
    setItemWithExpiration,
    getItemWithExpiration
} from "../imago-app/data/localStorage";


// export const API_KEY =  "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"
//https://www.googleapis.com/auth/drive.metadata.readonly 
export const SCOPES = 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/meetings.space.created https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid';
export const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

export const handleGoogleLogin = () => {
    const returnUrl = window.location.origin + '/board'
    const redirectUri = encodeURIComponent(returnUrl);
    const scope = encodeURIComponent(SCOPES);
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=token&prompt=consent&client_id=${config.google_auth_account.client_id}&redirect_uri=${redirectUri}&scope=${scope}`
    window.location.href = authUrl;
};

export const uploadGoogleDrive = async (file: Blob, name: string, type: string) => {
    const folderId = await getRootFolder("imago-collab")
    let metadata = {
        "name": name,
        "mimeType": type,
        "parents": [folderId]
    };

    const token = getItemWithExpiration("google_access_token")
    if (!token) {
        alert("Please log in first")
        return
    }
    let form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', file);

    try {
        let res = await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart", {
            method: 'POST',
            headers: new Headers({ 'Authorization': 'Bearer ' + token }),
            body: form,
        });
        let val = await res.json();

        return val
    } catch (error) {
        console.error(error);
        return null
    }
    return null
}


export const createFolderAndUploadFiles = async (fileId: string) => {
    const token = getItemWithExpiration("google_access_token");

    if (!token) {
        alert("Please log in first");
        return;
    }


};



export const getRootFolder = async (folderName: string) => {
    const token = getItemWithExpiration("google_access_token");
    if (!token) {
        alert("Please log in first");
        return;
    }
    try {
        const folderExistsResponse = await fetch(`https://www.googleapis.com/drive/v3/files?q=mimeType='application/vnd.google-apps.folder'+and+name='${folderName}'+and+trashed=false`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        });

        const folderExistsData = await folderExistsResponse.json();

        const existingFolder = folderExistsData.files.find((file: { mimeType: string; name: string; }) => file.mimeType === 'application/vnd.google-apps.folder' && file.name === folderName);


        if (existingFolder) {
            const folderId = existingFolder.id;
            return folderId;
        } else {
            const folderMetadata = {
                name: folderName,
                mimeType: "application/vnd.google-apps.folder",
            };

            const createFolderResponse = await fetch("https://www.googleapis.com/drive/v3/files", {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(folderMetadata)
            });

            const folderData = await createFolderResponse.json();
            const folderId = folderData.id;
            return folderId;
        }

    } catch (error) {
        console.error(error);
    }
};


export const createFolder = async (folderName: string, parentId: string) => {
    const token = getItemWithExpiration("google_access_token");
    if (!token) {
        alert("Please log in first");
        return;
    }
    try {

        const folderMetadata = {
            name: folderName,
            mimeType: "application/vnd.google-apps.folder",
            parents: [parentId]
        };

        const createFolderResponse = await fetch("https://www.googleapis.com/drive/v3/files", {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(folderMetadata)
        });

        const folderData = await createFolderResponse.json();
        const folderId = folderData.id;
        return folderId;

    } catch (error) {
        console.error(error);
    }
}


export const uploadMultipleGoogleDrive = async (files: NeedUploadFile[], folderName: string) => {
    const rootId = await getRootFolder("imago-collab");
    const folderId = await createFolder(folderName, rootId)
    const token = getItemWithExpiration("google_access_token");
    if (!token) {
        alert("Please log in first");
        return;
    }

    let results = [];
    for (let i = 0; i < files.length; i++) {
        let file = files[i].file;
        let name = files[i].fileName;
        let type = files[i].extension;

        let metadata = {
            "name": name,
            "mimeType": type,
            "parents": [folderId]
        };

        let form = new FormData();
        form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        form.append('file', file);

        try {
            let res = await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart", {
                method: 'POST',
                headers: new Headers({ 'Authorization': 'Bearer ' + token }),
                body: form,
            });
            let val = await res.json();
            results.push(val);
        } catch (error) {
            console.error(error);
            results.push(null);
        }
    }

    return results;
}
