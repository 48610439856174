import '@wangeditor-next/editor/dist/css/style.css'
import './RichEditor.scss'
import React, { useState, useEffect } from 'react'
import { Editor, Toolbar } from '@wangeditor-next/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig, i18nChangeLanguage } from '@wangeditor-next/editor'
import { LocalData } from '../imago-app/data/LocalData'
import { AppState } from '../types'
import { getformatDate } from './NotesPanel'

export const RichEditor: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  isShowToolBar?: boolean
}> = ({ appState, setAppState, isShowToolBar }) => {
  const [editor, setEditor] = useState<IDomEditor | null>(null)
  useEffect(() => {
    i18nChangeLanguage('en')

  }, [])


  const toolbarConfig: Partial<IToolbarConfig> = {
    // toolbarKeys: [
    //   'headerSelect',
    //   '|',
    //   "redo", "undo", 'bold', 'underline', 'italic', 'through', 'color', 'bgColor', 'indent', 'delIndent', 'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyJustify', 'fontSize', "emotion",
    //   "uploadVideo", "todo",
    //   "insertImage"
    // ]
  }


  const editorConfig: Partial<IEditorConfig> = {
    placeholder: 'content...',
  }

  useEffect(() => {
    return () => {
      if (editor == null) return
      editor.destroy()
      setEditor(null)
    }
  }, [editor])




  return (
    <div style={{ overflow: "hidden" }} onCopy={(event) => {
      event.stopPropagation();
    }} className='rich-main'>

      <Editor
        defaultConfig={editorConfig}
        value={appState.pageNoteWinInfo?.content}
        onCreated={setEditor}
        onChange={editor => {
          appState.pageNoteWinInfo && setAppState({
            pageNoteWinInfo: {
              ...appState.pageNoteWinInfo,
              content: editor.getHtml(),
              time: getformatDate(),
              closed: false
            },
          });

        }}
        mode="default"
        style={{ flex: 1, overflowY: 'hidden' }}
      />

    </div>
  )
}

export default RichEditor