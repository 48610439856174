import {
  ArrowIcon,
  DiamondIcon,
  EllipseIcon,
  EraserIcon,
  EnlargeIcon,
  ImageIcon,
  LineIcon,
  RectangleIcon,
  SquareIcon,
  TriangleIcon,
  PentagonIcon,
  HexagonIcon,
  OctagonIcon,
  CircleIcon,
  PerspectiveIcon,
  RosetteIcon,
  StarIcon,
  CylinderIcon,
  HeartIcon,
  TickShapeIcon,
  CrossShapeIcon,
  SelectionIcon,
  TextIcon,
  RubberIcon,
  PenIcon,
  MarkerIcon,
  BrushIcon,
  EraserObjectIcon,
  EraserBigIcon,
  MagicPenIcon,
  MagicTextIcon,
  ConnectArrowIcon,
} from "./components/icons";
import { KEYS } from "./keys";

export const SHAPES = [
  {
    name: "pointer",
    icon: SelectionIcon,
    value: "selection",
    key: KEYS.V,
    numericKey: KEYS["1"],
    fillable: true,
  },
  {
    name: "pencil",
    icon: PenIcon,
    value: "freedraw",
    key: [KEYS.P, KEYS.X],
    numericKey: KEYS["7"],
    fillable: false,
  },
  {
    name: "magicpen",
    icon: MagicPenIcon,
    value: "magicpen",
    key: "",
    numericKey: "",
    fillable: false,
  },
  {
    name: "marker",
    icon: MarkerIcon,
    value: "marker",
    key: "",
    numericKey: "",
    fillable: false,
  },
  {
    name: "brush",
    icon: BrushIcon,
    value: "eraserbig",
    key: [KEYS.P, KEYS.X],
    numericKey: KEYS["K"],
    fillable: false,
  },
  {
    name: "rectangle",
    icon: RectangleIcon,
    value: "rectangle",
    key: KEYS.R,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "square",
    icon: SquareIcon,
    value: "square",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "triangle",
    icon: TriangleIcon,
    value: "triangle",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "pentagon",
    icon: PentagonIcon,
    value: "pentagon",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "hexagon",
    icon: HexagonIcon,
    value: "hexagon",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "octagon",
    icon: OctagonIcon,
    value: "octagon",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "circle",
    icon: CircleIcon,
    value: "circle",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "perspective",
    icon: PerspectiveIcon,
    value: "perspective",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "rosette",
    icon: RosetteIcon,
    value: "rosette",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "star",
    icon: StarIcon,
    value: "star",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "cylinder",
    icon: CylinderIcon,
    value: "cylinder",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "heart",
    icon: HeartIcon,
    value: "heart",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "tick",
    icon: TickShapeIcon,
    value: "tick",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "cross",
    icon: CrossShapeIcon,
    value: "cross",
    key: KEYS.S,
    numericKey: KEYS["2"],
    fillable: true,
  },
  {
    name: "ellipse",
    icon: EllipseIcon,
    value: "ellipse",
    key: KEYS.O,
    numericKey: KEYS["4"],
    fillable: true,
  },
  {
    name: "diamond",
    icon: DiamondIcon,
    value: "diamond",
    key: KEYS.D,
    numericKey: KEYS["3"],
    fillable: true,
  },

  {
    name: "line",
    icon: LineIcon,
    value: "line",
    key: KEYS.L,
    numericKey: KEYS["6"],
    fillable: true,
  },
  {
    name: "arrow",
    icon: ArrowIcon,
    value: "arrow",
    key: KEYS.A,
    numericKey: KEYS["5"],
    fillable: true,
  },
  {
    name: "connectarrow",
    icon: ConnectArrowIcon,
    value: "connectarrow",
    key: "",
    numericKey: "",
    fillable: true,
  },
  {
    name: "enlarge",
    icon: EnlargeIcon,
    value: "enlarge",
    key: KEYS.G,
    numericKey: KEYS["G"],
    fillable: true,
  },

  {
    name: "text",
    icon: TextIcon,
    value: "text",
    key: KEYS.T,
    numericKey: KEYS["8"],
    fillable: false,
  },
  {
    name: "magictext",
    icon: MagicTextIcon,
    value: "magictext",
    key: "",
    numericKey: "",
    fillable: false,
  },
  {
    name: "image",
    icon: ImageIcon,
    value: "image",
    key: null,
    numericKey: KEYS["9"],
    fillable: false,
  },
  {
    name: "eraser-one",
    icon: EraserObjectIcon,
    value: "eraser",
    key: KEYS.E,
    numericKey: KEYS["0"],
    fillable: false,
  },
  {
    name: "eraser-brush",
    icon: EraserBigIcon,
    value: "rubber",
    key: "",
    numericKey: "",
    fillable: false,
  },

] as const;

export const findShapeByKey = (key: string) => {
  return null;
  const shape = SHAPES.find((shape, index) => {
    return (
      (shape.numericKey != null && key === shape.numericKey.toString()) ||
      (shape.key &&
        (typeof shape.key === "string"
          ? shape.key === key
          : (shape.key as readonly string[]).includes(key)))
    );
  });
  return shape?.value || null;
};
